export const validateIAMPolicyName = (policyName: string) => {
    let messages = [];
    let isValid = true;

    // Checking length of policy name
    if (policyName.length < 1 || policyName.length > 128) {
        messages.push("IAM Policy names must be between 1 and 128 characters long.");
        isValid = false;
    }

    // Checking if the policy name consists of alphanumeric characters, hyphens (-), and underscores (_)
    if (!/^[\w+=,.@_-]+$/.test(policyName)) {
        messages.push("IAM Policy names can include alphanumeric characters, as well as .+=,_@-");
        isValid = false;
    }

    return {
        isValid,
        messages
    };
}