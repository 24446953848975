import { ArrowDefinition } from "../../../Service"

export const lambdaToLambdaInteract: ArrowDefinition = {
    type: "Interact",
    fromType: "Lambda",
    toType: "Lambda",
    properties: [
        {
            name: "Grant permission",
            condition: () => { return true },
            defaultValue: "Execution",
            choices: ["Execution", "Full access"],
            type: "string",
            mandatory: true,
        }
    ]
}
export const lambdaToS3Interact: ArrowDefinition = {
    type: "Interact",
    fromType: "Lambda",
    toType: "S3 Bucket",
    properties: [
        {
            name: "Grant permission",
            condition: () => { return true },
            defaultValue: "Read only",
            choices: ["Read only", "Full access"],
            type: "string",
            mandatory: true,
        }
    ]
}

export const lambdaToDynamoDBInteract: ArrowDefinition = {
    type: "Interact",
    fromType: "Lambda",
    toType: "DynamoDB Table",
    properties: [
        {
            name: "Grant permission",
            condition: () => { return true },
            defaultValue: "Read only",
            choices: ["Read only", "Full access"],
            type: "string",
            mandatory: true,
        }
    ]
}

export const lambdaToSQSInteract: ArrowDefinition = {
    type: "Interact",
    fromType: "Lambda",
    toType: "SQS Queue",
    properties: [
        {
            name: "Grant permission",
            condition: () => { return true },
            defaultValue: "Read only",
            choices: ["Read only", "Full access"],
            type: "string",
            mandatory: true,
        }
    ]
}

export const lambdaToSNSInteract: ArrowDefinition = {
    type: "Interact",
    fromType: "Lambda",
    toType: "SNS Topic",
    properties: [
        {
            name: "Grant permission",
            condition: () => { return true },
            defaultValue: "Read only",
            choices: ["Read only", "Full access"],
            type: "string",
            mandatory: true,
        }
    ]
}

export const lambdaToRDSInteract: ArrowDefinition = {
    type: "Interact",
    fromType: "Lambda",
    toType: "RDS Instance",
    properties: [
        {
            name: "Grant permission",
            condition: () => { return true },
            defaultValue: "Read only",
            choices: ["Read only", "Full access"],
            type: "string",
            mandatory: true,
        }
    ]
}