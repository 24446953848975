import { Button, Icon } from "@mui/material";

interface CustomButtonProps {
    name: string
    onClick?: React.MouseEventHandler<HTMLButtonElement>
    icon: React.ReactNode
    darkMode?: boolean
    disabled?: boolean
    children?: React.ReactNode
}

const CustomButton = ({
    onClick, name, icon, darkMode, disabled, children
}: CustomButtonProps) => {
    return (
        <Button
            disabled={disabled}
            onClick={onClick}
            variant={"contained"}
            endIcon={icon}
            sx={{
                color: darkMode ? '#CCCCCC' : 'black',
                borderColor: darkMode ? '#CCCCCC' : 'black',
                border: "1px solid",
                fontSize: "13px",
                backgroundColor: darkMode ? '#181818' : 'white',
                '&:hover': {
                    color: darkMode ? '#CCCCCC' : 'rgba(0, 0, 0, 0.7)',
                    borderColor: darkMode ? '#CCCCCC' : 'rgba(0, 0, 0, 0.7)',
                    backgroundColor: darkMode ? '#181818' : '#f2f2f2',
                },
                '&.Mui-disabled': {
                    color: 'grey',
                    borderColor: 'grey',
                    backgroundColor: darkMode ? "#2B2B2B" : 'lightgrey',
                },
            }}
        >
            {name}
            {children}
        </Button>
    )
}

export default CustomButton;