import { FirebaseOptions, initializeApp } from "firebase/app";

interface InitializeFirebaseOptions {
    skipRemoteConfig?: boolean;
    skipAnalytics?: boolean;
}

export const initializeFirebase = () => {
    const firebaseConfig: FirebaseOptions = {
        apiKey: "AIzaSyC64RS_7JAbHRlx6DK5L_gRTziZrgqIehc",
        authDomain: "nocode-cloud-infra-dev.firebaseapp.com",
        projectId: "nocode-cloud-infra-dev",
        storageBucket: "nocode-cloud-infra-dev.appspot.com",
        messagingSenderId: "880891135559",
        appId: "1:880891135559:web:d00aa810ddca977ebc5362"
    };


    return initializeApp(firebaseConfig);
};