import {
    BaseBoxShapeTool,
    BaseBoxShapeUtil,
    DefaultColorStyle,
    HTMLContainer,
    StyleProp,
    T,
    TLBaseShape,
    TLDefaultColorStyle,
    TLShape,
    TLShapeUtilFlag,
    getDefaultColorTheme,
} from '@tldraw/tldraw'
import { CardShape } from './CardShape'
import { Tooltip } from '@mui/material'
import { truncateText } from './shapeUtils'

// Define a style that can be used across multiple shapes.
// The ID (myApp:filter) must be globally unique, so we recommend prefixing it with a namespace.


export type WrapperShape = TLBaseShape<
    'wrapper',
    {
        w: number
        h: number
        borderColor: string
        backgroundColor: string
        name: string
        inputs: string[]
        outputs: string[]
        transparentInputs: string[]
        transparentOutputs: string[]
        isValid: boolean
        messages: string[]
    }
>

export class WrapperShapeUtil extends BaseBoxShapeUtil<WrapperShape> {
    static override type = 'wrapper' as const

    static override props = {
        w: T.number,
        h: T.number,
        // You can re-use tldraw built-in styles...
        borderColor: T.string,
        backgroundColor: T.string,
        name: T.string,
        inputs: T.arrayOf(T.string),
        outputs: T.arrayOf(T.string),
        transparentOutputs: T.arrayOf(T.string),
        transparentInputs: T.arrayOf(T.string),
        isValid: T.boolean,
        messages: T.arrayOf(T.string)
    }

    override isAspectRatioLocked = (_shape: WrapperShape) => false
    override canResize = (_shape: WrapperShape) => true
    override canBind = (_shape: WrapperShape) => true
    override hideRotateHandle = (_shape: WrapperShape) => true

    override getDefaultProps(): WrapperShape['props'] {
        return {
            w: 600,
            h: 400,
            borderColor: "black",
            backgroundColor: "white",
            name: "AWS Service",
            inputs: [],
            outputs: [],
            transparentInputs: [],
            transparentOutputs: [],
            isValid: true,
            messages: []
        }
    }

    component(shape: WrapperShape) {
        const bounds = this.editor.getShapeGeometry(shape).bounds
        const theme = getDefaultColorTheme({ isDarkMode: this.editor.user.isDarkMode })

        return (
            <HTMLContainer
                id={shape.id}
                style={{
                    border: `6px dashed ${shape.props.borderColor}`,
                    borderRadius: 8,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    pointerEvents: 'all',
                    backgroundColor: "transparent",
                }}
            >
                {shape.props.isValid ?
                    <Tooltip placement='top' title={"No issues found"}>
                        <div className='absolute top-0 right-0 rounded-full bg-green-700 translate-y-[-50%] translate-x-[50%] h-7 w-7' />
                    </Tooltip> :
                    <Tooltip placement='top' title={<span>{shape.props.messages.map((m) => {
                        return <div>{m}</div>
                    })}</span>}>
                        <div className='absolute top-0 right-0 rounded-full bg-red-700 translate-y-[-50%] translate-x-[50%] h-7 w-7' />
                    </Tooltip>

                }
                <div className={`absolute top-0 left-0 text-center max-w-full p-3 text-3xl text-white truncate rounded-br`}
                    style={{ backgroundColor: shape.props.borderColor }}
                >
                    {shape.props.name}
                </div>
                <div className='absolute left-5 top-[70px] w-[40%] text-left'>
                    {shape.props.inputs.map(value => {
                        return <div
                            className={(shape.props.transparentInputs.indexOf(value) !== -1 ? "opacity-20" : "opacity-100") + " max-w-full truncate text-xl"}
                        >{value}</div>
                    })}
                </div>
                <div className='absolute right-5 top-[70px] w-[40%] text-right'>
                    {shape.props.outputs.map(value => {
                        return <div
                            className={(shape.props.transparentOutputs.indexOf(value) !== -1 ? "opacity-20" : "opacity-100") + " max-w-full truncate text-xl"}
                        >{value}</div>
                    })}
                </div>
            </HTMLContainer>
        )
    }

    // Indicator — used when hovering over a shape or when it's selected; must return only SVG elements here
    indicator(shape: WrapperShape) {
        return <rect width={shape.props.w} height={shape.props.h} />
    }

    async toSvg(shape: WrapperShape) {
        const xmlns = 'http://www.w3.org/2000/svg';
        const padding = 10;
        const titleHeight = 36;
        const titleSize = 30;
        const propertySize = 20;
        const lineHeight = 28;
        const topInputOutputPosition = 75; // Starting Y position for inputs and outputs

        // Create the main SVG group
        const g = document.createElementNS(xmlns, 'g');

        // Create the background rectangle with a dashed border
        const rect = document.createElementNS(xmlns, 'rect');
        rect.setAttributeNS(null, 'width', shape.props.w.toString());
        rect.setAttributeNS(null, 'height', shape.props.h.toString());
        rect.setAttributeNS(null, 'rx', '8');
        rect.setAttributeNS(null, 'fill', 'transparent');
        rect.setAttributeNS(null, 'stroke', shape.props.borderColor);
        rect.setAttributeNS(null, 'stroke-width', '6');
        rect.setAttributeNS(null, 'stroke-dasharray', '6,12'); // Dashed border pattern

        // Append the rectangle to the main group
        g.appendChild(rect);

        // Create the text element for the shape's name
        const nameText = document.createElementNS(xmlns, 'text');
        nameText.textContent = shape.props.name;
        nameText.setAttributeNS(null, 'x', padding.toString());
        nameText.setAttributeNS(null, 'y', "30");
        nameText.setAttributeNS(null, 'style', `font-family: sans-serif; font-size: ${titleSize}px; fill: white;`);
        nameText.setAttributeNS(null, 'dominant-baseline', 'middle');
        // Clamp text length for overflow
        // if more space needed here you can implement using SVG <foreignObject> or split into multiple <tspan> elements
        // create a background rectangle for the text
        const nameBg = document.createElementNS(xmlns, 'rect');
        nameBg.setAttributeNS(null, 'width', shape.props.w.toString());
        nameBg.setAttributeNS(null, 'height', (titleHeight + padding * 2).toString());
        nameBg.setAttributeNS(null, 'y', '0');
        nameBg.setAttributeNS(null, 'fill', shape.props.borderColor);
        g.appendChild(nameBg);
        // Truncate the text if it's too long
        truncateText(nameText, shape.props.w - 20); // Assume 10 padding on each side

        // Append the text above the rectangle background
        g.appendChild(nameText);

        // Set up constants for positioning
        const leftX = 20; // 5 units from the left edge
        const rightX = shape.props.w - 20; // 5 units from the right edge, adjusting for the width of the shape


        // Create a group for inputs
        const inputsGroup = document.createElementNS(xmlns, 'g');
        inputsGroup.setAttributeNS(null, 'transform', `translate(${leftX}, ${topInputOutputPosition})`);

        // Add text elements for each input
        shape.props.inputs.forEach((input, i) => {
            const textElement = document.createElementNS(xmlns, 'text');
            textElement.textContent = input;
            textElement.setAttributeNS(null, 'x', '0');
            textElement.setAttributeNS(null, 'y', `${i * lineHeight}`);
            textElement.setAttributeNS(null, 'style', `font-family: sans-serif; font-size: ${propertySize}px; opacity: ${shape.props.transparentInputs.includes(input) ? '0.4' : '1.0'};`);
            textElement.setAttributeNS(null, 'dominant-baseline', 'middle');
            truncateText(textElement, shape.props.outputs.length === 0 ? shape.props.w - 20 : shape.props.w * 0.4); // Adjust truncation width as needed

            inputsGroup.appendChild(textElement);
        });

        // Append the inputs group to the main group
        g.appendChild(inputsGroup);

        // Create a group for outputs, positioned on the right and adjust text anchor to 'end'
        const outputsGroup = document.createElementNS(xmlns, 'g');
        outputsGroup.setAttributeNS(null, 'transform', `translate(${rightX}, ${topInputOutputPosition})`);

        // Add text elements for each output
        shape.props.outputs.forEach((output, i) => {
            const textElement = document.createElementNS(xmlns, 'text');
            textElement.textContent = output;
            textElement.setAttributeNS(null, 'x', '0');
            textElement.setAttributeNS(null, 'y', `${i * lineHeight}`);
            textElement.setAttributeNS(null, 'style', `font-family: sans-serif; font-size: ${propertySize}px; opacity: ${shape.props.transparentOutputs.includes(output) ? '0.4' : '1.0'}; text-anchor: end;`);
            textElement.setAttributeNS(null, 'dominant-baseline', 'middle');
            truncateText(textElement, shape.props.inputs.length === 0 ? shape.props.w - 20 : shape.props.w * 0.4); // Adjust truncation width as needed

            outputsGroup.appendChild(textElement);
        });

        // Append the outputs group to the main group
        g.appendChild(outputsGroup);


        // Calculate the position for the validity indicator
        const indicatorDiameter = 28; // since the dot is 7x7, diameter would be double that
        const indicatorRadius = indicatorDiameter / 2;
        const indicatorX = shape.props.w
        const indicatorY = 0; // Positioned from top edge plus half its diameter

        // Create the validity indicator circle
        const indicator = document.createElementNS(xmlns, 'circle');
        indicator.setAttributeNS(null, 'cx', indicatorX.toString());
        indicator.setAttributeNS(null, 'cy', indicatorY.toString());
        indicator.setAttributeNS(null, 'r', indicatorRadius.toString());
        indicator.setAttributeNS(null, 'fill', shape.props.isValid ? '#16803C' : '#B81C1B'); // Using colors for green or red indicators
        // Append the indicator to the main group
        g.appendChild(indicator);

        // Handling inputs/outputs and additional elements is out of the scope of this example.
        // Proceed similarly to the nameText and nameBg, using createElementNS,
        // setAttributeNS, and appendChild to add them to the SVG.

        // Return the SVG element <g>
        return g;
    }
}

// Extending the base box shape tool gives us a lot of functionality for free.
export class WrapperShapeTool extends BaseBoxShapeTool {
    static override id = 'wrapper'
    static override initial = 'idle'
    override shapeType = 'wrapper'
    props = {
        w: T.number,
        h: T.number,
        // You can re-use tldraw built-in styles...
        color: DefaultColorStyle,
        // ...or your own custom styles.
    }
}