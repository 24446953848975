export const validateRDSInstanceIdentifier = (instanceIdentifier: string) => {
    let messages = [];
    let isValid = true;

    // Checking length of instance identifier
    if (instanceIdentifier.length < 1 || instanceIdentifier.length > 63) {
        messages.push("Instance identifier must be between 1 and 63 characters long.");
        isValid = false;
    }

    // Checking if the instance identifier consists of letters, numbers
    if (!/^[a-zA-Z0-9]+$/.test(instanceIdentifier)) {
        messages.push("Instance identifier can only consist of alphanumeric characters.");
        isValid = false;
    }

    // Checking if the instance identifier begins with a letter
    if (!/^[a-zA-Z]/.test(instanceIdentifier)) {
        messages.push("Instance identifier must begin with a letter.");
        isValid = false;
    }

    // Checking if the instance identifier ends with a letter or a number
    if (!/[a-zA-Z0-9]$/.test(instanceIdentifier)) {
        messages.push("Instance identifier must end with a letter or a number.");
        isValid = false;
    }

    return {
        isValid,
        messages
    };
}