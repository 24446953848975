import { Component, Service, ServiceData, ServiceTranslation, addQuotes, getPropertyValue, hasArrowTypeIn, hasArrowTypeOut } from "../../Service";
import { validateAwsImageUri, validateDockerImage, validateService } from "../validators/generalValidator";
import { validateFunctionHandler, validateLambdaName, validateLocalZipPath } from "../validators/lambdaValidator";

const defaultName = "lambda-function"

type Runtime = "nodejs14.x" | "nodejs16.x" | "nodejs18.x" | "python3.8" | "python3.9" | "python3.10" | "java8" | "java11" | "java17" | "ruby2.7" | "ruby3.2" | "go1.x" | "dotnetcore3.1" | "dotnet6";

function getRuntimeCode(name: string): Runtime {
    const langToRuntime: { [key: string]: Runtime } = {
        "Node.js 14.x": "nodejs14.x",
        "Node.js 16.x": "nodejs16.x",
        "Node.js 18.x": "nodejs18.x",
        "Python 3.8": "python3.8",
        "Python 3.9": "python3.9",
        "Python 3.10": "python3.10",
        "Java 11": "java11",
        "Java 17": "java17",
        "Java 8": "java8",
        "Ruby 2.7": "ruby2.7",
        "Ruby 3.2": "ruby3.2",
        "Go": "go1.x",
        ".NET 3.1": "dotnetcore3.1",
        ".NET 6": "dotnet6",
    };

    return langToRuntime[name];
}

const getPolicyActions = (serviceName: string, permission: string) => {
    switch (serviceName) {
        case "S3 Bucket":
            return permission === "Read only" ? ['s3:GetObject'] : ['s3:*']
        case "DynamoDB Table":
            return permission === "Read only" ? ['dynamodb:GetItem', 'dynamodb:Scan', 'dynamodb:Query'] : ['dynamodb:*']
        case "SQS Queue":
            return permission === "Read only" ? ['sqs:ReceiveMessage', 'sqs:GetQueueAttributes'] : ['sqs:*']
        case "Lambda":
            return permission === "Execution" ? ['lambda:InvokeFunction'] : ['lambda:*']
        case "SNS Topic":
            return permission === "Read only" ? ['sns:GetTopicAttributes', 'sns:ListSubscriptionsByTopic'] : ['sns:*']
        case "RDS Instance":
            return permission === "Read only" ? ['rds:Describe*', 'rds:List*'] : ['rds:*']
    }
}

const getResourceName = (serviceName: string) => {
    switch (serviceName) {
        case "S3 Bucket":
            return "aws_s3_bucket"
        case "DynamoDB Table":
            return "aws_dynamodb_table"
        case "SQS Queue":
            return "aws_sqs_queue"
        case "Lambda":
            return "aws_lambda_function"
        case "SNS Topic":
            return "aws_sns_topic"
        case "RDS Instance":
            return "aws_db_instance"
    }
}

export const lambda: Service = {
    serviceName: "Lambda",
    defaultName: defaultName,
    category: "Compute",
    borderColor: "#E57614",
    backgroundColor: "#fae3ce",
    filePath: "/root/modules/inkdrop/main.tf",
    validate: (serviceData: ServiceData) => validateService(serviceData),
    isWrapper: false,
    imgUrl: "img/Lambda.png",
    parentsTypes: ["IAM Role"],
    arrowInTypes: ["Invoke Function", "Interact", "API Integration"],
    arrowOutTypes: ["Interact"],
    properties: [
        /*
        {
            name: "Function source",
            condition: () => { return true },
            docsUrl: "",
            tfDocsUrl: "",
            defaultValue: "Docker image",
            type: "string",
            choices: ["Zip file", "Docker image"],
            mandatory: false
        },
        */
        {
            name: "Image URI",
            condition: () => { return true },
            docsUrl: "",
            tfDocsUrl: "",
            isDockerImage: true,
            defaultValue: "amazon/aws-lambda-python:latest",
            validate: (serviceData: ServiceData) => validateAwsImageUri(serviceData.properties.filter(p => {
                return p.name === "Image URI"
            })[0].value as string),
            type: "string",
            mandatory: false
        },
        /*
        {
            name: "File path",
            docsUrl: "",
            tfDocsUrl: "",
            condition: (serviceData: ServiceData) => { return getPropertyValue(serviceData, "Function source") === "Zip file" },
            validate: (serviceData: ServiceData) => validateLocalZipPath(serviceData.properties.filter(p => {
                return p.name === "File path"
            })[0].value as string),
            type: "string",
            defaultValue: "lambda-zip-file.zip",
            mandatory: false
        },
        {
            name: "Handler",
            docsUrl: "",
            tfDocsUrl: "",
            condition: (serviceData: ServiceData) => { return getPropertyValue(serviceData, "Function source") === "Zip file" },
            validate: (serviceData: ServiceData) => validateFunctionHandler(serviceData.properties.filter(p => {
                return p.name === "Handler"
            })[0].value as string),
            type: "string",
            defaultValue: "index.handler",
            mandatory: false
        },
        {
            name: "Runtime",
            docsUrl: "",
            tfDocsUrl: "",
            condition: (serviceData: ServiceData) => { return getPropertyValue(serviceData, "Function source") === "Zip file" },
            type: "string",
            choices: ['Node.js 14.x', 'Node.js 16.x', 'Node.js 18.x', 'Python 3.8', 'Python 3.9', 'Python 3.10', 'Java 11', 'Java 17', 'Java 8', 'Ruby 2.7', 'Ruby 3.2', 'Go', '.NET 3.1', '.NET 6'],
            defaultValue: "Node.js 18.x",
            mandatory: false
        },
        {
            name: "Environment variables",
            docsUrl: "",
            tfDocsUrl: "",
            condition: () => { return true },
            type: "Object",
            defaultValue: {},
            mandatory: false
        },
        */
        {
            name: "Name",
            condition: () => { return true },
            validate: (serviceData: ServiceData) => validateLambdaName(serviceData.properties.filter((p) => {
                return p.name === "Name"
            })[0].value as string),
            docsUrl: "",
            tfDocsUrl: "",
            isName: true,
            defaultValue: defaultName,
            type: "string",
            mandatory: true,
        },
    ],
}

export const lambdaServiceTranslation = (serviceData: ServiceData): ServiceTranslation => {

    const functionArguments = [
        {
            name: "function_name",
            condition: true,
            value: addQuotes(getPropertyValue(serviceData, "Name")),
            dependsOnProperties: ["Name"]
        },
        {
            name: "image_uri",
            condition: true,
            value: addQuotes(getPropertyValue(serviceData, "Image URI")),
            dependsOnProperties: ["Image URI"]
        },
        {
            name: "package_type",
            condition: true,
            value: addQuotes("Image"),
        },
        /*
        {
            name: "filename",
            value: addQuotes(getPropertyValue(serviceData, "File path")),
            condition: getPropertyValue(serviceData, "Function source") === "Zip file",
            dependsOnProperties: ["Function source", "File path"]
        },
        {
            name: "handler",
            condition: getPropertyValue(serviceData, "Function source") === "Zip file",
            value: addQuotes(getPropertyValue(serviceData, "Handler")),
            dependsOnProperties: ["Handler", "Function source"]
        },
        {
            name: "source_code_hash",
            condition: getPropertyValue(serviceData, "Function source") === "Zip file",
            value: "filebase64sha256(" + addQuotes(getPropertyValue(serviceData, "File path")) + ")",
            dependsOnProperties: ["Function source", "File path"]
        },
        {
            name: "runtime",
            condition: getPropertyValue(serviceData, "Function source") === "Zip file",
            value: addQuotes(getRuntimeCode(getPropertyValue(serviceData, "Runtime"))),
            dependsOnProperties: ["Runtime", "Function source"]
        },
        */
        {
            name: "memory_size",
            condition: true,
            value: "128",
        },
        {
            name: "timeout",
            condition: true,
            value: "30",
        },
        {
            name: "role",
            condition: true,
            value: serviceData.parents.some((p) => {
                return p.serviceData.serviceName === "IAM Role"
            }) ?
                "aws_iam_role." + getPropertyValue(serviceData.parents.filter((p) => {
                    return p.serviceData.serviceName === "IAM Role"
                })[0].serviceData, "Name") + ".arn" :
                "aws_iam_role.role_" + getPropertyValue(serviceData, "Name") + ".arn",
            dependsOnProperties: serviceData.parents.some((p) => {
                return p.serviceData.serviceName === "IAM Role"
            }) ? ["parent." + serviceData.parents.filter((p) => {
                return p.serviceData.serviceName === "IAM Role"
            })[0].shapeId + ".Name"] : ["Name"]
        },
        /*
        {
            name: "environment",
            condition: Object.keys(getObjectPropertyValue(serviceData, "Environment variables")).length > 0,
            dependsOnProperties: ["Environment variables"],
            value: [
                {
                    name: "variables",
                    condition: true,
                    value: getObjectFormat(getObjectPropertyValue(serviceData, "Environment variables")),
                    dependsOnProperties: ["Environment variables"]
                }
            ]
        }*/]

    return {
        components: [
            ...(serviceData.arrowIn.filter((arrow) => {
                return arrow.arrowData.type === "Invoke Function" && ["S3 Bucket", "SNS Topic", "Cognito User Pool"].indexOf(arrow.serviceData.serviceName) !== -1
            }).map((arrow, index) => {
                const serviceName = arrow.serviceData.serviceName
                return {
                    component: "resource",
                    type: "aws_lambda_permission",
                    componentName: "allow_" + (serviceName === "S3 Bucket" ? "bucket" : serviceName === "SNS Topic" ? "sns" : "cognito") + "_" + getPropertyValue(serviceData, "Name") + "_" + index,
                    condition: true,
                    dependsOnProperties: serviceName !== "SNS Topic" ? ["arrowIn." + arrow.arrowData.shapeId + ".Trigger on", "Name"] : ["serviceIn." + arrow.arrowData.startId + ".Name", "Name"],
                    arguments: [
                        {
                            name: "statement_id",
                            condition: true,
                            value: addQuotes((serviceName === "S3 Bucket" ? "AllowExecutionFromS3Bucket" : serviceName === "SNS Topic" ? "AllowSNSInvocationFromSNS" : "AllowExecutionFromCognito") + index),
                            dependsOnProperties: ["arrowIn." + arrow.arrowData.shapeId + ".Trigger on"]
                        },
                        {
                            name: "action",
                            condition: true,
                            value: addQuotes("lambda:InvokeFunction"),
                        },
                        {
                            name: "function_name",
                            condition: true,
                            value: "aws_lambda_function." + getPropertyValue(serviceData, "Name") + ".arn",
                            dependsOnProperties: ["Name"]
                        },
                        {
                            name: "principal",
                            condition: true,
                            value: addQuotes(serviceName === "S3 Bucket" ? "s3.amazonaws.com" : serviceName === "SNS Topic" ? "sns.amazonaws.com" : "cognito-idp.amazonaws.com"),
                        },
                        {
                            name: "source_arn",
                            condition: true,
                            value: (serviceName === "S3 Bucket" ? "aws_s3_bucket." : serviceName === "SNS Topic" ? "aws_sns_topic." : "aws_cognito_user_pool.") +
                                getPropertyValue(arrow.serviceData, "Name") + ".arn",
                            dependsOnProperties: ["serviceIn." + arrow.arrowData.startId + ".Name"]
                        },
                    ]
                }
            }) as Component[]),
            ...(serviceData.arrowIn.filter((arrow) => {
                return arrow.arrowData.type === "Invoke Function" && arrow.serviceData.serviceName === "SQS Queue"
            }).map((arrow, index) => {
                return {
                    component: "resource",
                    type: "aws_lambda_event_source_mapping",
                    componentName: "sqs_mapping_" + getPropertyValue(serviceData, "Name") + "_" + index,
                    condition: true,
                    dependsOnProperties: ["serviceIn." + arrow.arrowData.startId + ".Name", "Name"],
                    arguments: [{
                        name: "event_source_arn",
                        condition: true,
                        value: "aws_sqs_queue." + getPropertyValue(arrow.serviceData, "Name") + ".arn",
                        dependsOnProperties: ["serviceIn." + arrow.arrowData.startId + ".Name"]
                    },
                    {
                        name: "function_name",
                        condition: true,
                        value: addQuotes(getPropertyValue(serviceData, "Name")),
                        dependsOnProperties: ["Name"]
                    },
                    ]
                }
            }) as Component[]),
            ...(serviceData.arrowIn.filter((arrow) => {
                return arrow.arrowData.type === "Invoke Function" && arrow.serviceData.serviceName === "DynamoDB Table"
            }).map((arrow, index) => {
                return {
                    component: "resource",
                    type: "aws_lambda_event_source_mapping",
                    componentName: "dynamodb_table_mapping_" + getPropertyValue(serviceData, "Name") + "_" + index,
                    condition: true,
                    dependsOnProperties: ["serviceIn." + arrow.arrowData.startId + ".Name", "Name"],
                    arguments: [{
                        name: "event_source_arn",
                        condition: true,
                        value: "aws_dynamodb_table." + getPropertyValue(arrow.serviceData, "Name") + ".stream_arn",
                        dependsOnProperties: ["serviceIn." + arrow.arrowData.startId + ".Name"]
                    },
                    {
                        name: "function_name",
                        condition: true,
                        value: addQuotes(getPropertyValue(serviceData, "Name")),
                        dependsOnProperties: ["Name"]
                    },
                    {
                        name: "starting_position",
                        condition: true,
                        value: addQuotes("LATEST"),
                    },
                    ]
                }
            }) as Component[]),
            {
                component: "resource",
                type: "aws_lambda_function",
                componentName: getPropertyValue(serviceData, "Name"),
                dependsOnProperties: ["Name"],
                condition: true,
                arguments: functionArguments
            },
            ...(!serviceData.parents.some((p) => {
                return p.serviceData.serviceName === "IAM Role"
            }) ?
                [{
                    component: "data",
                    type: "aws_iam_policy_document",
                    componentName: "assume_role_doc_" + getPropertyValue(serviceData, "Name"),
                    dependsOnProperties: ["Name"],
                    condition: true,
                    arguments: [
                        {
                            name: "statement",
                            condition: true,
                            value: [
                                {
                                    name: "actions",
                                    condition: true,
                                    value: "[\"sts:AssumeRole\"]"
                                },
                                {
                                    name: "principals",
                                    condition: true,
                                    value: [
                                        {
                                            name: "type",
                                            condition: true,
                                            value: addQuotes("Service")
                                        },
                                        {
                                            name: "identifiers",
                                            condition: true,
                                            value: "[\"lambda.amazonaws.com\"]",
                                        },
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    component: "resource",
                    type: "aws_iam_role",
                    componentName: "role_" + getPropertyValue(serviceData, "Name"),
                    dependsOnProperties: ["Name"],
                    condition: true,
                    arguments: [
                        {
                            name: "name",
                            condition: true,
                            value: addQuotes("role_" + getPropertyValue(serviceData, "Name")),
                            dependsOnProperties: ["Name"]
                        },
                        {
                            name: "assume_role_policy",
                            condition: true,
                            value: "data.aws_iam_policy_document.assume_role_doc_" + getPropertyValue(serviceData, "Name") + ".json",
                            dependsOnProperties: ["Name"]
                        },

                    ]
                }] as Component[] : []),
            ...(serviceData.arrowOut.filter((arrow) => {
                return arrow.arrowData.type === "Interact"
            }).map((arrow, index) => {
                return {
                    component: "resource",
                    type: "aws_iam_role_policy",
                    componentName: "role_policy_" + getPropertyValue(serviceData, "Name") + "_" + index,
                    condition: hasArrowTypeOut(serviceData, "Interact"),
                    dependsOnProperties: ["arrowOut." + arrow.arrowData.shapeId + ".Name", "Name"],
                    arguments: [
                        {
                            name: "name",
                            condition: true,
                            value: addQuotes("role_policy_" + getPropertyValue(serviceData, "Name") + "_" + index),
                            dependsOnProperties: ["Name"]
                        },
                        {
                            name: "role",
                            condition: true,
                            value: serviceData.parents.some((p) => {
                                return p.serviceData.serviceName === "IAM Role"
                            }) ?
                                "aws_iam_role." + getPropertyValue(serviceData.parents.filter((p) => {
                                    return p.serviceData.serviceName === "IAM Role"
                                })[0].serviceData, "Name") + ".id" :
                                "aws_iam_role.role_" + getPropertyValue(serviceData, "Name") + ".id",
                            dependsOnProperties: serviceData.parents.some((p) => {
                                return p.serviceData.serviceName === "IAM Role"
                            }) ? ["parent." + serviceData.parents.filter((p) => {
                                return p.serviceData.serviceName === "IAM Role"
                            })[0].shapeId + ".Name"] : ["Name"]
                        },
                        {
                            name: "policy",
                            condition: true,
                            value: "data.aws_iam_policy_document." + "interact_policy_" + getPropertyValue(serviceData, "Name") + "_" + index + ".json",
                            dependsOnProperties: ["Name"]
                        },
                    ]
                }
            }) as Component[]),
            ...(serviceData.arrowOut.filter((arrow) => {
                return arrow.arrowData.type === "Interact"
            }).map((arrow, index) => {
                return {
                    component: "data",
                    type: "aws_iam_policy_document",
                    componentName: "interact_policy_" + getPropertyValue(serviceData, "Name") + "_" + index,
                    dependsOnProperties: ["serviceOut." + arrow.arrowData.endId + ".Name", "Name"],
                    condition: hasArrowTypeOut(serviceData, "Interact"),
                    arguments: [
                        {
                            name: "statement",
                            condition: true,
                            value: [{
                                name: "actions",
                                condition: true,
                                value: JSON.stringify(getPolicyActions(arrow.serviceData.serviceName, arrow.arrowData.properties.filter((p) => { return p.name === "Grant permission" })[0].value as string)),
                                dependsOnProperties: ["arrowOut." + arrow.arrowData.shapeId + ".Grant permission"]
                            },
                            {
                                name: "resources",
                                condition: true,
                                value: "[" + getResourceName(arrow.serviceData.serviceName) + "." + getPropertyValue(arrow.serviceData, "Name") + ".arn]",
                                dependsOnProperties: ["serviceOut." + arrow.arrowData.endId + ".Name"]
                            }
                            ],
                        },
                    ]
                }
            }) as Component[])
        ]
    }
}