import { ArrowDefinition } from "../../Service"

export const policy: ArrowDefinition = {
    type: "Policy",
    fromType: "IAM Policy",
    toType: "IAM Role",
    properties: [{
        name: "Name",
        condition: () => { return true },
        defaultValue: "",
        type: "string",
        mandatory: true,
        hide: true
    }]
}