import { MappedService } from "../editor-handler/EditorHandler"
import { Argument, ArrowData, ServiceData, ServiceTranslation, getServiceTranslation } from "../services/Service"

export type CodeArgument = {
    name: string,
    value: string | CodeArgument[],
    conditionVerified?: boolean
    state?: string
    dependsOnProperties?: string[]
}

export type CodeComponent = {
    component: "data" | "resource"
    type: string
    componentName: string
    dependsOnProperties?: string[]
    conditionVerified?: boolean
    state?: string
    arguments: CodeArgument[]
}

export const getChangedProperties = (newProperties: any[],
    arrowIn: { serviceData: ServiceData; arrowData: ArrowData; }[],
    arrowOut: { serviceData: ServiceData; arrowData: ArrowData; }[],
    parents: { serviceData: ServiceData, shapeId: string }[],
    oldProperties: { name: string, value: string }[]) => {

    const setArrowChangedProperties = (arrow: { serviceData: ServiceData; arrowData: ArrowData; }, type: "arrowIn" | "arrowOut") => {
        arrow.arrowData.properties.forEach((arrowProp) => {
            const old = oldProperties.filter((o) => { return o.name === type + "." + arrow.arrowData.shapeId + "." + arrowProp.name })
            if (old.length > 0) {
                if (typeof arrowProp.value !== "string") {
                    JSON.stringify(arrowProp.value) !== old[0].value && changedProperties.push(old[0].name)
                } else {
                    arrowProp.value !== old[0].value && changedProperties.push(old[0].name)
                }
            } else {
                changedProperties.push(type + "." + arrow.arrowData.shapeId + "." + arrowProp.name)
            }
        })
    }

    const setParentsChangedProperties = (parent: { serviceData: ServiceData; shapeId: string }) => {
        parent.serviceData.properties.forEach((parentProp) => {
            const old = oldProperties.filter((o) => { return o.name === "parent." + parent.shapeId + "." + parentProp.name })
            if (old.length > 0) {
                if (typeof parentProp.value !== "string") {
                    JSON.stringify(parentProp.value) !== old[0].value && changedProperties.push(old[0].name)
                } else {
                    parentProp.value !== old[0].value && changedProperties.push(old[0].name)
                }
            } else {
                changedProperties.push("parent." + parent.shapeId + "." + parentProp.name)
            }
        })
    }

    const setServiceChangedProperties = (arrow: { serviceData: ServiceData; arrowData: ArrowData; }, type: "serviceIn" | "serviceOut") => {
        arrow.serviceData.properties.forEach((prop) => {
            const old = oldProperties.filter((o) => {
                return o.name === type + "." + (type === "serviceIn" ? arrow.arrowData.startId : arrow.arrowData.endId) + "." + prop.name
            })
            if (old.length > 0) {
                if (typeof prop.value !== "string") {
                    JSON.stringify(prop.value) !== old[0].value && changedProperties.push(old[0].name)
                } else {
                    prop.value !== old[0].value && changedProperties.push(old[0].name)
                }
            } else {
                changedProperties.push(type + "." + (type === "serviceIn" ? arrow.arrowData.startId : arrow.arrowData.endId) + "." + prop.name)
            }
        })
    }


    const changedProperties: string[] = []

    if (oldProperties.length === 0) return []
    newProperties.forEach((n) => {
        const old = oldProperties.filter((o) => { return o.name === n.name })[0]
        if (typeof n.value !== "string") {
            JSON.stringify(n.value) !== old.value && changedProperties.push(n.name as string)
        } else {
            n.value !== old.value && changedProperties.push(n.name as string)
        }
    })
    arrowIn.forEach((arrow) => {
        setArrowChangedProperties(arrow, "arrowIn")
        setServiceChangedProperties(arrow, "serviceIn")
    })
    arrowOut.forEach((arrow) => {
        setArrowChangedProperties(arrow, "arrowOut")
        setServiceChangedProperties(arrow, "serviceOut")
    })
    parents.forEach((parent) => {
        setParentsChangedProperties(parent)
    })

    return changedProperties;
}

export const getFileCodeBlock = (service: MappedService, setCodeState: (components: CodeComponent[]) => void) => {
    const newCodeText: string[] = []

    const serviceData = service.serviceData
    const fr = service.codeState.length === 0
    const codeState = service.codeState
    if (serviceData && serviceData.properties) {
        const serviceTranslation = getServiceTranslation(serviceData);
        const oldProperties = service.oldProperties
        const changedProperties = getChangedProperties(serviceData.properties, serviceData.arrowIn, serviceData.arrowOut, serviceData.parents, oldProperties)
        const comp = serviceTranslation.components.map((c) => {
            return {
                component: c.component,
                dependsOnProperties: c.dependsOnProperties,
                type: c.type,
                conditionVerified: c.condition,
                componentName: c.componentName,
                arguments: getArguments(c.arguments, changedProperties, fr, true)
            }
        })
        const state = serviceTranslation.components.filter((c) => {

            return (fr || changedProperties.some((cp) => {
                return (c.dependsOnProperties && c.dependsOnProperties?.indexOf(cp) !== -1) ||
                    getInnerDependsOnProperties(c.arguments).indexOf(cp) !== -1
            }))
        }).map((c) => {
            return {
                component: c.component,
                dependsOnProperties: c.dependsOnProperties,
                type: c.type,
                conditionVerified: c.condition,
                componentName: c.componentName,
                arguments: getArguments(c.arguments, changedProperties, fr)
            }
        })
        newCodeText.push(createCodeText(state, comp, codeState, fr, serviceTranslation,
            (newCodeState: CodeComponent[]) => setCodeState(newCodeState)))
    }

    return newCodeText
}


export const getFileContent = (
    serviceDataMapRef: React.MutableRefObject<Map<string, MappedService>>,
    focusedFile: string,
    setCodeState: (key: string, newCodeState: CodeComponent[]) => void,
    generalCodeState: CodeComponent[]
) => {
    const newCompleteState = new Map<string, CodeComponent[]>
    const newCodeText: string[] = []


    serviceDataMapRef.current.forEach((mappedService, key) => {
        if (mappedService.serviceData.filePath === focusedFile) {
            const serviceData = mappedService.serviceData
            const fr = mappedService.codeState.length === 0
            const codeState = mappedService.codeState
            if (serviceData && serviceData.properties) {
                const serviceTranslation = getServiceTranslation(serviceData);
                const oldProperties = mappedService.oldProperties
                const changedProperties = getChangedProperties(serviceData.properties, serviceData.arrowIn, serviceData.arrowOut, serviceData.parents, oldProperties)
                const comp = serviceTranslation.components.map((c) => {
                    return {
                        component: c.component,
                        dependsOnProperties: c.dependsOnProperties,
                        type: c.type,
                        conditionVerified: c.condition,
                        componentName: c.componentName,
                        arguments: getArguments(c.arguments, changedProperties, fr, true)
                    }
                })
                newCompleteState.set(key, comp)
                const state = serviceTranslation.components.filter((c) => {

                    return (fr || changedProperties.some((cp) => {
                        return (c.dependsOnProperties && c.dependsOnProperties?.indexOf(cp) !== -1) ||
                            getInnerDependsOnProperties(c.arguments).indexOf(cp) !== -1
                    }))
                }).map((c) => {
                    return {
                        component: c.component,
                        dependsOnProperties: c.dependsOnProperties,
                        type: c.type,
                        conditionVerified: c.condition,
                        componentName: c.componentName,
                        arguments: getArguments(c.arguments, changedProperties, fr)
                    }
                })
                newCodeText.push(createCodeText(state, comp, codeState, fr, serviceTranslation,
                    (newCodeState: CodeComponent[]) => setCodeState(key, newCodeState)))
            }
        }
    })

    if (generalCodeState) {
        newCodeText.push(createCodeText([], [], [], false, undefined, undefined, generalCodeState))
    }
    return { newCodeText, newCompleteState }
}

export const getArguments = (ar: Argument[], changedProperties: string[], firstRender: boolean, completeState?: boolean): CodeArgument[] => {
    return ar.filter((a) => {
        return completeState || firstRender || changedProperties.some((cp) => {
            return (a.dependsOnProperties && a.dependsOnProperties?.indexOf(cp) !== -1) ||
                (Array.isArray(a.value) && getInnerDependsOnProperties(a.value as Argument[]).indexOf(cp) !== -1)
        })
    }).map((a: Argument) => {
        if (Array.isArray(a.value)) {
            return {
                name: a.name,
                conditionVerified: a.condition,
                dependsOnProperties: a.dependsOnProperties,
                value: getArguments(a.value as Argument[], changedProperties, firstRender, completeState)
            }
        } else {
            return {
                name: a.name,
                conditionVerified: a.condition,
                dependsOnProperties: a.dependsOnProperties,
                value: a.value as string
            }
        }
    })
}

export const getInnerDependsOnProperties = (args: Argument[]) => {
    const dependsOnProperties: string[] = []

    const appendToDependencyArray = (newArr: string[]) => {
        newArr.forEach(elem => {
            if (dependsOnProperties.indexOf(elem) === -1) {
                dependsOnProperties.push(elem)
            }
        })
    }

    args.forEach(arg => {
        if (arg.dependsOnProperties) appendToDependencyArray(arg.dependsOnProperties)
        if (Array.isArray(arg.value)) appendToDependencyArray(getInnerDependsOnProperties(arg.value as Argument[]))
    })
    return dependsOnProperties
}

export const createCodeText = (state: CodeComponent[], completeState: CodeComponent[], codeState: CodeComponent[],
    firstRender?: boolean, serviceTranslation?: ServiceTranslation, setCodeState?: (codeState: CodeComponent[]) => void,
    providedFullComponents?: CodeComponent[]
) => {
    const newCodeState = providedFullComponents || (firstRender ? state.filter(s => {
        return s.conditionVerified
    })
        .map(s => {
            return {
                component: s.component,
                type: s.type,
                componentName: s.componentName,
                dependsOnProperties: s.dependsOnProperties,
                state: s.state,
                arguments: removeNotVerified(s.arguments),
            }
        }) : mergeWithCodeState(state, completeState, codeState, serviceTranslation!, setCodeState!))
    if (!providedFullComponents) {
        newCodeState.forEach((c) => {
            if (!codeState.some((codeComponent) => {
                return c.componentName === codeComponent.componentName &&
                    c.type === codeComponent.type &&
                    c.component === codeComponent.component
            })) codeState.push({
                ...c,
                state: "UNEDITED"
            })
        })
        setCodeState!(codeState)
    }
    return newCodeState
        .map((c) => {
            return (
                `${c.component} "${c.type}" "${c.componentName}" {\n` +
                appendArgs(c.arguments, "    ") + "}"
            )
        }).join("\n")
}

const removeNotVerified = (args: CodeArgument[]): CodeArgument[] => {
    return args.filter((a) => {
        return a.conditionVerified
    }).map((a) => {
        return {
            name: a.name,
            conditionVerified: a.conditionVerified,
            value: Array.isArray(a.value) ? removeNotVerified(a.value) : a.value
        }
    })
}

const mergeWithCodeState = (newState: CodeComponent[], completeState: CodeComponent[],
    codeState: CodeComponent[], serviceTranslation: ServiceTranslation, setCodeState: (codeState: CodeComponent[]) => void) => {
    const finalState: CodeComponent[] = []
    codeState.forEach((codeComponent, index) => {
        const isAlreadyPushed = codeState.some((cs, i) => {
            return i < index && cs.componentName === codeComponent.componentName &&
                cs.type === codeComponent.type &&
                cs.component === codeComponent.component
        })
        const newComponent = newState.filter((n) => {
            return n.componentName === codeComponent.componentName &&
                n.type === codeComponent.type &&
                n.component === codeComponent.component
        })
        const completeComponent = completeState.filter((n) => {
            return n.componentName === codeComponent.componentName &&
                n.type === codeComponent.type &&
                n.component === codeComponent.component
        })
        if (newComponent.length === 0 || isAlreadyPushed) {
            if (completeComponent.length === 0 || isAlreadyPushed) {
                if (codeComponent.state !== "DELETED" && codeComponent.state !== "HIDDEN" && codeComponent.state !== "UNEDITED")
                    finalState.push({
                        component: codeComponent.component,
                        type: codeComponent.type,
                        componentName: codeComponent.componentName,
                        dependsOnProperties: codeComponent.dependsOnProperties,
                        state: codeComponent.state,
                        arguments: removeDeleted(codeComponent.arguments, []),
                    })
                if (codeComponent.state === "UNEDITED") {
                    codeComponent.state = "HIDDEN"
                }
            } else {
                if (codeComponent.state !== "DELETED") {
                    if (completeComponent[0].conditionVerified || codeComponent.state !== "HIDDEN")
                        finalState.push({
                            component: codeComponent.component,
                            type: codeComponent.type,
                            componentName: codeComponent.componentName,
                            dependsOnProperties: codeComponent.dependsOnProperties,
                            state: codeComponent.state,
                            arguments: removeDeleted(codeComponent.arguments, completeComponent[0].arguments),
                        })
                }
            }
        }
        else {
            const completeArgs = completeComponent[0].arguments
            const args = serviceTranslation.components.filter((c) => {
                return c.componentName === newComponent[0].componentName &&
                    c.type === newComponent[0].type &&
                    c.component === newComponent[0].component
            })[0].arguments
            if (codeComponent.state === "UNEDITED" || codeComponent.state === "HIDDEN") {
                if (newComponent[0].conditionVerified) {
                    finalState.push({
                        component: newComponent[0].component,
                        type: newComponent[0].type,
                        componentName: newComponent[0].componentName,
                        dependsOnProperties: newComponent[0].dependsOnProperties,
                        state: "UNEDITED",
                        arguments: mergeArguments(newComponent[0].arguments, codeComponent.arguments, args, completeArgs),
                    })
                } else {
                    codeComponent.state = "HIDDEN"
                }
            }
            else {
                if (codeComponent.state !== "DELETED") {
                    finalState.push({
                        component: codeComponent.component,
                        type: codeComponent.type,
                        componentName: codeComponent.componentName,
                        dependsOnProperties: codeComponent.dependsOnProperties,
                        state: codeComponent.state,
                        arguments: mergeArguments(newComponent[0].arguments, codeComponent.arguments, args, completeArgs),
                    })
                }
            }
        }
    })

    completeState.forEach((newComp) => {
        if (newComp.conditionVerified && !codeState.some((codeComponent) => {
            return newComp.componentName === codeComponent.componentName &&
                newComp.type === codeComponent.type &&
                newComp.component === codeComponent.component
        })) {
            const replacedState = serviceTranslation.components.filter((c) => {
                return c.componentName === newComp.componentName &&
                    c.type === newComp.type &&
                    c.component === newComp.component
            })[0]

            const stateToPush = {
                component: replacedState.component,
                type: replacedState.type,
                componentName: replacedState.componentName,
                dependsOnProperties: replacedState.dependsOnProperties,
                arguments: removeNotVerifiedArguments(replacedState.arguments),
            }

            finalState.push(stateToPush)
        }
    })
    return finalState
}

const mergeArguments = (newArguments: CodeArgument[], oldCodeArguments: CodeArgument[], serviceTranslationArguments: Argument[], completeArguments: CodeArgument[]) => {
    const finalArguments: CodeArgument[] | string = []
    oldCodeArguments.forEach((oldArg, index) => {
        const isAlreadyPushed = oldCodeArguments.some((ca, i) => {
            return i < index && ca.name === oldArg.name
        })
        const newArg = newArguments.filter((n) => {
            return oldArg.name === n.name
        })
        const completeArg = completeArguments.filter((n) => {
            return n.name === oldArg.name
        })
        if (newArg.length === 0 || isAlreadyPushed) {
            if (completeArg.length === 0 || isAlreadyPushed) {
                if (oldArg.state !== "DELETED" && oldArg.state !== "UNEDITED" && oldArg.state !== "HIDDEN") {
                    finalArguments.push(Array.isArray(oldArg.value) ? {
                        name: oldArg.name,
                        state: oldArg.state,
                        value: removeDeleted(oldArg.value, [])
                    } : oldArg)
                }
                if (oldArg.state === "UNEDITED")
                    oldArg.state = "HIDDEN"
            }
            else if (oldArg.state !== "DELETED") {
                if (completeArg[0].conditionVerified || oldArg.state !== "HIDDEN")
                    finalArguments.push(Array.isArray(oldArg.value) ? {
                        name: oldArg.name,
                        state: oldArg.state,
                        value: removeDeleted(oldArg.value, Array.isArray(completeArg[0].value) ? completeArg[0].value : [])
                    } : oldArg)
            }
        }
        else {
            const completeArgs = completeArg[0].value;
            const args = serviceTranslationArguments.filter((c) => {
                return c.name === newArg[0].name
            })[0].value
            if (oldArg.state === "UNEDITED" || oldArg.state === "HIDDEN") {
                if (newArg[0].conditionVerified) {
                    finalArguments.push({
                        name: newArg[0].name,
                        state: "UNEDITED",
                        value: Array.isArray(newArg[0].value) && Array.isArray(oldArg.value) && Array.isArray(args) ?
                            mergeArguments(newArg[0].value, oldArg.value, args, completeArgs as CodeArgument[]) : newArg[0].value
                    })
                } else {
                    oldArg.state = "HIDDEN"
                }
            } else {
                if (oldArg.state !== "DELETED")
                    finalArguments.push({
                        name: oldArg.name,
                        state: oldArg.state,
                        value: Array.isArray(newArg[0].value) && Array.isArray(oldArg.value) && Array.isArray(args) ?
                            mergeArguments(newArg[0].value, oldArg.value, args, completeArgs as CodeArgument[]) : oldArg.value
                    })
            }
        }
    })
    completeArguments.forEach(newArg => {

        if (newArg.conditionVerified && !oldCodeArguments.some((o) => {
            return o.name === newArg.name
        })) {
            const replacedState = serviceTranslationArguments.filter((c) => {
                return c.name === newArg.name
            })[0]
            finalArguments.push({
                name: replacedState.name,
                value: Array.isArray(replacedState.value) ? removeNotVerifiedArguments(replacedState.value as Argument[]) :
                    replacedState.value as string,
            })
        }
    })
    return finalArguments;
}

const removeDeleted = (args: CodeArgument[], completeArgs: CodeArgument[]): CodeArgument[] => {
    const finalArguments: CodeArgument[] = []
    args.forEach(a => {
        const completeArg = completeArgs.filter((c) => {
            return c.name === a.name
        })
        if (completeArg.length === 0) {
            if (a.state !== "DELETED" && a.state !== "HIDDEN" && a.state !== "UNEDITED") {
                finalArguments.push({
                    name: a.name,
                    value: Array.isArray(a.value) ? removeDeleted(a.value, []) : a.value,
                    state: a.state
                })
            }
            if (a.state === "UNEDITED") {
                a.state = "HIDDEN"
            }
        } else {
            if (a.state !== "DELETED") {
                if (completeArg[0].conditionVerified || a.state !== "HIDDEN")
                    finalArguments.push({
                        name: a.name,
                        value: Array.isArray(a.value) ?
                            removeDeleted(a.value, Array.isArray(completeArg[0].value) ? completeArg[0].value : []) :
                            a.state === "UNEDITED" ?
                                completeArg[0].value :
                                a.value,
                        state: a.state
                    })
            }
        }
    })
    return finalArguments
}

const removeNotVerifiedArguments = (args: Argument[]): CodeArgument[] => {
    return args.filter((a) => {
        return a.condition
    }).map((a) => {
        return {
            name: a.name,
            conditionVerified: a.condition,
            value: Array.isArray(a.value) ? removeNotVerifiedArguments(a.value as Argument[]) : a.value
        }
    }) as CodeArgument[]
}

function appendArgs(args: CodeArgument[], indentation: string) {

    let res = '';
    args.forEach((a) => {
        res += `${indentation}${a.name} `
        if (Array.isArray(a.value)) {
            res += `{\n${appendArgs(a.value as CodeArgument[], indentation + "    ")}${indentation}}\n`
        } else {
            res += `= ${a.value}\n`
        }
    })

    return res;
}