import { Button } from '@mui/material'
import {
    BaseBoxShapeTool,
    BaseBoxShapeUtil,
    DefaultColorStyle,
    HTMLContainer,
    StyleProp,
    T,
    TLBaseShape,
    TLDefaultColorStyle,
    TLShape,
    TLShapeUtilFlag,
    getDefaultColorTheme,
} from '@tldraw/tldraw'
import React from 'react'

// Define a style that can be used across multiple shapes.
// The ID (myApp:filter) must be globally unique, so we recommend prefixing it with a namespace.


export type FolderShape = TLBaseShape<
    'folder',
    {
        w: number
        h: number
        name: string
        onClick?: () => void | undefined
    }
>

export class FolderShapeUtil extends BaseBoxShapeUtil<FolderShape> {
    static override type = 'folder' as const

    static override props = {
        w: T.number,
        h: T.number,
        // You can re-use tldraw built-in styles...
        name: T.string,
        onClick: T.any
    }

    override isAspectRatioLocked = (_shape: FolderShape) => false
    override canResize = (_shape: FolderShape) => true
    override canBind = (_shape: FolderShape) => false
    override hideRotateHandle = (_shape: FolderShape) => true

    override getDefaultProps(): FolderShape['props'] {
        return {
            w: 600,
            h: 400,
            name: "Template",
        }
    }

    component(shape: FolderShape) {
        const bounds = this.editor.getShapeGeometry(shape).bounds
        const theme = getDefaultColorTheme({ isDarkMode: this.editor.user.isDarkMode })

        return (
            <HTMLContainer
                id={shape.id}
                style={{
                    border: `6px solid #797181`,
                    borderRadius: 8,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    pointerEvents: 'all',
                    backgroundColor: "transparent",
                }}
            >

                <div className={`absolute top-0 left-0 text-center max-w-full p-3 text-3xl text-white truncate rounded-br`}
                    style={{ backgroundColor: "#797181" }}
                >
                    {shape.props.name}
                </div>
                <Button
                    onClick={shape.props.onClick}
                    sx={{
                        position: "absolute",
                        bottom: "0",
                        right: "0",
                        color: "white",
                        backgroundColor: "#797181",
                        fontSize: "1.5rem",
                        padding: "0.75rem",
                        borderRadius: "8px 0 0 0",
                        "&:hover": {
                            backgroundColor: "#544f5a",
                            color: "white"
                        }
                    }}
                    onPointerDown={(e) => e.stopPropagation()}>
                    {"Save template"}
                </Button>

            </HTMLContainer>
        )
    }

    async toSvg(shape: FolderShape) {
        const xmlns = 'http://www.w3.org/2000/svg';

        // Create the main SVG group
        const g = document.createElementNS(xmlns, 'g');

        // Create the background rectangle
        const rect = document.createElementNS(xmlns, 'rect');
        rect.setAttributeNS(null, 'width', shape.props.w.toString());
        rect.setAttributeNS(null, 'height', shape.props.h.toString());
        rect.setAttributeNS(null, 'rx', '8'); // match the borderRadius from the component style
        rect.setAttributeNS(null, 'fill', "transparent");
        rect.setAttributeNS(null, 'stroke', "#797181");
        rect.setAttributeNS(null, 'stroke-width', '6');

        // Append the rectangle to the main group
        g.appendChild(rect);

        // Return the main group
        return g
    }

    // Indicator — used when hovering over a shape or when it's selected; must return only SVG elements here
    indicator(shape: FolderShape) {
        return <rect width={shape.props.w} height={shape.props.h} />
    }

}

// Extending the base box shape tool gives us a lot of functionality for free.
export class FolderShapeTool extends BaseBoxShapeTool {
    static override id = 'folder'
    static override initial = 'idle'
    override shapeType = 'folder'
    props = {
        w: T.number,
        h: T.number,
        // You can re-use tldraw built-in styles...
        color: DefaultColorStyle,
        // ...or your own custom styles.
    }
}