export const validateLambdaName = (lambdaName: string) => {
    let messages = [];
    let isValid = true;

    // Checking length of function name
    if (lambdaName.length < 1 || lambdaName.length > 64) {
        messages.push("Function names must be between 1 and 140 characters long.");
        isValid = false;
    }

    // Checking if the function name consists of letters, numbers, hyphens (-), or underscores (_)
    if (!/^[a-zA-Z0-9-_]+$/.test(lambdaName)) {
        messages.push("Function names should only consist of letters, numbers, hyphens (-), and underscores (_).");
        isValid = false;
    }

    // Checking if the function name doesn't contain a colon (:)
    if (/:/.test(lambdaName)) {
        messages.push("Function names should not contain a colon (:).");
        isValid = false;
    }

    return {
        isValid,
        messages
    };
}

export const validateFunctionHandler = (handler: string) => {
    let messages = [];
    let isValid = true;

    // Checking if the handler consists of two parts divided by '.'
    const handlerParts = handler.split('.')
    if (handlerParts.length !== 2) {
        messages.push("Handler should be in the format: {filename}.{exported_function_name}.");
        isValid = false;
    }

    return {
        isValid,
        messages
    };
}

export const validateLocalZipPath = (zipPath: string) => {
    let messages = [];
    let isValid = true;

    /*
    // Checking if file exists at given path
    if (!fs.existsSync(zipPath)) {
      messages.push("There is no file at the provided path.");
      isValid = false;
    } else {
        */
    // Checking if the file is a zip file
    if (!zipPath.endsWith('.zip')) {
        messages.push("The file at the provided path should be a zip file.");
        isValid = false;
    }

    return {
        isValid,
        messages
    };
}