export const validateSNSTopicName = (topicName: string) => {
    let messages = [];
    let isValid = true;

    // Checking length of topic name
    if (topicName.length < 1 || topicName.length > 256) {
        messages.push("SNS Topic names must be between 1 and 256 characters long.");
        isValid = false;
    }

    // Checking if the topic name consists only of alphanumeric characters, hyphens (-), and underscores (_)
    if (!/^[a-zA-Z0-9_-]+$/.test(topicName)) {
        messages.push("SNS Topic names can only consist of alphanumeric characters, hyphens (-), and underscores (_).");
        isValid = false;
    }

    return {
        isValid,
        messages
    };
}