import { Argument, Component, Service, ServiceData, ServiceTranslation, addQuotes, addSquareBrackets, getObjectFormat, getParentByServiceName, getParentShapeId, getParentsWithType, getPropertyFromArrowIn, getPropertyValue, hasArrowTypeIn, removeQuotes, stringToObject, updatePropertyValue } from "../../Service";

const instanceTypes = [
    "t2.micro",
    "t2.small",
    "t2.medium",
    "t3.micro",
    "t3.small",
    "t3.medium",
    "m5.large",
    "m5.xlarge",
    "c5.large",
    "c5.xlarge",
    "r5.large",
    "r5.xlarge",
    "i3.large",
    "i3.xlarge",
    "p3.2xlarge",
    "p2.xlarge",
    "a1.medium",
    "a1.large",
    "t3a.nano",
    "t3a.micro"
]

export const ec2: Service = {
    serviceName: "EC2",
    defaultName: "virtual-machine",
    borderColor: "#E47413",
    category: "Compute",
    backgroundColor: "#fae3ce",
    filePath: "",
    parentsTypes: ["Subnet", "Security group"],
    validate: () => { return { isValid: true, messages: [] } },
    isWrapper: false,
    imgUrl: "img/EC2.png",
    arrowInTypes: [],
    arrowOutTypes: [],
    properties: [
        {
            name: "OS Image",
            condition: () => { return true },
            docsUrl: "",
            tfDocsUrl: "https://registry.terraform.io/providers/hashicorp/aws/latest/docs/resources/instance",
            defaultValue: "Amazon Linux",
            type: "string",
            mandatory: true,
            choices: ["Amazon Linux", "Ubuntu", "Windows", "Red Hat", "Debian", "SUSE Linux"]
        },
        {
            name: "Instance type",
            condition: () => { return true },
            docsUrl: "",
            tfDocsUrl: "",
            defaultValue: "t2.micro",
            type: "string",
            mandatory: true,
            choices: instanceTypes
        },
        {
            name: "Assign public IP",
            condition: () => { return true },
            docsUrl: "",
            tfDocsUrl: "",
            defaultValue: "Yes",
            type: "string",
            mandatory: true,
            choices: ["Yes", "No"]
        },
    ],
}

const getAmi = (os: string) => {
    switch (os) {
        case "Amazon Linux":
            return "ami-0fc067f03ad87bb64"
        case "Ubuntu":
            return "ami-05b5a865c3579bbc4"
        case "Windows":
            return "ami-06ce55df7975baa3b"
        case "Red Hat":
            return "ami-0d767e966f3458eb5"
        case "Debian":
            return "ami-0f014d1f920a73971"
        case "SUSE Linux":
            return "ami-0789618b96ed86580"
    }
    return "ami-0fc067f03ad87bb64"
}

export const ec2ServiceTranslation = (serviceData: ServiceData): ServiceTranslation => {

    const args: Argument[] = [{
        name: "ami",
        condition: true,
        value: addQuotes(getAmi(getPropertyValue(serviceData, "OS Image"))),
        dependsOnProperties: ["OS Image"]
    },
    {
        name: "instance_type",
        condition: true,
        value: addQuotes(getPropertyValue(serviceData, "Instance type")),
        dependsOnProperties: ["Instance type"]
    }]

    getParentByServiceName(serviceData, "Subnet") !== undefined && args.push({
        name: "subnet_id",
        condition: true,
        value: "aws_subnet.subnet.id",
        dependsOnProperties: ["parent." + getParentShapeId(serviceData, "Subnet") + ".Address range"]
    })
    getParentByServiceName(serviceData, "Security group") !== undefined && args.push({
        name: "vpc_security_group_ids",
        condition: true,
        value: "[" + getParentsWithType(serviceData, "Security group").map((p) => {
            return "aws_security_group.security_group.id"
        }).join(", ") + "]",
        dependsOnProperties: ["parent." + getParentShapeId(serviceData, "Security group") + ".Allow inbound SSH"]
    })
    return {
        components: [
            {
                component: "resource",
                type: "aws_instance",
                componentName: "instance",
                condition: true,
                arguments: args
            }
        ]
    }
}