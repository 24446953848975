import { FirebaseApp } from "firebase/app";
import { MappedService } from "../editor-handler/EditorHandler";
import { getAuth } from "firebase/auth";
import { deleteField, doc, getDoc, getFirestore, setDoc } from "firebase/firestore";
import { ArrowData, arrowsProperties, services } from "../services/Service";
import { CodeComponent } from "../code/codeWriteUtils";
import { getAvailableName } from "./naming";


export const composeServiceDataMap = (serviceData: any) => {
    const newServiceDataMap = new Map<string, MappedService>()
    serviceData.forEach((elem: any) => {
        newServiceDataMap.set(elem.id, {
            serviceData: {
                name: elem.serviceData.name,
                serviceName: elem.serviceData.serviceName,
                filePath: elem.serviceData.filePath,
                validate: services.filter((s) => {
                    return s.serviceName === elem.serviceData.serviceName
                })[0].validate,
                isWrapper: elem.serviceData.isWrapper,
                properties: elem.serviceData.properties.map((p: any, index: number) => {
                    return {
                        name: p.name,
                        value: p.value,
                        isName: p.isName,
                        defaultValue: p.defaultValue,
                        multipleChoice: p.multipleChoice,
                        databaseEntry: p.databaseEntry,
                        databaseKey: p.databaseKey,
                        condition: services.filter((s) => {
                            return s.serviceName === elem.serviceData.serviceName
                        })[0].properties[index].condition,
                        validate: services.filter((s) => {
                            return s.serviceName === elem.serviceData.serviceName
                        })[0].properties[index].validate,
                        isDockerImage: p.isDockerImage,
                        containerDefinition: p.containerDefinition,
                        advanced: p.advanced,
                        choices: p.choices,
                        mandatory: p.mandatory,
                        type: p.type,
                        hide: p.hide
                    }
                }),
                arrowInTypes: elem.serviceData.arrowInTypes,
                arrowOutTypes: elem.serviceData.arrowOutTypes,
                parentsTypes: elem.serviceData.parentsTypes,
                arrowIn: [],
                arrowOut: [],
                parents: []
            },
            codeState: JSON.parse(JSON.stringify(elem.codeState)),
            oldProperties: elem.oldProperties
        })
    })
    return newServiceDataMap
}

export const composeArrowsData = (arrowsData: any) => {
    return arrowsData.map((a: any) => {
        return {
            ...a,
            properties: a.properties.map((p: any, index: number) => {
                return {
                    ...p,
                    condition: arrowsProperties.filter((ap) => {
                        return ap.type === a.type
                    })[0].properties[index].condition
                }
            })
        }
    })

}

export const assignPropsToServiceDataMap = (arrowsData: ArrowData[], serviceDataMap: Map<string, MappedService>, parents: any, skipArrows?: boolean) => {
    serviceDataMap.forEach((sdmValue, key) => {
        if (!skipArrows) {
            sdmValue.serviceData.arrowIn = arrowsData.filter((ad) => {
                return ad.endId === key
            }).map((ad) => {
                return {
                    arrowData: ad,
                    serviceData: serviceDataMap.get(ad.startId)?.serviceData!
                }
            })
            sdmValue.serviceData.arrowOut = arrowsData.filter((ad) => {
                return ad.startId === key
            }).map((ad) => {
                return {
                    arrowData: ad,
                    serviceData: serviceDataMap.get(ad.endId)?.serviceData!
                }
            })
        }
        sdmValue.serviceData.parents = parents ? parents.filter((p: any) => {
            return p.childId === key
        }).map((p: any) => {
            return {
                serviceData: serviceDataMap.get(p.parentId)?.serviceData!,
                shapeId: p.parentId
            }
        }) : []
    }
    )

}

export const getServiceDataMap = async (app: FirebaseApp) => {
    const auth = getAuth(app)
    const fs = getFirestore(app)
    const documentRef = doc(fs, "state", auth.currentUser?.uid!)
    const docSnap = (await getDoc(documentRef)).data()
    if (docSnap && docSnap.serviceDataMap) {
        const newServiceDataMap = composeServiceDataMap(docSnap.serviceDataMap)

        const newGeneralCodeState = docSnap.generalCodeState || {}
        const newTerraformTemplate = docSnap.terraformTemplate || {}
        const newFileContents = docSnap.fileContents || {}

        const newArrowsData: ArrowData[] = docSnap.arrowsData ? composeArrowsData(docSnap.arrowsData) : []

        assignPropsToServiceDataMap(newArrowsData, newServiceDataMap, docSnap.parents)

        return {
            serviceDataMap: newServiceDataMap,
            arrowsData: newArrowsData,
            generalCodeState: newGeneralCodeState,
            terraformTemplate: newTerraformTemplate,
            fileContents: newFileContents,
        }
    }
}


export const updateArrowsData = async (newArrows: ArrowData[], app: FirebaseApp) => {
    const auth = getAuth(app)
    const fs = getFirestore(app)
    const documentRef = doc(fs, "state", auth.currentUser?.uid!)
    await setDoc(documentRef, {
        arrowsData: formatArrowsData(newArrows)
    }, { merge: true })
}

const formatArrowsData = (arrowsData: ArrowData[]) => {
    return JSON.parse(JSON.stringify(arrowsData.map((a) => {
        return {
            ...a,
            properties: a.properties.map((prop) => {
                return {
                    ...prop,
                    condition: undefined
                }
            })
        }
    })))
}

const formatServiceDataMap = (serviceDataMap: Map<string, MappedService>) => {
    const serviceData: any[] = []
    const parents: {
        parentId: string;
        childId: string
    }[] = []
    serviceDataMap.forEach((value, id) => {
        parents.push(...value.serviceData.parents.map((p) => {
            return {
                parentId: p.shapeId,
                childId: id
            }
        }))
        serviceData.push({
            id: id,
            serviceData: JSON.parse(JSON.stringify({
                name: value.serviceData.name,
                serviceName: value.serviceData.serviceName,
                filePath: value.serviceData.filePath,
                isWrapper: value.serviceData.isWrapper,
                properties: value.serviceData.properties.map((p) => {
                    return {
                        name: p.name,
                        value: p.value,
                        defaultValue: p.defaultValue,
                        multipleChoice: p.multipleChoice,
                        isDockerImage: p.isDockerImage,
                        containerDefinition: p.containerDefinition,
                        advanced: p.advanced,
                        choices: p.choices,
                        databaseEntry: p.databaseEntry,
                        databaseKey: p.databaseKey,
                        isName: p.isName,
                        mandatory: p.mandatory,
                        type: p.type,
                        hide: p.hide,
                    }
                }),
                arrowInTypes: value.serviceData.arrowInTypes,
                arrowOutTypes: value.serviceData.arrowOutTypes,
                parentsTypes: value.serviceData.parentsTypes
            })),
            codeState: JSON.parse(JSON.stringify(value.codeState)),
            oldProperties: JSON.parse(JSON.stringify(value.oldProperties))
        })
    })
    return { parents, serviceData }

}

export const updateTemplates = async (newTemplates: {
    folder: string;
    folderName: string;
    width: number;
    height: number;
    children: {
        id: string;
        relativePositionX: number;
        relativePositionY: number;
        width?: number;
        height?: number;
        start?: any;
        end?: any;
    }[];
}[], serviceDataMap: Map<string, MappedService>,
    arrowData: ArrowData[],
    app: FirebaseApp) => {

    const auth = getAuth(app)
    const fs = getFirestore(app)
    const documentRef = doc(fs, "userTemplates", auth.currentUser?.uid!)
    //check if the new templates parent is already in the templates, if so, update it, if not, add it
    const docSnap = (await getDoc(documentRef)).data()
    const newTemplatesMap = new Map<string, {
        folder: string;
        folderName: string;
        width: number;
        height: number;
        children: {
            id: string;
            relativePositionX: number;
            relativePositionY: number;
            start?: any;
            end?: any;
            width?: number;
            height?: number;
        }[];
        serviceData: any[];
        parents: any[];
        arrowsData: any[]
    }>()
    if (docSnap && docSnap.templates) {
        docSnap.templates.forEach((t: any) => {
            newTemplatesMap.set(t.folder, {
                folder: t.folder,
                folderName: t.folderName,
                width: t.width,
                height: t.height,
                children: t.children,
                serviceData: t.serviceData,
                parents: t.parents,
                arrowsData: t.arrowsData
            })
        })
    }
    newTemplates.forEach((t) => {
        const { parents, serviceData } = formatServiceDataMap(serviceDataMap)
        const newArrowsData = formatArrowsData(arrowData)

        const availableFolderName = getAvailableName(t.folderName, docSnap ? docSnap.templates.filter((dbTemplate: any) => {
            return dbTemplate.folder !== t.folder
        }).map((dbTemplate: any) => {
            return dbTemplate.folderName as string
        }) : [])

        newTemplatesMap.set(t.folder, {
            folder: t.folder,
            folderName: availableFolderName,
            width: t.width,
            height: t.height,
            children: t.children,
            serviceData: serviceData,
            parents: parents,
            arrowsData: newArrowsData
        })
    })
    await setDoc(documentRef, {
        templates: JSON.parse(JSON.stringify(Array.from(newTemplatesMap.values())))
    }, { merge: true })
}

export const updateGeneralCodeState = async (newGeneralCodeState: { [filePath: string]: CodeComponent[] }, app: FirebaseApp) => {
    const auth = getAuth(app)
    const fs = getFirestore(app)
    const documentRef = doc(fs, "state", auth.currentUser?.uid!)


    await setDoc(documentRef, {
        generalCodeState: deleteField()
    }, { merge: true })
    await setDoc(documentRef, {
        generalCodeState: JSON.parse(JSON.stringify(newGeneralCodeState))
    }, { merge: true })

}

export const updateTerraformTemplate = async (newTerraformTemplate: any, app: FirebaseApp) => {
    const auth = getAuth(app)
    const fs = getFirestore(app)
    const documentRef = doc(fs, "state", auth.currentUser?.uid!)
    await setDoc(documentRef, {
        terraformTemplate: deleteField()
    }, { merge: true })
    await setDoc(documentRef, {
        terraformTemplate: JSON.parse(JSON.stringify(newTerraformTemplate))
    }, { merge: true })
}

export const updateFileContents = async (fileContents: { [filePath: string]: string }, app: FirebaseApp) => {
    const auth = getAuth(app)
    const fs = getFirestore(app)
    const documentRef = doc(fs, "state", auth.currentUser?.uid!)

    await setDoc(documentRef, {
        fileContents: deleteField()
    }, { merge: true })
    await setDoc(documentRef, {
        fileContents: JSON.parse(JSON.stringify(fileContents))
    }, { merge: true })

}

export const updateServiceDataMap = async (dataMap: Map<string, MappedService>, app: FirebaseApp) => {
    const auth = getAuth(app)
    const fs = getFirestore(app)
    const documentRef = doc(fs, "state", auth.currentUser?.uid!)
    const { parents, serviceData } = formatServiceDataMap(dataMap)

    await setDoc(documentRef, {
        serviceDataMap: serviceData,
        parents: parents
    }, { merge: true })
}