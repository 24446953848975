export const validateSQSQueueName = (queueName: string) => {
    let messages = [];
    let isValid = true;

    // Checking length of queue name
    if (queueName.length < 1 || queueName.length > 80) {
        messages.push("SQS Queue names must be between 1 and 80 characters long.");
        isValid = false;
    }

    // Checking if the queue name consists of alphanumeric characters, hyphens (-), and underscores (_)
    if (!/^[a-zA-Z0-9_-]+$/.test(queueName)) {
        messages.push("SQS Queue names can only consist of alphanumeric characters, hyphens (-), and underscores (_).");
        isValid = false;
    }

    return {
        isValid,
        messages
    };
}