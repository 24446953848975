import { FirebaseApp } from "firebase/app"
import CodeEditor from "./CodeEditor"
import React, { LegacyRef, useEffect, useRef, useState } from "react"
import { getServiceDataMap, updateArrowsData, updateFileContents, updateGeneralCodeState, updateServiceDataMap, updateTerraformTemplate } from "../../utils/firestoreOps"
import { ArrowData } from "../../services/Service"
import { MappedService } from "../../editor-handler/EditorHandler"
import { Outlet } from "react-router-dom"
import { CodeComponent } from "../codeWriteUtils"
import { UncontrolledTreeEnvironment, Tree, StaticTreeDataProvider, TreeItem, TreeRef } from 'react-complex-tree';
import { Box, Button, Drawer, IconButton, TextField, Tooltip } from "@mui/material"
import 'react-complex-tree/lib/style-modern.css';
import "@blueprintjs/core/lib/css/blueprint.css";
import { renderers as bpRenderers } from "react-complex-tree-blueprintjs-renderers";
import { } from "@blueprintjs/icons";
import { readTemplate, terraformTemplate } from "./FileTree"
import ConfigDeployer from "../../config-deployer/ConfigDeployer"
import CustomDataProvider from "./CustomDataProvider"
import { mainContent } from "./mainContent"
import Convert from 'ansi-to-html';
import CloseIcon from '@mui/icons-material/Close';
import CustomButton from "../../custom-components/CustomButton"
import EditIcon from '@mui/icons-material/Edit';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { rename } from "fs"
import CodeDownloader from "../../code-downloader/CodeDownloader"



interface CodeManagerProps {
    app: FirebaseApp
    setCurrentView: (view: "EDITOR" | "CODE" | "SETTINGS") => void
    triggerNewDeployment: number
    setTriggerNewDeployment: (value: number) => void
    isValid: boolean
}

const CodeManager = ({ app, setCurrentView, triggerNewDeployment, setTriggerNewDeployment, isValid }: CodeManagerProps) => {
    const [serviceDataMap, setServiceDataMap] = useState<Map<string, MappedService>>(new Map<string, MappedService>)
    const [generalCodeState, setGeneralCodeState] = useState<{ [filePath: string]: CodeComponent[] }>({})
    const [treeItems, setTreeItems] = useState(readTemplate().items)
    const tree = useRef<TreeRef>(null);
    const [treeItemsNames, setTreeItemsNames] = useState<{ [index: string]: string }>({})
    /*const [terraformTemplate, setTerraformTemplate] = useState({
        root: {
            "modules": {
                "inkdrop": {
                    "main.tf": null
                },
            },
        }})*/
    const [focusedFile, setFocusedFile] = useState("/root/main.tf")
    const [disableButtons, setDisableButtons] = useState(false)
    const [outputText, setOutputText] = useState("")
    const [fileContents, setFileContents] = useState<{ [filePath: string]: string }>({})
    const serviceDataMapRef = useRef(serviceDataMap)
    const [arrowsData, setArrowsData] = useState<ArrowData[]>([])
    const [isDataInitialized, setIsDataInitialized] = useState(false)
    const [isOutputOpen, setIsOutputOpen] = useState(false)
    const outputDivRef = useRef<HTMLDivElement>(null)

    const convert = new Convert();


    const dataProvider = new CustomDataProvider(treeItems, (item, data) => ({
        ...item,
        data,
    }))

    const sidebarWidth = "20"

    const setFiles = (newContents: {
        path: string,
        content: string
    }[]) => {
        const newFileContents = { ...fileContents }
        newContents.forEach((n) => {
            newFileContents[n.path] = n.content
        })
        setFileContents(newFileContents)
        setFsFileContents(newFileContents)
    }

    useEffect(() => {
        if (!isDataInitialized) return;
        if (!fileContents["/root/main.tf"]) {
            setFiles([{ path: "/root/main.tf", content: mainContent }])
        }
        Object.keys(fileContents).forEach((key) => {
            if (!readTemplate().items[key]) {
                addItem(key.split("/").slice(0, -1).join("/"), false, key.split("/")[key.split("/").length - 1])
            }
        })
    }, [fileContents, isDataInitialized])

    useEffect(() => {
        if (outputDivRef.current) {
            outputDivRef.current.scrollTop = outputDivRef.current.scrollHeight
        }
    }, [outputText])


    useEffect(() => {
        const getExistingServiceDataMap = async () => {
            const sdm = await getServiceDataMap(app)
            if (sdm) {
                setServiceDataMap(sdm.serviceDataMap)
                setFileContents(sdm.fileContents)
                if (sdm.terraformTemplate.root) {
                    terraformTemplate.root = sdm.terraformTemplate.root
                    await refreshTreeChildren()
                }
                setArrowsData(sdm.arrowsData)
                setGeneralCodeState(sdm.generalCodeState)
                serviceDataMapRef.current = sdm.serviceDataMap
            }
            setIsDataInitialized(true)
        }
        getExistingServiceDataMap()
    }, [])

    const setCodeState = (key: string, newCodeState: CodeComponent[]) => {
        const sdm = serviceDataMapRef.current.get(key)
        sdm!.codeState! = JSON.parse(JSON.stringify(newCodeState))
        refreshServiceDataMap()
    }

    const refreshServiceDataMap = () => {
        setServiceDataMap((prev: Map<string, MappedService>) => {
            setFsServiceDataMap(prev)
            serviceDataMapRef.current = new Map(prev);
            return serviceDataMapRef.current
        })
    }

    const setFsGeneralCodeState = async (generalCodeState: { [filePath: string]: CodeComponent[] }) => {
        if (!isDataInitialized) return;
        await updateGeneralCodeState(generalCodeState, app)
        setGeneralCodeState(generalCodeState)
    }

    const setFsFileContents = async (fileContents: { [filePath: string]: string }) => {
        if (!isDataInitialized) return;
        await updateFileContents(fileContents, app)
    }

    const setFsServiceDataMap = async (dataMap: Map<string, MappedService>) => {
        if (!isDataInitialized) return;
        await updateServiceDataMap(dataMap, app)
    }

    const setFsTerraformTemplate = async () => {
        if (!isDataInitialized) return;
        await updateTerraformTemplate(terraformTemplate, app)
    }

    const addFileToRoot = (name: string) => {
        terraformTemplate.root[name] = null
        const newItems = readTemplate().items
        setTreeItems(newItems)
        dataProvider.onChangeItemChildren("/root", newItems["/root"].children)
    }

    const refreshTreeChildren = async () => {
        const newItems = readTemplate().items
        setTreeItems(newItems)
        const promises = Object.entries(newItems).map(async ([index, item]: [any, any]) => {
            if (item && item.children && item.children.length > 0) {
                return dataProvider.onChangeItemChildren(index, item.children)
            }
            return Promise.resolve() // return a default Promise in case there is no children.
        })

        await Promise.all(promises)
    }

    const addItem = async (index: string, isFolder: boolean, name?: string, e?: React.MouseEvent) => {
        if (e)
            e.stopPropagation();
        const pathArray = (index as string).split("/")
        //Update the terraformTemplate adding "newFolder" to the path
        let currentItem = terraformTemplate.root
        if (pathArray.length)
            for (let i = 2; i < pathArray.length; i++) {
                currentItem = currentItem[pathArray[i]]
            }
        let newName = name || (isFolder ? "new_folder" : "new_file")
        //if the name already exists, add a number to the end
        let counter = 1
        while (currentItem[newName]) {
            newName = isFolder ? "new_folder_" + counter : "new_file_" + counter
            counter++
        }
        currentItem[newName] = isFolder ? {} : null
        await refreshTreeChildren()
        tree.current!.expandItem(index)
        if (!name)
            setTimeout(() => { tree.current!.startRenamingItem(index + "/" + newName) }, 100)
        setFsTerraformTemplate()
    };

    const deleteItem = async (index: string, e?: React.MouseEvent) => {
        if (e)
            e.stopPropagation();
        const pathArray = index.split("/")
        //Update the terraformTemplate adding "newFolder" to the path
        let currentItem = terraformTemplate.root
        for (let i = 2; i < pathArray.length - 1; i++) {
            currentItem = currentItem[pathArray[i]]
        }
        delete currentItem[pathArray[pathArray.length - 1]]
        await refreshTreeChildren()
        setFsTerraformTemplate()
    }

    const renameItem = async (index: string, name: string) => {
        // TODO: if the name contains a "/", don't rename

        if (name === index.split("/")[index.split("/").length - 1]) return;
        const newGeneralCodeState = { ...generalCodeState }
        newGeneralCodeState[index.split("/").slice(0, -1).join("/") + "/" + name] = newGeneralCodeState[index]
        delete newGeneralCodeState[index]
        setGeneralCodeState(newGeneralCodeState)
        setFsGeneralCodeState(newGeneralCodeState)

        const newFileContents = { ...fileContents }
        newFileContents[index.split("/").slice(0, -1).join("/") + "/" + name] = newFileContents[index]
        delete newFileContents[index]
        setFileContents(newFileContents)
        setFsFileContents(newFileContents)

        const pathArray = index.split("/")
        //Update the terraformTemplate adding "newFolder" to the path
        let currentItem = terraformTemplate.root
        for (let i = 2; i < pathArray.length - 1; i++) {
            currentItem = currentItem[pathArray[i]]
        }
        currentItem[name] = currentItem[pathArray[pathArray.length - 1]]
        delete currentItem[pathArray[pathArray.length - 1]]
        await refreshTreeChildren()
        setFsTerraformTemplate()
    }


    return (<>
        <div className='fixed inset-0 flex flex-col h-screen bg-[#181818]'>
            <div className="flex items-center justify-start w-2/3 top-0 h-[50px] bg-[#181818] border-b-[#2B2B2B] border-b-[1px] ml-80">
                <div className="ml-3">
                    <CustomButton name="Back to editor" onClick={() => setCurrentView("EDITOR")} icon={<EditIcon />} darkMode />
                </div>
                <div className="ml-3">
                    <ConfigDeployer destroy={false} isDataInitialized={isDataInitialized} triggerNewDeployment={triggerNewDeployment} setTriggerNewDeployment={setTriggerNewDeployment} setIsOutputOpen={setIsOutputOpen}
                        setOutputText={setOutputText} fileContents={fileContents} app={app} serviceDataMapRef={serviceDataMapRef}
                        generalCodeState={generalCodeState} setCodeState={setCodeState} addFileToRoot={addFileToRoot} setFiles={setFiles} isValid={isValid} disableButtons={disableButtons} setDisableButtons={setDisableButtons} />
                </div>
                <div className="ml-3">
                    <ConfigDeployer destroy={true} isDataInitialized={isDataInitialized} triggerNewDeployment={triggerNewDeployment} setTriggerNewDeployment={setTriggerNewDeployment} setIsOutputOpen={setIsOutputOpen}
                        setOutputText={setOutputText} fileContents={fileContents} app={app} serviceDataMapRef={serviceDataMapRef}
                        generalCodeState={generalCodeState} setCodeState={setCodeState} addFileToRoot={addFileToRoot} setFiles={setFiles} isValid={isValid} disableButtons={disableButtons} setDisableButtons={setDisableButtons} />
                </div>
            </div>
            <div className="absolute right-3 items-center h-[50px]">
                <div className=" mt-6 translate-y-[-50%]">
                    <CodeDownloader fileContents={fileContents} serviceDataMapRef={serviceDataMapRef} setCodeState={setCodeState} generalCodeState={generalCodeState} />
                </div>
            </div>
            <div className={'flex-grow overflow-auto transition-all'}
                style={true ? {
                    marginLeft: sidebarWidth + "rem"
                } : {}}>
                <CodeEditor setFiles={setFiles} fileContents={fileContents} focusedFile={focusedFile} isDataInitialized={isDataInitialized} generalCodeState={generalCodeState} setFsGeneralCodeState={setFsGeneralCodeState} serviceDataMapRef={serviceDataMapRef} refreshServiceDataMap={refreshServiceDataMap} setCodeState={setCodeState} />
            </div>
            <div
                className="bg-[#181818] border-t-[#2B2B2B] border-t-[1px]"

                style={{
                    position: "sticky",
                    bottom: 0,
                    height: isOutputOpen ? "40%" : "50px",
                    marginLeft: sidebarWidth + "rem",
                    color: "#CCCCCC",
                }}>
                <div className="fixed w-full bg-[#181818] pt-3 pb-3 pl-6">
                    <Button
                        onClick={() => setIsOutputOpen(true)}
                        sx={{
                            padding: 0,
                            fontSize: "12px",
                            fontWeight: 600,
                            color: isOutputOpen ? "#CCCCCC" : "#9D9D9D",
                            textDecoration: isOutputOpen ? "underline" : "none"
                        }}>
                        OUTPUT
                    </Button>
                </div>
                {isOutputOpen &&
                    <>
                        <IconButton
                            onClick={() => setIsOutputOpen(false)}

                            size="small"
                            sx={{
                                marginTop: "0.25rem",
                                padding: "0.5rem",
                                position: "fixed",
                                right: 0,
                            }}>
                            <CloseIcon sx={{ color: "#CCCCCC" }} />
                        </IconButton>
                        <div
                            ref={outputDivRef}
                            className="h-full overflow-scroll pl-6 pr-6 pt-9 pb-6"
                            style={true ? {
                                fontFamily: '"Cascadia Code", sans-serif',
                                fontSize: "13px",
                                fontWeight: 300
                            } : {}}
                            dangerouslySetInnerHTML={{
                                __html: convert.toHtml(
                                    outputText.replace(/\n/g, "<br/>")
                                )
                            }} />
                    </>
                }
            </div>
            <Drawer
                anchor={"left"}
                variant="persistent"
                open={true}
                PaperProps={{
                    sx: {
                        borderRight: "1px solid #2B2B2B",
                        display: "flex"
                    }
                }}
            >
                <div className="sticky top-0 h-[50px] bg-[#181818] border-b-[#2B2B2B] border-b-[1px] pt-4 pb-3 pl-6 font-semibold text-[#CCCCCC] text-xs">
                    EXPLORER
                </div>
                <div
                    style={{
                        overflow: "hidden",
                        flexGrow: 1,
                        width: sidebarWidth + "rem",
                        backgroundColor: "#181818",
                        color: "#CCCCCC",
                        fontSize: "13px",
                    }}>
                    <UncontrolledTreeEnvironment
                        onFocusItem={(item) => { if (!item.isFolder) setFocusedFile(item.index.toString()) }}
                        dataProvider={
                            dataProvider
                        }

                        getItemTitle={item => item.data}
                        viewState={{
                            "tree-1": {
                                selectedItems: ["/root/main.tf"]
                            }
                        }}
                        onRenameItem={(item, name) => { renameItem(item.index as string, name) }}
                        keyboardBindings={{
                            renameItem: ["ENTER"]
                        }}
                        {...bpRenderers}
                        renderItemTitle={({ title, item, context, info }) => {
                            return <div key={item.index} className="w-full flex items-center">
                                <span className=" grow">{title}</span>
                                {item.children &&
                                    <div>
                                        <Tooltip placement="top" title="Create folder" arrow>
                                            <IconButton style={{
                                            }} onClick={(e) => addItem(item.index as string, true, undefined, e)}><CreateNewFolderOutlinedIcon sx={
                                                {
                                                    color: "white",
                                                    fontSize: "16px"
                                                }
                                            } /></IconButton>
                                        </Tooltip>
                                        <Tooltip placement="top" title="Create file" arrow>
                                            <IconButton style={{

                                            }} onClick={(e) => addItem(item.index as string, false, undefined, e)}><NoteAddOutlinedIcon sx={
                                                {
                                                    color: "white",
                                                    fontSize: "16px"
                                                }
                                            } /></IconButton>
                                        </Tooltip>
                                    </div>
                                }
                                {(!item.isFolder || (item.children && item.children.length === 0)) &&
                                    <Tooltip placement="top" title="Delete" arrow>
                                        <IconButton style={{

                                        }} onClick={(e) => deleteItem(item.index as string, e)}><DeleteIcon sx={
                                            {
                                                color: "white",
                                                fontSize: "16px"
                                            }
                                        } /></IconButton>
                                    </Tooltip>
                                }

                            </div>
                        }}

                    >
                        <Tree ref={tree} treeId="tree-1" rootItem="/root" treeLabel="Tree Example" />
                    </UncontrolledTreeEnvironment>
                </div>
            </Drawer >
        </div >
        <Outlet />
    </>)
}

export default CodeManager;
