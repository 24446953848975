export const validateVPCOutboundTraffic = (publicSubnetsNumber: string, outboundTrafficValue: string) => {
    let messages = [];
    let isValid = true;

    // Checking if the number of public subnets is greater than 0
    if (publicSubnetsNumber === "None" && outboundTrafficValue !== "Disabled") {
        messages.push("You can't enable outbound traffic if you don't have any public subnets.");
        isValid = false;
    }

    return {
        isValid,
        messages
    };
}