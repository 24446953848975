import { Editor } from "@monaco-editor/react"
import { Outlet } from "react-router-dom"
import { MappedService } from "../../editor-handler/EditorHandler"
import { useEffect, useState } from "react"
import { CodeComponent, createCodeText, getArguments, getChangedProperties, getFileContent, getInnerDependsOnProperties } from "../codeWriteUtils"
import { getServiceTranslation } from "../../services/Service"
import { KeyCode, KeyMod, editor } from "monaco-editor"
import { checkArgumentsChange, parseHCL } from "../codeParseUtils"
import { mainContent } from "./mainContent"

interface CodeEditorProps {
    serviceDataMapRef: React.MutableRefObject<Map<string, MappedService>>,
    refreshServiceDataMap: () => void
    setFiles: (newContents: {
        path: string,
        content: string
    }[]) => void
    focusedFile: string
    fileContents: { [filePath: string]: string }
    generalCodeState: { [filePath: string]: CodeComponent[] }
    setFsGeneralCodeState: (generalCodeState: { [filePath: string]: CodeComponent[] }) => void
    isDataInitialized: boolean
    setCodeState: (key: string, newCodeState: CodeComponent[]) => void
}

const CodeEditor = ({
    setFiles, fileContents, focusedFile, generalCodeState, serviceDataMapRef, refreshServiceDataMap, setFsGeneralCodeState, isDataInitialized, setCodeState
}: CodeEditorProps) => {

    const [firstRender, setFirstRender] = useState<Map<string, boolean>>(new Map<string, boolean>);
    const [completeState, setCompleteState] = useState<Map<string, CodeComponent[]>>(new Map<string, CodeComponent[]>)
    const [stopPropagatingChanges, setStopPropagatingChanges] = useState(false)
    const [codeText, setCodeText] = useState("")
    const [isEditorFocused, setIsEditorFocused] = useState(false)
    const [editor, setEditor] = useState<editor.IStandaloneCodeEditor>()
    const [editorValue, setEditorValue] = useState("")

    useEffect(() => {
        if (!editor) return;
        editor.onDidFocusEditorWidget(() => {
            setIsEditorFocused(true)
        })
        editor.onDidBlurEditorWidget(() => {
            setIsEditorFocused(false)
        })
    }, [editor])

    const handleEditorMounted = (e: editor.IStandaloneCodeEditor) => {
        setEditor(e)
        e.addCommand(KeyMod.CtrlCmd | KeyCode.KeyZ, function () { });
    }

    const serviceDataArray = Array.from(serviceDataMapRef.current.entries()).map(([key, service]) => {
        return service.serviceData
    })

    const getExtendedCodeState = () => {
        const extendedCodeState: (CodeComponent & { key: string })[] = []
        Array.from(serviceDataMapRef.current.entries()).filter(([key, service]) => {
            return service.serviceData.filePath === focusedFile
        }).forEach(([key, service]) => {
            extendedCodeState.push(...service.codeState.map((c) => {
                return {
                    ...c,
                    key: key
                }
            }))
        })
        return extendedCodeState
    }

    const getExtendedCompleteState = () => {
        const extendedCompleteState: CodeComponent[] = []
        Array.from(completeState.entries()).map(([key, component]) => {
            extendedCompleteState.push(...component)
        })
        return extendedCompleteState
    }

    useEffect(() => {
        if (focusedFile !== "/root/modules/inkdrop/main.tf") {
            setFiles([{ path: focusedFile, content: editorValue }])
        } else {
            const codeComponents = parseHCL(editorValue);
            //if (codeComponents.length === 0) return;
            const extendedCodeState = getExtendedCodeState()
            const extendedCompleteState = getExtendedCompleteState()
            const codeComponentsWithKey: (CodeComponent & { key: string })[] = []

            const alreadyUsedOldComponents: (CodeComponent & { key: string })[] = []
            const copiedComponents: (CodeComponent & { key: string })[] = []

            codeComponents.forEach((newCodeComponent) => {
                const oldComponentsArray = extendedCodeState.filter((cs) => {
                    return cs.component === newCodeComponent.component &&
                        cs.type === newCodeComponent.type &&
                        cs.componentName === newCodeComponent.componentName
                })

                let oldComponent: CodeComponent & { key: string } | undefined = undefined
                let copiedComponent: CodeComponent & { key: string } | undefined = undefined

                let found = false

                oldComponentsArray.forEach((o, index) => {
                    if (!found) {
                        if (!alreadyUsedOldComponents.some(c => {
                            return c.component === o.component &&
                                c.type === o.type &&
                                c.componentName === o.componentName &&
                                c.key === o.key
                        })) {
                            alreadyUsedOldComponents.push(o)
                            oldComponent = o
                            found = true
                        } else {
                            const currentLength = alreadyUsedOldComponents.length + copiedComponents.length
                            if (currentLength < oldComponentsArray.length && index >= currentLength) {
                                copiedComponents.push(o)
                                copiedComponent = o
                                found = true
                            }
                        }
                    }
                })


                const completeComponent = extendedCompleteState.filter((cs) => {
                    return cs.component === newCodeComponent.component &&
                        cs.type === newCodeComponent.type &&
                        cs.componentName === newCodeComponent.componentName
                })
                const componentState = oldComponent || copiedComponent || { ...newCodeComponent, key: "" }
                if (!componentState.state) {
                    componentState.state = completeComponent.length > 0 && oldComponent ? "UNEDITED" : "EDITED"
                }

                newCodeComponent.state = componentState.state === "HIDDEN" ? "UNEDITED" : componentState.state
                newCodeComponent.dependsOnProperties = componentState.dependsOnProperties
                newCodeComponent.state = checkArgumentsChange(newCodeComponent, componentState.arguments, oldComponent && completeComponent.length > 0 ? completeComponent[0].arguments : [], isEditorFocused)
                codeComponentsWithKey.push({ ...newCodeComponent, key: componentState.key })
            })
            extendedCodeState.forEach((o) => {
                if (!codeComponentsWithKey.some((a) => {
                    return a.component === o.component &&
                        a.type === o.type &&
                        a.componentName === o.componentName
                })) {
                    o.state = o.state === "HIDDEN" ? o.state : "DELETED"
                    codeComponentsWithKey.push(o)
                }
            })

            setFsGeneralCodeState(
                {
                    ...generalCodeState,
                    [focusedFile]: codeComponentsWithKey.filter((c) => {
                        return c.key === ""
                    }).map((c) => {
                        return {
                            arguments: c.arguments,
                            component: c.component,
                            componentName: c.componentName,
                            state: c.state,
                            conditionVerified: c.conditionVerified,
                            dependsOnProperties: c.dependsOnProperties,
                            type: c.type,
                            filePath: focusedFile
                        }
                    })
                })

            serviceDataMapRef.current.forEach((value, key) => {
                value.codeState = codeComponentsWithKey.filter((c) => {
                    return c.key === key
                }).map((c) => {
                    return {
                        arguments: c.arguments,
                        component: c.component,
                        componentName: c.componentName,
                        state: c.state,
                        conditionVerified: c.conditionVerified,
                        dependsOnProperties: c.dependsOnProperties,
                        type: c.type
                    }
                })
            })
            refreshServiceDataMap()
        }
    }, [editorValue])

    useEffect(() => {

        if (!isDataInitialized || !focusedFile) return

        const { newCodeText, newCompleteState } = getFileContent(serviceDataMapRef, focusedFile, setCodeState, generalCodeState[focusedFile])
        if (focusedFile !== "/root/modules/inkdrop/main.tf") {
            setEditorValue(fileContents[focusedFile] || "")
        } else if (newCodeText.join("\n\n") !== editorValue) {
            setEditorValue(newCodeText.join("\n\n"))
        }

        setCompleteState(newCompleteState)

    }, [isDataInitialized,
        focusedFile,
        fileContents,
        JSON.stringify(serviceDataArray.map(a => {
            return a.properties
        })), JSON.stringify(serviceDataArray.map(a => {
            return a.parents.map((p) => {
                return {
                    ...p,
                    serviceData: {
                        ...p.serviceData,
                        arrowIn: [],
                        arrowOut: []
                    }
                }
            })
        }))])

    return (<>
        <Editor
            theme="vs-dark"
            options={{
                autoIndent: "full",
                formatOnPaste: true,
                formatOnType: true,
            }}
            onMount={(e) => handleEditorMounted(e)}
            defaultLanguage="hcl" value={editorValue} onChange={(v) => { setEditorValue(v!) }} />
    </>)
}
export default CodeEditor