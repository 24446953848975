import { Component, Service, ServiceData, ServiceTranslation, addQuotes, getObjectFormat, getPropertyFromArrowIn, getPropertyValue } from "../../Service";
import { validateEmail, validateService } from "../validators/generalValidator";
import { validateSNSTopicName } from "../validators/snsValidator";

const defaultName = "sns-topic"

export const sns: Service = {
    serviceName: "SNS Topic",
    defaultName: defaultName,
    imgUrl: "img/SNS.png",
    borderColor: "#D82F6C",
    category: "Application Integration",
    filePath: "/root/modules/inkdrop/main.tf",
    validate: (serviceData: ServiceData) => validateService(serviceData),
    backgroundColor: "#f7d5e1",
    isWrapper: false,
    arrowInTypes: ["SNS Notification", "Interact", "API Integration"],
    arrowOutTypes: ["Invoke Function", "SQS Message", "Policy Restriction"],
    parentsTypes: [],
    properties: [
        {
            name: "Topic type",
            condition: () => { return true },
            docsUrl: "",
            tfDocsUrl: "",
            defaultValue: "Standard",
            choices: ["Standard", "FIFO"],
            type: "string",
            mandatory: true
        },
        {
            name: "Email notification",
            condition: () => { return true },
            docsUrl: "",
            tfDocsUrl: "",
            defaultValue: "Disabled",
            choices: ["Enabled", "Disabled"],
            type: "string",
            mandatory: true
        },
        {
            name: "Email",
            condition: (serviceData: ServiceData) => { return getPropertyValue(serviceData, "Email notification") === "Enabled" },
            validate: (serviceData: ServiceData) => validateEmail(serviceData.properties.filter((p) => {
                return p.name === "Email"
            })[0].value as string),
            docsUrl: "",
            tfDocsUrl: "",
            defaultValue: "email@example.com",
            type: "string",
            mandatory: true
        },
        {
            name: "Name",
            condition: () => { return true },
            validate: (serviceData: ServiceData) => validateSNSTopicName(serviceData.properties.filter((p) => {
                return p.name === "Name"
            })[0].value as string),
            docsUrl: "",
            tfDocsUrl: "",
            isName: true,
            defaultValue: defaultName,
            type: "string",
            mandatory: true,
        },
    ],
}
export const snsServiceTranslation = (serviceData: ServiceData): ServiceTranslation => {

    const topicArgs = [
        {
            name: "name",
            condition: true,
            value: getPropertyValue(serviceData, "Topic type") === "FIFO" ? addQuotes(getPropertyValue(serviceData, "Name") + ".fifo") : addQuotes(getPropertyValue(serviceData, "Name")),
            dependsOnProperties: ["Name", "Topic type"],
        },
        {
            name: "fifo_queue",
            condition: getPropertyValue(serviceData, "Topic type") === "FIFO",
            value: "true",
            dependsOnProperties: ["Topic type"],
        },
        {
            name: "content_based_deduplication",
            condition: getPropertyValue(serviceData, "Topic type") === "FIFO",
            value: "false",
            dependsOnProperties: ["Topic type"],
        },
    ]

    const components: Component[] = [{
        component: "resource",
        type: "aws_sns_topic",
        componentName: getPropertyValue(serviceData, "Name"),
        dependsOnProperties: ["Name"],
        condition: true,
        arguments: topicArgs
    },
    {
        component: "resource",
        type: "aws_sns_topic_subscription",
        componentName: "email_sub_" + getPropertyValue(serviceData, "Name"),
        dependsOnProperties: ["Name", "Email notification"],
        condition: getPropertyValue(serviceData, "Email notification") === "Enabled",
        arguments: [
            {
                name: "topic_arn",
                condition: true,
                value: "aws_sns_topic." + getPropertyValue(serviceData, "Name") + ".arn",
                dependsOnProperties: ["Name"],
            },
            {
                name: "protocol",
                condition: true,
                value: addQuotes("email")
            },
            {
                name: "endpoint",
                condition: true,
                value: addQuotes(getPropertyValue(serviceData, "Email")),
                dependsOnProperties: ["Email"],
            },
        ]
    },
    ...(serviceData.arrowOut.filter((arrow) => {
        return arrow.arrowData.type === "SQS Message"
    }).map((arrow, index) => {
        return {
            component: "resource",
            type: "aws_sns_topic_subscription",
            componentName: "sqs_sub_" + getPropertyValue(serviceData, "Name") + "_" + index,
            dependsOnProperties: ["Name", "serviceOut." + arrow.arrowData.endId + ".Name"],
            condition: true,
            arguments: [
                {
                    name: "topic_arn",
                    condition: true,
                    value: "aws_sns_topic." + getPropertyValue(serviceData, "Name") + ".arn",
                    dependsOnProperties: ["Name"],
                },
                {
                    name: "protocol",
                    condition: true,
                    value: addQuotes("sqs")
                },
                {
                    name: "endpoint",
                    condition: true,
                    value: "aws_sqs_queue." + getPropertyValue(arrow.serviceData, "Name") + ".arn",
                    dependsOnProperties: ["arrowOut." + arrow.arrowData.endId + ".Name"],
                },
            ]
        }
    }) as Component[]),
    ...(serviceData.arrowOut.filter((arrow) => {
        return arrow.arrowData.type === "Invoke Function"
    }).map((arrow, index) => {
        return {
            component: "resource",
            type: "aws_sns_topic_subscription",
            componentName: "lambda_sub_" + getPropertyValue(serviceData, "Name") + "_" + index,
            dependsOnProperties: ["Name", "serviceOut." + arrow.arrowData.endId + ".Name"],
            condition: true,
            arguments: [
                {
                    name: "topic_arn",
                    condition: true,
                    value: "aws_sns_topic." + getPropertyValue(serviceData, "Name") + ".arn",
                    dependsOnProperties: ["Name"],
                },
                {
                    name: "protocol",
                    condition: true,
                    value: addQuotes("lambda")
                },
                {
                    name: "endpoint",
                    condition: true,
                    value: "aws_lambda_function." + getPropertyValue(arrow.serviceData, "Name") + ".arn",
                    dependsOnProperties: ["arrowOut." + arrow.arrowData.endId + ".Name"],
                },
            ]
        }
    }) as Component[])
    ]

    if (serviceData.arrowIn.some((a) => {
        return a.arrowData.type === "SNS Notification"
    })) {
        components.push({
            component: "data",
            type: "aws_iam_policy_document",
            componentName: "send_notification_doc_" + getPropertyValue(serviceData, "Name"),
            dependsOnProperties: [...serviceData.arrowIn.filter((a) => {
                return a.arrowData.type === "SNS Notification"
            }).map((a) => {
                return "serviceIn." + a.arrowData.startId + ".Name"
            }), "Name"],
            condition: true,
            arguments: [
                {
                    name: "statement",
                    condition: true,
                    value: [
                        {
                            name: "effect",
                            condition: true,
                            value: addQuotes("Allow")
                        },
                        {
                            name: "principals",
                            condition: true,
                            value: [
                                {
                                    name: "type",
                                    condition: true,
                                    value: addQuotes("AWS")
                                },
                                {
                                    name: "identifiers",
                                    condition: true,
                                    value: "[\"*\"]"
                                },
                            ]
                        },
                        {
                            name: "actions",
                            condition: true,
                            value: "[\"SNS:Publish\"]"
                        },
                        {
                            name: "resources",
                            condition: true,
                            value: "[\"arn:aws:sns:*:*:" + getPropertyValue(serviceData, "Name") + "\"]",
                            dependsOnProperties: ["Name"]
                        },
                    ]
                }
            ]
        },
            {
                component: "resource",
                type: "aws_sns_topic_policy",
                componentName: "send_notification_policy_" + getPropertyValue(serviceData, "Name"),
                dependsOnProperties: [...serviceData.arrowIn.filter((a) => {
                    return a.arrowData.type === "SNS Notification"
                }).map((a) => {
                    return "serviceIn." + a.arrowData.startId + ".Name"
                }), "Name"],
                condition: true,
                arguments: [
                    {
                        name: "arn",
                        condition: true,
                        value: "aws_sns_topic." + getPropertyValue(serviceData, "Name") + ".arn",
                        dependsOnProperties: ["Name"]
                    },
                    {
                        name: "policy",
                        condition: true,
                        value: "data.aws_iam_policy_document.send_notification_doc_" + getPropertyValue(serviceData, "Name") + ".json",
                        dependsOnProperties: ["Name"]
                    },
                ]
            }
        )
    }

    return {
        components: components
    }
}
export { }