import { Component, Service, ServiceData, ServiceTranslation, addQuotes, addSquareBrackets, getObjectFormat, getPropertyFromArrowIn, getPropertyValue, hasArrowTypeIn, removeQuotes, stringToObject, updatePropertyValue } from "../../Service";
import { validateService } from "../validators/generalValidator";
import { validateVPCOutboundTraffic } from "../validators/vpcValidator";

const defaultName = "ecs-cluster"

export const ecsCluster: Service = {
    serviceName: "ECS Cluster",
    defaultName: defaultName,
    borderColor: "#E47413",
    category: "Compute",
    backgroundColor: "#fae3ce",
    parentsTypes: [],
    validate: (serviceData: ServiceData) => validateService(serviceData),
    filePath: "/root/modules/inkdrop/main.tf",
    isWrapper: true,
    imgUrl: "img/ECS.png",
    arrowInTypes: [],
    arrowOutTypes: [],
    properties: [

        {
            name: "Name",
            condition: () => { return true },
            docsUrl: "",
            tfDocsUrl: "",
            isName: true,
            defaultValue: defaultName,
            type: "string",
            mandatory: true,
        },
    ],
}


export const ecsClusterServiceTranslation = (serviceData: ServiceData): ServiceTranslation => {
    return {
        components: [
            {
                component: "resource",
                type: "aws_ecs_cluster",
                componentName: getPropertyValue(serviceData, "Name"),
                condition: true,
                dependsOnProperties: ["Name"],
                arguments: [
                    {
                        name: "name",
                        condition: true,
                        value: addQuotes(getPropertyValue(serviceData, "Name")),
                        dependsOnProperties: ["Name"],
                    },
                    {
                        name: "setting",
                        condition: true,
                        value: [
                            {
                                name: "name",
                                value: addQuotes("containerInsights"),
                                condition: true,
                            },
                            {
                                name: "value",
                                value: addQuotes("enabled"),
                                condition: true,
                            }
                        ]
                    }
                ]
            },
        ]
    }
}