export const validateIAMRoleName = (roleName: string) => {
    let messages = [];
    let isValid = true;

    // Checking length of Role name
    if (roleName.length < 1 || roleName.length > 64) {
        messages.push("IAM Role names must be between 1 and 64 characters long.");
        isValid = false;
    }

    // Checking if the role name contains only valid characters
    if (!/^[\w+=,.@_-]+$/.test(roleName)) {
        messages.push("Role names can only consist of alphanumeric characters, plus (+), equal (=), comma (,), period (.), at (@), underscore (_), and hyphen (-).");
        isValid = false;
    }

    // Check if Role name starts or ends with - or _
    if (roleName.startsWith('-') || roleName.startsWith('_') || roleName.endsWith('-') || roleName.endsWith('_')) {
        messages.push("Role names must not begin or end with hyphen (-) or underscore (_).");
        isValid = false;
    }

    return {
        isValid,
        messages
    };
}