import { get } from "http";
import { Argument, Component, Service, ServiceData, ServiceTranslation, addQuotes, getPropertyFromArrowIn, getPropertyValue } from "../../Service";
import { validateService } from "../validators/generalValidator";
import { validateRDSInstanceIdentifier } from "../validators/rdsValidator";

const defaultName = "cognito-user-pool"

export const cognito: Service = {
    serviceName: "Cognito User Pool",
    defaultName: defaultName,
    imgUrl: "img/Cognito.png",
    category: "Security, Identity, & Compliance",
    filePath: "/root/modules/inkdrop/main.tf",
    validate: (serviceData: ServiceData) => validateService(serviceData),
    borderColor: "#DD3033",
    backgroundColor: "#fbeaea",
    isWrapper: false,
    arrowInTypes: [],
    arrowOutTypes: ["Invoke Function", "API Authentication"],
    parentsTypes: [],
    properties: [
        {
            name: "Sign-in method",
            condition: () => { return true },
            docsUrl: "",
            tfDocsUrl: "",
            defaultValue: "Username",
            type: "string",
            choices: ["Username", "Email", "Email and phone number"],
            mandatory: true,
        },
        {
            name: "Enforce MFA",
            condition: (serviceData: ServiceData) => { return getPropertyValue(serviceData, "Sign-in method") === "Email and phone number" },
            docsUrl: "",
            tfDocsUrl: "",
            defaultValue: "No",
            type: "string",
            choices: ["No", "Yes"],
            mandatory: true,
        },
        {
            name: "Name",
            condition: () => { return true },
            docsUrl: "",
            tfDocsUrl: "",
            isName: true,
            defaultValue: defaultName,
            type: "string",
            mandatory: true,
        },
    ],
}


const getNameFromArrowProperty = (property: string): string => {
    switch (property) {
        case "Post-authentication":
            return "post_authentication";
        case "Pre-authentication":
            return "pre_authentication";
        case "Post-confirmation":
            return "post_confirmation";
        case "Pre-signup":
            return "pre_sign_up";
        default:
            return "post_authentication";
    }
}


export const cognitoServiceTranslation = (serviceData: ServiceData): ServiceTranslation => {

    const cognitoArgs: Argument[] = [
        {
            name: "name",
            condition: true,
            value: addQuotes(getPropertyValue(serviceData, "Name")),
            dependsOnProperties: ["Name"]
        },
        {
            name: "password_policy",
            condition: true,
            value: [
                {
                    name: "minimum_length",
                    condition: true,
                    value: "8",
                },
                {
                    name: "require_lowercase",
                    condition: true,
                    value: "true",
                },
                {
                    name: "require_numbers",
                    condition: true,
                    value: "true",
                },
                {
                    name: "require_uppercase",
                    condition: true,
                    value: "true",
                },
                {
                    name: "require_symbols",
                    condition: true,
                    value: "false",
                },
            ]
        },

    ]

    const cognitoComponents: Component[] = [{
        component: "resource",
        type: "aws_cognito_user_pool",
        componentName: getPropertyValue(serviceData, "Name"),
        dependsOnProperties: ["Name"],
        condition: true,
        arguments: cognitoArgs
    },]


    if (getPropertyValue(serviceData, "Sign-in method") !== "Username") {
        cognitoArgs.push({
            name: "username_attributes",
            condition: true,
            value: getPropertyValue(serviceData, "Sign-in method") === "Email" ? "[\"email\"]" : "[\"email\", \"phone_number\"]",
            dependsOnProperties: ["Sign-in method"]
        })
        cognitoArgs.push({
            name: "dynamic \"schema\"",
            condition: true,
            value: [{
                name: "for_each",
                condition: true,
                dependsOnProperties: ["Sign-in method"],
                value: "[" + (getPropertyValue(serviceData, "Sign-in method") === "Email" ?
                    "\"email\"" : "\"email\", \"phone_number\"") + "]"
            },
            {
                name: "content",
                condition: true,
                value: [
                    {
                        name: "name",
                        condition: true,
                        value: "schema.value",
                    },
                    {
                        name: "attribute_data_type",
                        condition: true,
                        value: addQuotes("String"),
                    },
                    {
                        name: "required",
                        condition: true,
                        value: "true",
                    },

                ],
            },
            ]
        })
    }
    const recoveryMechanismArgs: Argument[] = []

    getPropertyValue(serviceData, "Sign-in method") !== "Username" ?
        recoveryMechanismArgs.push(
            {
                name: "name",
                condition: true,
                value: addQuotes("verified_email"),
            },
            {
                name: "priority",
                condition: true,
                value: "1",
            },
        ) : recoveryMechanismArgs.push(
            {
                name: "name",
                condition: true,
                value: addQuotes("admin_only"),
            },
            {
                name: "priority",
                condition: true,
                value: "1",
            },
        )
    const lambdaConfigArgs: Argument[] = []

    if (serviceData.arrowOut.some((a) => {
        return a.arrowData.type === "Invoke Function"
    })
    ) {
        serviceData.arrowOut.filter((a) => {
            return a.arrowData.type === "Invoke Function"
        }).forEach((a) => {
            lambdaConfigArgs.push({
                name: getNameFromArrowProperty(a.arrowData.properties.filter((p) => {
                    return p.name === "Trigger on"
                })[0].value as string),
                condition: true,
                value: "aws_lambda_function." + getPropertyValue(a.serviceData, "Name") + ".arn",
                dependsOnProperties: ["serviceOut." + a.arrowData.endId + ".Name", "arrowOut." + a.arrowData.shapeId + ".Trigger on"]
            })
        })
        cognitoArgs.push({
            name: "lambda_config",
            condition: true,
            value: lambdaConfigArgs
        })
    }
    cognitoArgs.push({
        name: "mfa_configuration",
        condition: getPropertyValue(serviceData, "Sign-in method") === "Email and phone number",
        value: getPropertyValue(serviceData, "Enforce MFA") === "Yes" ? addQuotes("ON") : addQuotes("OFF"),
        dependsOnProperties: ["Enforce MFA", "Sign-in method"]
    },
        {
            name: "sms_authentication_message",
            condition: true,
            value: addQuotes("Your authentication code is {####}"),
        },
        {
            name: "sms_verification_message",
            condition: true,
            value: addQuotes("Your verification code is {####}"),
        },
        {
            name: "email_verification_message",
            condition: true,
            value: addQuotes("Your verification code is {####}"),
        },
        {
            name: "email_verification_subject",
            condition: true,
            value: addQuotes("Your verification code"),
        },
        {
            name: "account_recovery_setting",
            condition: true,
            value: [
                {
                    name: "recovery_mechanism",
                    condition: true,
                    value: recoveryMechanismArgs
                },
            ],
        },
        {
            name: "sms_configuration",
            condition: getPropertyValue(serviceData, "Sign-in method") === "Email and phone number",
            dependsOnProperties: ["Sign-in method"],
            value: [
                {
                    name: "external_id",
                    condition: true,
                    value: addQuotes("role_" + getPropertyValue(serviceData, "Name")),
                    dependsOnProperties: ["Name"],
                },
                {
                    name: "sns_caller_arn",
                    condition: true,
                    value: "aws_iam_role." + "role_" + getPropertyValue(serviceData, "Name") + ".arn",
                    dependsOnProperties: ["Name"],
                },
            ]
        },
        {
            name: "deletion_protection",
            condition: true,
            value: addQuotes("INACTIVE"),
        },
        {
            name: "device_configuration",
            condition: true,
            value: [
                {
                    name: "challenge_required_on_new_device",
                    condition: true,
                    value: "true",
                },
                {
                    name: "device_only_remembered_on_user_prompt",
                    condition: true,
                    value: "true",
                },
            ]
        },
        {
            name: "admin_create_user_config",
            condition: true,
            value: [
                {
                    name: "allow_admin_create_user_only",
                    condition: true,
                    value: "false",
                },
                {
                    name: "invite_message_template",
                    condition: true,
                    value: [
                        {
                            name: "email_message",
                            condition: true,
                            value: addQuotes("Your username is {username} and temporary password is {####}."),
                        },
                        {
                            name: "email_subject",
                            condition: true,
                            value: addQuotes("Your temporary password"),
                        },
                        {
                            name: "sms_message",
                            condition: true,
                            value: addQuotes("Your username is {username} and temporary password is {####}."),
                        },
                    ]
                }
            ]
        }
    )

    getPropertyValue(serviceData, "Sign-in method") === "Email and phone number" && cognitoComponents.push({
        component: "resource",
        type: "aws_iam_role",
        componentName: "role_" + getPropertyValue(serviceData, "Name"),
        dependsOnProperties: ["Name"],
        condition: true,
        arguments: [
            {
                name: "name",
                condition: true,
                value: addQuotes("role_" + getPropertyValue(serviceData, "Name")),
                dependsOnProperties: ["Name"]
            },
            {
                name: "assume_role_policy",
                condition: true,
                value: "data.aws_iam_policy_document.assume_role_policy_doc_" + getPropertyValue(serviceData, "Name") + ".json",
                dependsOnProperties: ["Name"]
            },
        ]
    },
        {
            //push the iam policy for sns, necessary to send sms
            component: "data",
            type: "aws_iam_policy_document",
            componentName: "assume_role_policy_doc_" + getPropertyValue(serviceData, "Name"),
            dependsOnProperties: ["Name"],
            condition: true,
            arguments: [
                {
                    name: "statement",
                    condition: true,
                    value: [
                        {
                            name: "actions",
                            condition: true,
                            value: "[\"sts:AssumeRole\"]"
                        },
                        {
                            name: "principals",
                            condition: true,
                            value: [
                                {
                                    name: "type",
                                    condition: true,
                                    value: addQuotes("Service")
                                },
                                {
                                    name: "identifiers",
                                    condition: true,
                                    value: "[\"cognito-idp.amazonaws.com\"]",
                                },
                            ]
                        },
                        {
                            name: "condition",
                            condition: true,
                            value: [
                                {
                                    name: "test",
                                    condition: true,
                                    value: addQuotes("StringEquals"),
                                },
                                {
                                    name: "variable",
                                    condition: true,
                                    value: addQuotes("sts:ExternalId"),
                                },
                                {
                                    name: "values",
                                    condition: true,
                                    value: "[\"role_" + getPropertyValue(serviceData, "Name") + "\"]",
                                    dependsOnProperties: ["Name"]
                                },
                            ]
                        }
                    ]
                }
            ]
        },
        {
            component: "resource",
            type: "aws_iam_role_policy",
            componentName: "role_policy_" + getPropertyValue(serviceData, "Name"),
            dependsOnProperties: ["Name"],
            condition: true,
            arguments: [
                {
                    name: "name",
                    condition: true,
                    value: addQuotes("role_policy_" + getPropertyValue(serviceData, "Name")),
                    dependsOnProperties: ["Name"]
                },
                {
                    name: "role",
                    condition: true,
                    value: "aws_iam_role.role_" + getPropertyValue(serviceData, "Name") + ".id",
                    dependsOnProperties: ["Name"]
                },
                {
                    name: "policy",
                    condition: true,
                    value: "data.aws_iam_policy_document.policy_doc_" + getPropertyValue(serviceData, "Name") + ".json",
                    dependsOnProperties: ["Name"]
                },
            ]
        },
        {
            component: "data",
            type: "aws_iam_policy_document",
            componentName: "policy_doc_" + getPropertyValue(serviceData, "Name"),
            dependsOnProperties: ["Name"],
            condition: true,
            arguments: [
                {
                    name: "statement",
                    condition: true,
                    value: [
                        {
                            name: "actions",
                            condition: true,
                            value: "[\"sns:Publish\"]"
                        },
                        {
                            name: "resources",
                            condition: true,
                            value: "[\"*\"]",
                        },
                    ]
                }
            ]
        },
    )

    return {
        components: cognitoComponents
    }
}

export { }