export const validateSecurityGroupName = (sgName: string) => {
    let messages = [];
    let isValid = true;

    // Checking length of security group name
    if (sgName.length < 1 || sgName.length > 255) {
        messages.push("Security group name must be between 1 and 255 characters long.");
        isValid = false;
    }

    // Checking if the security group name consists of alphanumeric characters, spaces, and the following characters: _ . : / = + - @
    if (!/^[a-zA-Z0-9\s_.:\/=+-@]+$/.test(sgName)) {
        messages.push("Security group name can only consist of alphanumeric characters, spaces, and the following characters: _ . : / = + - @");
        isValid = false;
    }


    return {
        isValid,
        messages
    };
}

export const validatePortsList = (ports: string) => {
    let messages = [];
    let isValid = true;

    // Checking if the security group ports is a list of numbers comma-separated
    if (!/^( *[0-9]+( *\,)?)*$/.test(ports)) {
        messages.push("Custom ports should be a comma-separated list of integers");
        isValid = false;
    }

    return {
        isValid,
        messages
    };
}