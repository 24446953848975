import { Component, Service, ServiceData, ServiceTranslation, addQuotes, getPropertyValue } from "../../Service";
import { validateService } from "../validators/generalValidator";
import { validateS3DaysNumber, validateS3Name } from "../validators/s3Validator";

const defaultName = "s3-bucket"

const getStorageClassSymbol = (storageClass: string) => {
    switch (storageClass) {
        case "Infrequent access":
            return "STANDARD_IA"
        case "Intelligent tiering":
            return "INTELLIGENT_TIERING"
        case "One zone infrequent access":
            return "ONEZONE_IA"
        case "Deep archive":
            return "DEEP_ARCHIVE"
        case "Glacier":
            return "GLACIER"
        default:
            return "STANDARD"
    }
}

export const s3: Service = {
    serviceName: "S3 Bucket",
    defaultName: defaultName,
    imgUrl: "img/S3.png",
    filePath: "/root/modules/inkdrop/main.tf",
    validate: (serviceData: ServiceData) => validateService(serviceData),
    borderColor: "#448927",
    category: "Storage",
    backgroundColor: "#d6f0cb",
    isWrapper: false,
    arrowInTypes: ["Interact", "API Integration"],
    arrowOutTypes: ["Invoke Function", "SQS Message", "SNS Notification", "Policy Restriction"],
    parentsTypes: [],
    properties: [
        {
            name: "Object lifecycle",
            condition: () => { return true },
            docsUrl: "https://docs.aws.amazon.com/AmazonS3/latest/userguide/object-lifecycle-mgmt.html",
            tfDocsUrl: "https://registry.terraform.io/providers/hashicorp/aws/latest/docs/resources/s3_bucket_lifecycle_configuration",
            defaultValue: "None",
            choices: ["None", "Transition", "Expiration"],
            type: "string",
            mandatory: true
        },
        {
            name: "Change Storage class to",
            condition: (serviceData: ServiceData) => { return getPropertyValue(serviceData, "Object lifecycle") === "Transition" },
            docsUrl: "",
            tfDocsUrl: "",
            defaultValue: "Infrequent access",
            choices: ["Infrequent access", "Intelligent tiering", "Glacier"],
            type: "string",
            mandatory: true
        },
        {
            name: "Transition objects after (days)",
            condition: (serviceData: ServiceData) => { return getPropertyValue(serviceData, "Object lifecycle") === "Transition" },
            validate: (serviceData: ServiceData) => validateS3DaysNumber(serviceData.properties.filter((p) => {
                return p.name === "Transition objects after (days)"
            })[0].value as string),
            docsUrl: "",
            tfDocsUrl: "",
            defaultValue: "30",
            type: "string",
            mandatory: true
        },
        {
            name: "Delete objects after (days)",
            condition: (serviceData: ServiceData) => { return getPropertyValue(serviceData, "Object lifecycle") === "Expiration" },
            validate: (serviceData: ServiceData) => validateS3DaysNumber(serviceData.properties.filter((p) => {
                return p.name === "Delete objects after (days)"
            })[0].value as string),
            docsUrl: "",
            tfDocsUrl: "",
            defaultValue: "30",
            type: "string",
            mandatory: true
        },
        {
            name: "Name",
            condition: () => { return true },
            validate: (serviceData: ServiceData) => validateS3Name(serviceData.properties.filter((p) => {
                return p.name === "Name"
            })[0].value as string),
            docsUrl: "",
            tfDocsUrl: "",
            isName: true,
            defaultValue: defaultName,
            type: "string",
            mandatory: true,
        },
    ],
}
export const s3ServiceTranslation = (serviceData: ServiceData): ServiceTranslation => {
    return {
        components: [
            {
                component: "resource",
                type: "aws_s3_bucket",
                componentName: getPropertyValue(serviceData, "Name"),
                dependsOnProperties: ["Name"],
                condition: true,
                arguments: [
                    {
                        name: "bucket",
                        condition: true,
                        value: addQuotes(getPropertyValue(serviceData, "Name")),
                        dependsOnProperties: ["Name"],
                    },
                    {
                        name: "force_destroy",
                        condition: true,
                        value: "false"
                    },
                ]
            },
            {
                component: "resource",
                type: "aws_s3_bucket_lifecycle_configuration",
                componentName: "lifecycle_" + getPropertyValue(serviceData, "Name"),
                condition: getPropertyValue(serviceData, "Object lifecycle") !== "None",
                dependsOnProperties: ["Object lifecycle", "Name"],
                arguments: [
                    {
                        name: "bucket",
                        condition: true,
                        value: "aws_s3_bucket." + getPropertyValue(serviceData, "Name") + ".id",
                        dependsOnProperties: ["Name"],
                    },
                    {
                        name: "rule",
                        condition: true,
                        value: [
                            {
                                name: "id",
                                condition: true,
                                value: addQuotes("rule-1")
                            },
                            {
                                name: "filter",
                                condition: true,
                                value: []
                            },
                            {
                                name: "transition",
                                condition: getPropertyValue(serviceData, "Object lifecycle") === "Transition",
                                dependsOnProperties: ["Object lifecycle"],
                                value: [
                                    {
                                        name: "days",
                                        condition: true,
                                        value: getPropertyValue(serviceData, "Transition objects after (days)"),
                                        dependsOnProperties: ["Transition objects after (days)"]
                                    },
                                    {
                                        name: "storage_class",
                                        condition: true,
                                        value: addQuotes(getStorageClassSymbol(getPropertyValue(serviceData, "Change Storage class to"))),
                                        dependsOnProperties: ["Change Storage class to"]
                                    },
                                ]
                            },
                            {
                                name: "expiration",
                                condition: getPropertyValue(serviceData, "Object lifecycle") === "Expiration",
                                dependsOnProperties: ["Object lifecycle"],
                                value: [
                                    {
                                        name: "days",
                                        condition: true,
                                        value: getPropertyValue(serviceData, "Delete objects after (days)"),
                                        dependsOnProperties: ["Delete objects after (days)"]
                                    },
                                ]
                            },
                            {
                                name: "status",
                                condition: true,
                                value: addQuotes("Enabled")
                            },
                        ]
                    },
                ]
            },
            ...(serviceData.arrowOut.filter((arrow) => {
                return ["Invoke Function", "SQS Message", "SNS Notification"].indexOf(arrow.arrowData.type) !== -1
            }).map((arrow, index) => {
                return {
                    component: "resource",
                    type: "aws_s3_bucket_notification",
                    componentName: "bucket_notification_" + getPropertyValue(serviceData, "Name") + "_" + index,
                    condition: true,
                    dependsOnProperties: ["serviceOut." + arrow.arrowData.endId + ".Name", "Name"],
                    arguments: [
                        {
                            name: "bucket",
                            condition: true,
                            value: addQuotes(getPropertyValue(serviceData, "Name")),
                            dependsOnProperties: ["Name"]
                        },
                        arrow.serviceData.serviceName === "Lambda" ?
                            {
                                name: "lambda_function",
                                condition: true,
                                value: [
                                    {
                                        name: "lambda_function_arn",
                                        condition: true,
                                        value: "aws_lambda_function." + getPropertyValue(arrow.serviceData, "Name") + ".arn",
                                        dependsOnProperties: ["serviceOut." + arrow.arrowData.endId + ".Name"]
                                    },
                                    {
                                        name: "events",
                                        condition: true,
                                        value: arrow.arrowData.properties.find((p) => {
                                            return p.name === "Trigger on"
                                        })?.value === "Create" ? "[\"s3:ObjectCreated:*\"]" : "[\"s3:ObjectRemoved:*\"]",
                                        dependsOnProperties: ["arrowOut." + arrow.arrowData.shapeId + ".Trigger on"]
                                    },
                                ]
                            } : arrow.serviceData.serviceName === "SQS Queue" ? {
                                name: "queue",
                                condition: true,
                                value: [
                                    {
                                        name: "queue_arn",
                                        condition: true,
                                        value: "aws_sqs_queue." + getPropertyValue(arrow.serviceData, "Name") + ".arn",
                                        dependsOnProperties: ["serviceOut." + arrow.arrowData.endId + ".Name"]
                                    },
                                    {
                                        name: "events",
                                        condition: true,
                                        value: arrow.arrowData.properties.find((p) => {
                                            return p.name === "Send message on"
                                        })?.value === "Create" ? "[\"s3:ObjectCreated:*\"]" : "[\"s3:ObjectRemoved:*\"]",
                                        dependsOnProperties: ["arrowOut." + arrow.arrowData.shapeId + ".Trigger on"]
                                    },
                                ]
                            } : {
                                name: "topic",
                                condition: true,
                                value: [
                                    {
                                        name: "topic_arn",
                                        condition: true,
                                        value: "aws_sns_topic." + getPropertyValue(arrow.serviceData, "Name") + ".arn",
                                        dependsOnProperties: ["serviceOut." + arrow.arrowData.endId + ".Name"]
                                    },
                                    {
                                        name: "events",
                                        condition: true,
                                        value: arrow.arrowData.properties.find((p) => {
                                            return p.name === "Send notification on"
                                        })?.value === "Create" ? "[\"s3:ObjectCreated:*\"]" : "[\"s3:ObjectRemoved:*\"]",
                                        dependsOnProperties: ["arrowOut." + arrow.arrowData.shapeId + ".Trigger on"]
                                    },
                                ]
                            },
                    ]
                }
            }) as Component[])
        ]
    }
}
export { }