import { ArrowData, ArrowDefinition } from "../../Service";

export const s3Message: ArrowDefinition = {
    type: "SQS Message",
    fromType: "S3 Bucket",
    toType: "SQS Queue",
    properties: [
        {
            name: "Send message on",
            condition: () => { return true },
            defaultValue: "Create",
            choices: ["Create", "Delete"],
            type: "string",
            mandatory: true,
        }
    ]
}