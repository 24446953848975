import { Argument, Component, Service, ServiceData, ServiceTranslation, addQuotes, addSquareBrackets, getObjectFormat, getParentByServiceName, getParentShapeId, getPropertyFromArrowIn, getPropertyValue, hasArrowTypeIn, removeQuotes, services, stringToObject, updatePropertyValue } from "../../Service";
import { validateService } from "../validators/generalValidator";
import { validateIAMRoleName } from "../validators/iamRoleValidator";

function getAwsIdentifier(serviceName: string): string {
    switch (serviceName) {
        case 'Lambda':
            return 'lambda.amazonaws.com';
        case 'S3 Bucket':
            return 's3.amazonaws.com';
        case 'SQS':
            return 'sqs.amazonaws.com';
        case 'DynamoDB':
            return 'dynamodb.amazonaws.com';
        case 'RDS':
            return 'rds.amazonaws.com';
        default:
            return 'lambda.amazonaws.com';
    }
}

const defaultName = "iam-role"

export const iamRole: Service = {
    serviceName: "IAM Role",
    defaultName: defaultName,
    borderColor: "#DD3033",
    backgroundColor: "#fbeaea",
    category: "Security, Identity, & Compliance",
    filePath: "/root/modules/inkdrop/main.tf",
    validate: (serviceData: ServiceData) => validateService(serviceData),
    parentsTypes: [],
    isWrapper: true,
    imgUrl: "img/IAM.png",
    arrowInTypes: ["Policy"],
    arrowOutTypes: [],
    properties: [
        {
            name: "Service type",
            condition: () => { return true },
            docsUrl: "",
            tfDocsUrl: "",
            defaultValue: "Lambda",
            type: "string",
            mandatory: true,
            choices: ["Lambda"]
        },
        {
            name: "Name",
            condition: () => { return true },
            validate: (serviceData: ServiceData) => validateIAMRoleName(serviceData.properties.filter((p) => {
                return p.name === "Name"
            })[0].value as string),
            docsUrl: "",
            tfDocsUrl: "",
            isName: true,
            defaultValue: defaultName,
            type: "string",
            mandatory: true,
        },
    ],
}

export const iamRoleServiceTranslation = (serviceData: ServiceData): ServiceTranslation => {

    return {
        components: [
            {
                component: "data",
                type: "aws_iam_policy_document",
                componentName: "assume_role_policy_doc_" + getPropertyValue(serviceData, "Name"),
                dependsOnProperties: ["Name"],
                condition: true,
                arguments: [
                    {
                        name: "statement",
                        condition: true,
                        value: [
                            {
                                name: "actions",
                                condition: true,
                                value: "[\"sts:AssumeRole\"]"
                            },
                            {
                                name: "principals",
                                condition: true,
                                value: [
                                    {
                                        name: "type",
                                        condition: true,
                                        value: addQuotes("Service")
                                    },
                                    {
                                        name: "identifiers",
                                        condition: true,
                                        value: "[\"" + getAwsIdentifier(getPropertyValue(serviceData, "Service type")) + "\"]",
                                        dependsOnProperties: ["Service type"]
                                    },
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                component: "resource",
                type: "aws_iam_role",
                componentName: getPropertyValue(serviceData, "Name"),
                dependsOnProperties: ["Name"],
                condition: true,
                arguments: [
                    {
                        name: "name",
                        condition: true,
                        value: addQuotes(getPropertyValue(serviceData, "Name")),
                        dependsOnProperties: ["Name"]
                    },
                    {
                        name: "assume_role_policy",
                        condition: true,
                        value: "data.aws_iam_policy_document.assume_role_policy_doc_" + getPropertyValue(serviceData, "Name") + ".json",
                        dependsOnProperties: ["Name"]
                    },

                ]
            },
            ...(serviceData.arrowIn.filter((arrow) => {
                return arrow.arrowData.type === "Policy"
            }).map((arrow, index) => {
                return {
                    component: "resource",
                    type: "aws_iam_role_policy",
                    componentName: "role_" + getPropertyValue(serviceData, "Name") + "_" + index,
                    condition: hasArrowTypeIn(serviceData, "Policy"),
                    dependsOnProperties: ["arrowIn." + arrow.arrowData.shapeId + ".Name", "Name"],
                    arguments: [
                        {
                            name: "name",
                            condition: true,
                            value: addQuotes("role_" + getPropertyValue(arrow.serviceData, "Name")),
                            dependsOnProperties: ["serviceIn." + arrow.arrowData.startId + ".Name"]
                        },
                        {
                            name: "role",
                            condition: true,
                            value: "aws_iam_role." + getPropertyValue(serviceData, "Name") + ".id",
                            dependsOnProperties: ["Name"]
                        },
                        {
                            name: "policy",
                            condition: true,
                            value: "data.aws_iam_policy_document." + getPropertyValue(arrow.serviceData, "Name") + ".json",
                            dependsOnProperties: ["serviceIn." + arrow.arrowData.startId + ".Name"]
                        },
                    ]
                }
            }) as Component[]),
        ]
    }
}