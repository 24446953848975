import { Component, Service, ServiceData, ServiceTranslation, addQuotes, addSquareBrackets, getObjectFormat, getObjectPropertyValue, getParentShapeId, getPropertyFromArrowIn, getPropertyValue, hasArrowTypeIn, removeQuotes, stringToObject, updatePropertyValue } from "../../Service";
import { validateEcsContainerDefinition, validateEcsService } from "../validators/ecsServiceValidator";
import { validateService } from "../validators/generalValidator";
import { validateVPCOutboundTraffic } from "../validators/vpcValidator";
import { getSubnetsByType, hasPublicAndPrivateSubnets } from "./vpc";

const defaultName = "ecs-service"

export const ecsService: Service = {
    serviceName: "ECS Service",
    defaultName: defaultName,
    borderColor: "#E47413",
    category: "Compute",
    backgroundColor: "#fae3ce",
    parentsTypes: ["ECS Cluster", "VPC", "Security Group"],
    validate: (serviceData: ServiceData) => validateEcsService(serviceData),
    filePath: "/root/modules/inkdrop/main.tf",
    isWrapper: false,
    imgUrl: "img/ECS.png",
    arrowInTypes: [],
    arrowOutTypes: [],
    properties: [
        {
            name: "Container definitions",
            condition: () => { return true },
            validate: (serviceData: ServiceData) => {
                return validateEcsContainerDefinition(getObjectPropertyValue(serviceData, "Container definitions") as any)
            },
            containerDefinition: true,
            docsUrl: "",
            tfDocsUrl: "",
            defaultValue: [{ imageUri: "", ports: [{ containerPort: "", hostPort: "" }] }],
            type: "Object[]",
            mandatory: true,
        },
        {
            name: "Service subnet location",
            condition: (serviceData: ServiceData) => {
                return hasPublicAndPrivateSubnets(serviceData)
            },
            docsUrl: "",
            tfDocsUrl: "",
            defaultValue: "Public subnets",
            choices: ["Public subnets", "Private subnets"],
            type: "string",
            mandatory: true
        },
        {
            name: "Name",
            condition: () => { return true },
            docsUrl: "",
            tfDocsUrl: "",
            isName: true,
            defaultValue: defaultName,
            type: "string",
            mandatory: true,
        },
    ],
}

const getContainerDefinitionJSON = (containerDefinition: {
    imageUri: string,
    ports: {
        containerPort: string,
        hostPort: string
    }[],
}[]) => {
    //Create the JSON object, then return as a hcl formatted string

    let finalString = "["

    containerDefinition.forEach((c) => {
        finalString += `{
            name = "${c.imageUri.split("/")[c.imageUri.split("/").length - 1].replaceAll(":", "-")}",
            image = "${c.imageUri}",
            portMappings = [${c.ports.filter(
            (p, index) => {
                return index !== c.ports.length - 1 &&
                    p.containerPort !== "" && p.hostPort !== "" && p.containerPort !== undefined && p.hostPort !== undefined
            }
        ).map(p => {
            return `{
                containerPort = ${parseInt(p.containerPort)},
                hostPort = ${parseInt(p.hostPort)},
            }`
        }
        )}],
            cpu = 1024,
            memory = 2048,
            essential = true,
        },`
    })

    finalString += "]"
    return finalString

}


export const ecsServiceServiceTranslation = (serviceData: ServiceData): ServiceTranslation => {
    return {
        components: [
            {
                component: "resource",
                type: "aws_ecs_service",
                componentName: getPropertyValue(serviceData, "Name"),
                condition: true,
                dependsOnProperties: ["Name"],
                arguments: [
                    {
                        name: "name",
                        condition: true,
                        value: addQuotes(getPropertyValue(serviceData, "Name")),
                        dependsOnProperties: ["Name"],
                    },
                    ...(serviceData.parents.some(p => p.serviceData.serviceName === "ECS Cluster") ? [
                        {
                            name: "cluster",
                            condition: true,
                            value: "aws_ecs_cluster." +
                                getPropertyValue(serviceData.parents.filter(p => p.serviceData.serviceName === "ECS Cluster")[0].serviceData, "Name") + ".id",
                            dependsOnProperties: ["parent." + serviceData.parents.filter(p => p.serviceData.serviceName === "ECS Cluster")[0].shapeId + ".Name"]
                        },
                    ] : []),
                    {
                        name: "task_definition",
                        condition: true,
                        value: "aws_ecs_task_definition." + getPropertyValue(serviceData, "Name") + "_task_definition.arn",
                        dependsOnProperties: ["Name"]
                    },
                    {
                        name: "desired_count",
                        condition: true,
                        value: "1",
                    },
                    {
                        name: "launch_type",
                        condition: true,
                        value: addQuotes("FARGATE"),
                    },
                    {
                        name: "scheduling_strategy",
                        condition: true,
                        value: addQuotes("REPLICA"),
                    },
                    ...(serviceData.parents.some(p => p.serviceData.serviceName === "VPC") ? [
                        {
                            name: "network_configuration",
                            condition: true,
                            value: [{
                                name: "subnets",
                                condition: true,
                                value: "[" + getSubnetsByType(serviceData.parents.filter((p) => {
                                    return p.serviceData.serviceName === "VPC"
                                })[0].serviceData, getPropertyValue(serviceData, "Service subnet location") as "Private subnets" | "Public subnets"
                                ).join(", ") + "]",
                                dependsOnProperties: ["parent." + getParentShapeId(serviceData, "VPC") + ".Number of public subnets",
                                "parent." + getParentShapeId(serviceData, "VPC") + ".Number of private subnets",
                                "parent." + getParentShapeId(serviceData, "VPC") + ".Number of availability zones",
                                "parent." + getParentShapeId(serviceData, "VPC") + ".Name",
                                    "Service subnet location"]
                            },
                            ...(serviceData.parents.some(p => p.serviceData.serviceName === "Security Group") ? [
                                {
                                    name: "security_groups",
                                    condition: serviceData.parents.some(p =>
                                        p.serviceData.serviceName === "Security Group"
                                    ),
                                    value: "[aws_security_group." +
                                        getPropertyValue(serviceData.parents.filter((p) => {
                                            return p.serviceData.serviceName === "Security Group"
                                        })[0].serviceData, "Name") + ".id]",
                                    dependsOnProperties: ["parent." + getParentShapeId(serviceData, "Security Group") + ".Name"]
                                }] : []),
                            {
                                name: "assign_public_ip",
                                condition: true,
                                value: "false",
                            }
                            ],
                        }
                    ] : [])
                ]
            },
            {
                component: "resource",
                type: "aws_ecs_task_definition",
                componentName: getPropertyValue(serviceData, "Name") + "_task_definition",
                condition: true,
                dependsOnProperties: ["Name"],
                arguments: [
                    {
                        name: "family",
                        condition: true,
                        value: addQuotes(getPropertyValue(serviceData, "Name") + "_task_definition"),
                        dependsOnProperties: ["Name"],
                    },
                    {
                        name: "container_definitions",
                        condition: true,
                        value: "jsonencode(" + getContainerDefinitionJSON(getObjectPropertyValue(serviceData, "Container definitions") as any) + ")",
                        dependsOnProperties: ["Container definitions"],
                    },
                    {
                        name: "requires_compatibilities",
                        condition: true,
                        value: "[" + addQuotes("FARGATE") + "]",
                    },
                    {
                        name: "network_mode",
                        condition: true,
                        value: addQuotes("awsvpc"),
                    },
                    {
                        name: "cpu",
                        condition: true,
                        value: "1024",
                    },
                    {
                        name: "memory",
                        condition: true,
                        value: "2048",
                    },

                ]
            },
        ]
    }
}