export const validateS3Name = (bucketName: string) => {
    let messages = [];
    let isValid = true;

    // Checking length of bucket name
    if (bucketName.length < 3 || bucketName.length > 63) {
        messages.push("Bucket names must be between 3 and 63 characters long.");
        isValid = false;
    }

    // Checking if the bucket name has only lowercase letters, numbers, dots (.), and hyphens (-)
    if (!/^[a-z0-9.-]+$/.test(bucketName)) {
        messages.push("Bucket names can only consist of lowercase letters, numbers, dots (.), and hyphens (-).");
        isValid = false;
    }

    // Checking if the bucket name begins and ends with a letter or a number
    if (!/^[a-z0-9].*[a-z0-9]$/.test(bucketName)) {
        messages.push("Bucket names must begin and end with a letter or number.");
        isValid = false;
    }

    // Checking if the bucket name has two adjacent periods
    if (/\.\./.test(bucketName)) {
        messages.push("Bucket names must not contain two adjacent periods.");
        isValid = false;
    }

    return {
        isValid,
        messages
    };
}

export const validateS3DaysNumber = (value: string) => {
    let isValid = true
    const messages: string[] = []
    if (!/^[0-9]+$/.test(value)) {
        isValid = false
        messages.push("After (days) must be a number")
    }
    return { isValid, messages }
}