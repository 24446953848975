import { ServiceData } from "../../Service"

export const validateService = (serviceData: ServiceData) => {
    return propertiesValidation(serviceData)
}

const propertiesValidation = (serviceData: ServiceData) => {
    let arePropertiesValid = true
    const propertiesMessages: string[] = []
    serviceData.properties.forEach((p) => {
        if (p.condition(serviceData) && p.validate) {
            const { isValid, messages } = p.validate(serviceData)
            if (!isValid) arePropertiesValid = false
            propertiesMessages.push(...messages)
        }
    })
    return { isValid: arePropertiesValid, messages: propertiesMessages }
}

export const validateAwsImageUri = (uri: string) => {
    const isValid = uri.match(/^[\d]{1,}\.dkr\.ecr\.\w+-\w+-\d+\.amazonaws\.com\/(?:aws\/)?[\w\/\.\-]{1,}(:[\w\-\.]{1,}){0,1}$/g) !== null
    const messages = uri ? isValid ? [] : ["Invalid image URI. The URI should link to a valid image in Amazon ECR"] : ["Image URI can't be empty"]
    return { isValid, messages }
}

export const validateDockerImageNotAws = (image: string) => {
    const isValid = image.match(/^[a-z][a-z0-9-_.\/]+(:[a-z0-9-_.\/]+)?$/g) !== null
    const messages = image ? isValid ? [] : ["Invalid image name. The name should consist of letters, numbers, hyphens (-), and underscores (_)."] : ["Image name can't be empty"]
    return { isValid, messages }
}

export const validateDockerImage = (image: string) => {
    const { isValid: isAwsImage, messages: awsMessages } = validateAwsImageUri(image)
    const { isValid: isNotAwsImage, messages: notAwsMessages } = validateDockerImageNotAws(image)
    const isValid = isAwsImage || isNotAwsImage
    const messages = image ? isValid ? [] : ["Invalid image name. The name must start with a letter and can only contain lowercase letters, numbers, hyphens, underscores, periods and forward slashes, or link to a valid image in Amazon ECR"] : ["Image name can't be empty"]
    return { isValid, messages }
}

export const validateEmail = (email: string) => {
    let messages = [];
    let isValid = true;

    // Regex for checking the email format
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;

    if (!regex.test(email)) {
        messages.push("Enter a valid email address.");
        isValid = false;
    }

    return {
        isValid,
        messages
    };
}