import { FirebaseApp } from "firebase/app"
import { Outlet } from "react-router-dom"

import { useEffect, useState } from 'react';
import { AppBar, CssBaseline, Drawer, List, ListItem, ListItemText, Toolbar, Typography, Box, TextField, Button, IconButton, Tooltip } from '@mui/material';
import axios from "axios";
import { getAuth } from "firebase/auth";
import { doc, getDoc, getFirestore, setDoc } from "firebase/firestore";
import { useSnackbar } from "notistack";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const drawerWidth = 500;

const menuItems = ['Credentials'];

interface SettingsProps {
    app: FirebaseApp
}
const Settings = ({
    app
}: SettingsProps) => {

    const [AWSAccessKeyId, setAWSAccessKeyId] = useState("");
    const [AWSSecretAccessKey, setAWSSecretAccessKey] = useState("");
    const [selectedSetting, setSelectedSetting] = useState(0);
    const [isEncrypting, setIsEncrypting] = useState(false);
    const [content, setContent] = useState('Credentials');
    const [storedAccessKeyId, setStoredAccessKeyId] = useState('');

    const { enqueueSnackbar } = useSnackbar();


    const auth = getAuth(app)
    const fs = getFirestore(app)

    const documentRef = doc(fs, "users", auth.currentUser?.uid!)

    const handleClick = (text: string, index: number) => {
        setContent(text);
        setSelectedSetting(index)
    };

    const resetKey = () => {
        setAWSAccessKeyId("")
        setAWSSecretAccessKey("")
        setStoredAccessKeyId("")
    }

    useEffect(() => {
        getDoc(documentRef).then((d) => {
            setStoredAccessKeyId((d && d.data() && d.data()!.awsAccessKeyId as string) || "")
        })
    }, [])

    const handleSubmit = async (evt: any) => {
        evt.preventDefault();

        try {
            setIsEncrypting(true)
            const response = await axios.post('https://secrets-runner-2js36x37va-ey.a.run.app/store-aws-credentials', {
                "user_id": auth.currentUser?.uid,
                "aws_access_key_id": AWSAccessKeyId,
                "aws_secret_access_key": AWSSecretAccessKey
            });
            setIsEncrypting(false)
            if (response.data.status === "Success") {
                setStoredAccessKeyId(AWSAccessKeyId)
                setAWSAccessKeyId("")
                setAWSSecretAccessKey("")
                await setDoc(documentRef, {
                    awsAccessKeyId: AWSAccessKeyId
                }, { merge: true })
                enqueueSnackbar("AWS Credentials have been stored successfully.", { variant: "success" });
            } else { enqueueSnackbar(response.data.message, { variant: "error" }); }
        } catch (error) {
            console.error(`Error: ${error}`);
        }
    }

    return (
        <div className="flex">
            <CssBaseline />
            <AppBar position="fixed"
                sx={{
                    zIndex: 2000,
                    background: "white",
                    color: "#3e3745",
                    boxShadow: "none",
                    borderBottom: "1px solid #E0E0E0"
                }}>
                <Toolbar sx={{
                    '@media (min-width: 600px)': {
                        paddingLeft: "255px",
                    }
                }}>
                    <Tooltip title={"Back to editor"}>
                        <IconButton onClick={() => window.location.href = "/"}>
                            <ArrowBackIcon />
                        </IconButton>
                    </Tooltip>
                    <Typography sx={{ marginLeft: "8px" }} variant="h6" noWrap>
                        Account settings
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                anchor="left"
                PaperProps={{
                    sx: {
                        width: drawerWidth,
                        paddingLeft: "250px",
                        border: "none"
                    }
                }}
                sx={{
                    width: drawerWidth,
                }}
            >
                <div className=" h-20" />
                <List>
                    {menuItems.map((text, index) => (
                        <ListItem button selected={index === selectedSetting} key={index} onClick={() => handleClick(text, index)}>
                            <ListItemText primary={text} />
                        </ListItem>
                    ))}
                </List>
            </Drawer>
            <main>
                <div className=" h-24" />
                <div className=" ml-8 mb-4">
                    <Typography variant="h6"
                        sx={{
                            color: "#3e3745"
                        }}
                    >{content}</Typography>
                </div>
                {content === 'Credentials' &&
                    <div className=" ml-8">
                        < form onSubmit={handleSubmit} noValidate autoComplete="off">
                            <TextField
                                disabled={storedAccessKeyId !== "" || isEncrypting}
                                name="AWSAccessKeyId"
                                value={storedAccessKeyId || AWSAccessKeyId}
                                label={"AWS Access Key ID"}
                                onChange={e => setAWSAccessKeyId(e.target.value)}
                                sx={{ width: '100%', marginBottom: 2 }}
                            />
                            <TextField
                                disabled={storedAccessKeyId !== "" || isEncrypting}
                                name="AWSSecretAccessKey"
                                value={storedAccessKeyId ? "*****" : AWSSecretAccessKey}
                                label={"AWS Secret Access Key"}
                                type="password"
                                onChange={e => setAWSSecretAccessKey(e.target.value)}
                                sx={{ width: '100%', marginBottom: 2 }}
                            />
                            {!storedAccessKeyId &&
                                <Button disabled={isEncrypting} variant="outlined" color="primary" type="submit">
                                    {isEncrypting ? "Encrypting..." : "Save"}
                                </Button>
                            }
                        </form>
                        {storedAccessKeyId &&
                            <Button variant="outlined" color="primary" onClick={() => resetKey()}>
                                Reset key
                            </Button>
                        }
                    </div>}
            </main >
            <Outlet />
        </div >
    )
}
export default Settings