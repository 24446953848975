import { ArrowDefinition } from "../../Service"

export const rdsNotification: ArrowDefinition = {
    type: "SNS Notification",
    fromType: "RDS Instance",
    toType: "SNS Topic",
    properties: [
        {
            name: "Send notification on",
            condition: () => { return true },
            defaultValue: "Low storage",
            choices: [
                "Availability",
                "Deletion",
                "Failover",
                "Failure",
                "Low storage",
                "Maintenance",
                "Notification",
                "Read replica",
                "Recovery",
                "Restoration",
            ],
            type: "string",
            mandatory: true,
        }
    ]
}