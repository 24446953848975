import { FirebaseApp } from "firebase/app";
import CustomButton from "../custom-components/CustomButton"
import { getAuth } from "firebase/auth";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import axios from "axios";
import UploadIcon from '@mui/icons-material/Upload';
import { NodeModel, RootGraphModel, SubgraphModel, fromDot } from "ts-graphviz"
import { getAvailableName } from "../utils/naming";
import Papa from "papaparse"
import { useEffect, useState } from "react";
import dagre from "dagre"
import { Editor, TLShapeId, useValue } from "@tldraw/tldraw";
import { CircularProgress } from "@mui/material";
import { enqueueSnackbar } from "notistack";

interface DiagramGeneratorProps {
    app: FirebaseApp
    fileInputRef: React.MutableRefObject<HTMLInputElement | null>
    editor: Editor | undefined
}

type NodeGroup = {
    nodes: NodeModel[],
    mainNode: NodeModel,
    connectionsOut: string[],
    connectionsIn: string[],
    name: string,
    type: string,
    iconPath: string,
    serviceName: string
    moduleName?: string
}

const DiagramGenerator = ({
    app, fileInputRef, editor
}: DiagramGeneratorProps) => {
    const handleUploadClick = () => {
        // Trigger the file input click event
        (fileInputRef.current as any).click();
    };

    const [csvJson, setCsvJson] = useState<any>(undefined);
    const [isUploading, setIsUploading] = useState(false);

    const defaultWidth = 120, defaultHeight = 120

    useEffect(() => {
        fetch('./terraform_resources.csv')
            .then((response) => response.text())
            .then((text) => {
                // Here `text` is the CSV file content as a string
                const jsonArray = Papa.parse(text, { delimiter: ",", header: true })
                setCsvJson(jsonArray);
            })
            .catch((error) => {
                console.error('Error fetching CSV:', error);
            });
    }, []);

    const handleFileChange = async (event: any) => {
        setIsUploading(true)
        const files = event.target.files;
        if (files && files.length > 0) {
            const file = files[0];
            const auth = getAuth(app)
            const storage = getStorage(app);
            const date = Date.now()
            const storageRef = ref(storage, "tf-projects/" + auth.currentUser?.uid + "/" + date + ".zip");
            await uploadBytes(storageRef, file).catch((e) => enqueueSnackbar("Error uploading file", { variant: "error" }))
            try {
                const response = await axios.post('https://graph-generator-2js36x37va-uc.a.run.app/generate-graph',
                    {
                        "user_id": auth.currentUser?.uid,
                        "bucket_name": "nocode-cloud-infra-dev.appspot.com",
                        "files_path": "tf-projects/" + auth.currentUser?.uid + "/" + date + ".zip"
                    }
                )
                const model = fromDot(response.data.graphviz_file)
                parseModel(model)
            } catch (error) {
                enqueueSnackbar("Error generating diagram: " + error, { variant: "error" })
            }
        }
        event.target.value = "";
        setIsUploading(false)
    };

    const checkHclBlockType = (blockId: string) => {
        let moduleName = ""
        if (blockId.startsWith("module.")) {
            moduleName = blockId.split(".")[1]
            blockId = blockId.split(".").slice(2).join(".")
        }
        const isModule = !blockId && moduleName

        const isData = blockId.startsWith("data.")
        const isVariable = blockId.startsWith("var.")
        const isLocal = blockId.startsWith("local.")
        const isOutput = blockId.startsWith("output.")
        const isProvider = blockId.startsWith("provider[")

        const isResource = blockId.startsWith("aws_")

        if (!isData && !isVariable && !isLocal && !isOutput && !isProvider && !isResource && !isModule) {
            console.warn("Unknown block type", blockId)
        }
        const splitBlockId = blockId.split(".")
        const isResourceWithName = isResource && splitBlockId.length > 1
        if (!isResource && !isModule) {
            blockId = splitBlockId.slice(1).join(".")
        }
        return { processedBlockId: blockId, isData, isVariable, isResource, isLocal, isOutput, isProvider, isModule, isResourceWithName, moduleName }
    }

    const getResourceNameAndType = (blockId: string) => {
        const resourceType = blockId.split(".") && blockId.split(".").filter(s => s.startsWith("aws_")).length > 0 ?
            blockId.split(".").filter(s => s.startsWith("aws_"))[0] : undefined
        const resourceName = resourceType && blockId.split(".").filter((s, index) => {
            return index > 0 && blockId.split(".")[index - 1] === resourceType
        })[0].split(" ")[0]
        return { resourceType, resourceName }
    }

    const parseModel = (model: RootGraphModel) => {
        const nodeGroups = new Map<string, NodeGroup>()
        model.subgraphs.forEach((subgraph) => {
            subgraph.nodes.forEach((node) => {
                let centralPart = node.id.split(" ")[1]
                if (centralPart) {
                    const { processedBlockId, isResourceWithName, moduleName } = checkHclBlockType(centralPart)
                    if (isResourceWithName) {
                        const { resourceType, resourceName } = getResourceNameAndType(processedBlockId)
                        if (resourceType && resourceName && csvJson) {
                            csvJson.data.forEach((row: any) => {
                                if (row["Main Diagram Blocks"].split(",").some((s: string) => s === resourceType)) {
                                    nodeGroups.set(node.id.split(" ")[1], {
                                        nodes: [node],
                                        mainNode: node,
                                        name: resourceName,
                                        type: resourceType,
                                        serviceName: row["Service Name"],
                                        iconPath: row["Icon Path"].trim(),
                                        connectionsIn: [],
                                        connectionsOut: [],
                                        moduleName: moduleName
                                    })
                                }
                            })
                        }
                    }
                }
            })
        })
        nodeGroups.forEach((nodeGroup) => {
            getConnectedNodes(nodeGroup.mainNode, nodeGroup, nodeGroups, model.subgraphs[0], true)
            getConnectedNodes(nodeGroup.mainNode, nodeGroup, nodeGroups, model.subgraphs[0], false)
        })
        // Compute connections between groups
        model.subgraphs[0].edges.forEach((edge) => {
            const edgeFromId = (edge.targets[0] as any).id
            const edgeToId = (edge.targets[1] as any).id

            const fromGroup = Array.from(nodeGroups).filter(([id, group]) => {
                return group.nodes.some((n) => {
                    return n.id === edgeFromId
                })
            })[0]
            const toGroup = Array.from(nodeGroups).filter(([id, group]) => {
                return group.nodes.some((n) => {
                    return n.id === edgeToId
                })
            })[0]
            if (fromGroup && toGroup && fromGroup !== toGroup) {
                const fromGroupKey = fromGroup[0]
                const toGroupKey = toGroup[0]
                if (!fromGroup[1].connectionsOut.includes(toGroupKey) && !toGroup[1].connectionsIn.includes(fromGroupKey)) {
                    fromGroup[1].connectionsOut.push(toGroupKey)
                    toGroup[1].connectionsIn.push(fromGroupKey)
                }
            }
        })
        computeLayout(nodeGroups)
    }

    const computeLayout = (nodeGroups: Map<string, NodeGroup>) => {
        const g = new dagre.graphlib.Graph({ compound: true });
        g.setGraph({ rankdir: "TB", ranksep: 120 });
        g.setDefaultEdgeLabel(function () { return {}; });
        nodeGroups.forEach((nodeGroup, key) => {

            g.setNode(key, { label: nodeGroup.name, width: defaultWidth, height: defaultHeight })
            nodeGroup.connectionsOut.forEach((connection) => {
                g.setEdge(key, connection)
            })
            if (nodeGroup.moduleName) {
                if (!g.hasNode("module." + nodeGroup.moduleName)) {
                    g.setNode("module." + nodeGroup.moduleName, { label: nodeGroup.moduleName })
                }
                g.setParent(key, "module." + nodeGroup.moduleName)
            }
        })
        dagre.layout(g);
        const date = Date.now()

        editor?.createShapes(
            g.nodes().filter((id) => {
                return g.children(id) && g.children(id)!.length > 0
            }).map((id) => {
                const node = g.node(id);
                return {
                    id: "shape:" + id + date as TLShapeId,
                    type: "frame",
                    x: node.x - node.width / 2,
                    y: node.y - node.height / 2,
                    props: {
                        name: id,
                        w: node.width,
                        h: node.height,
                    }
                }
            }))

        editor?.createShapes(
            g.nodes().filter((id) => {
                return !g.children(id) || g.children(id)!.length === 0
            }).map((id) => {
                const node = g.node(id);


                return {
                    id: "shape:" + id + date as TLShapeId,
                    type: "node",
                    x: node.x - node.width / 2,
                    y: node.y - node.height / 2,
                    props: {
                        name: node.label,
                        iconPath: nodeGroups.get(id)?.iconPath,
                    }
                }
            })
        )

        const arrowShapes: any[] = []

        nodeGroups.forEach((nodeGroup, id) => {
            nodeGroup.connectionsOut.forEach((connection) => {
                const connectionNode = nodeGroups.get(connection)
                if (connectionNode) {
                    const fromShape = editor?.getShape("shape:" + id + date as TLShapeId)
                    const toShape = editor?.getShape("shape:" + connection + date as TLShapeId)
                    if (fromShape && toShape) {
                        arrowShapes.push(
                            {
                                id: "shape:" + id + "-" + connection + date as TLShapeId,
                                type: "arrow",
                                props: {
                                    size: "s",
                                    start: {
                                        type: "binding",
                                        boundShapeId: fromShape.id,
                                        normalizedAnchor: {
                                            x: 0.5,
                                            y: 0.5
                                        },
                                        isExact: false
                                    },
                                    end: {
                                        type: "binding",
                                        boundShapeId: toShape.id,
                                        normalizedAnchor: {
                                            x: 0.5,
                                            y: 0.5
                                        },
                                        isExact: false
                                    }
                                }
                            }
                        )
                    }
                }
            })
        })
        editor?.createShapes(arrowShapes)
    }


    const getConnectedNodes = (node: NodeModel, nodeGroup: NodeGroup, nodeGroups: Map<string, NodeGroup>, subgraph: SubgraphModel, start: boolean) => {
        subgraph.edges.filter((e) => {
            return (e.targets[start ? 0 : 1] as any).id === node.id
        }).forEach((edge) => {
            const edgeToId = (edge.targets[start ? 1 : 0] as any).id
            let centralPart = edgeToId.split(" ")[1]
            if (centralPart) {
                const { isResourceWithName, processedBlockId, isData } = checkHclBlockType(centralPart)

                if (isResourceWithName || isData) {
                    const { resourceType, resourceName } = getResourceNameAndType(processedBlockId)
                    const isNodePresent = Array.from(nodeGroups.values()).some((group) => {
                        return group.nodes.some((n) => {
                            return n.id === (edge.targets[start ? 1 : 0] as any).id
                        })
                    })
                    if (resourceType && resourceName && csvJson && !isNodePresent &&
                        csvJson.data.some((row: any) => {
                            return row["Main Diagram Blocks"].split(",").some((s: string) => s === nodeGroup.type) &&
                                (row["Missing Resources"].split(",").some((s: string) => s === resourceType) ||
                                    row["Data Sources"].split(",").some((s: string) => s === resourceType))
                        })) {
                        const newNode = subgraph.nodes.filter((n) => { return n.id === (edge.targets[start ? 1 : 0] as any).id })[0]
                        if (newNode) {
                            nodeGroup.nodes.push(newNode)
                            getConnectedNodes(newNode, nodeGroup, nodeGroups, subgraph, start)
                            getConnectedNodes(newNode, nodeGroup, nodeGroups, subgraph, !start)
                        }
                    }
                }
            }
        })
    }

    return (
        <>
            <CustomButton onClick={handleUploadClick}
                disabled={isUploading}
                name={isUploading ? "Processing file" : "Upload terraform"} icon={isUploading ? <CircularProgress style={{
                    color: "#808080",
                    height: "20px",
                    width: "20px"
                }} /> : <UploadIcon />}>
                <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    style={{ display: 'none' }} // Hide the input element
                    accept=".zip" // Only accept .zip files
                />
            </CustomButton>
        </>
    )
}

export default DiagramGenerator