import { Argument, Component, Service, ServiceData, ServiceTranslation, addQuotes, addSquareBrackets, getObjectFormat, getObjectPropertyValue, getParentByServiceName, getParentShapeId, getPropertyFromArrowIn, getPropertyValue, hasArrowTypeIn, removeQuotes, stringToObject, updatePropertyValue } from "../../Service";
import { validateService } from "../validators/generalValidator";
import { validatePortsList, validateSecurityGroupName } from "../validators/securityGroupValidator";

const defaultName = "security-group"

export const securityGroup: Service = {
    serviceName: "Security Group",
    defaultName: defaultName,
    borderColor: "#7444D1",
    filePath: "/root/modules/inkdrop/main.tf",
    backgroundColor: "#f1ecfa",
    category: "Networking & Content Delivery",
    validate: (serviceData: ServiceData) => validateService(serviceData),
    parentsTypes: ["VPC"],
    isWrapper: true,
    imgUrl: "img/VPC.png",
    arrowInTypes: [],
    arrowOutTypes: [],
    properties: [
        {
            name: "Allow inbound traffic",
            condition: () => { return true },
            docsUrl: "https://docs.aws.amazon.com/vpc/latest/userguide/security-groups.html",
            tfDocsUrl: "https://registry.terraform.io/providers/hashicorp/aws/latest/docs/resources/security_group",
            defaultValue: ["SSH"],
            type: "string",
            multipleChoice: true,
            mandatory: true,
            choices: ["SSH", "HTTPS", "HTTP", "Custom ports"]
        },
        {
            name: "Allowed ports",
            condition: (serviceData) => { return (getObjectPropertyValue(serviceData, "Allow inbound traffic") as string[]).indexOf("Custom ports") !== -1 },
            validate: (serviceData: ServiceData) => validatePortsList(serviceData.properties.filter((p) => {
                return p.name === "Allowed ports"
            })[0].value as string),
            docsUrl: "https://docs.aws.amazon.com/vpc/latest/userguide/security-groups.html",
            tfDocsUrl: "https://registry.terraform.io/providers/hashicorp/aws/latest/docs/resources/security_group",
            defaultValue: "5432",
            type: "string",
            multipleChoice: true,
            mandatory: true,
        },
        {
            name: "Name",
            condition: () => { return true },
            validate: (serviceData: ServiceData) => validateSecurityGroupName(serviceData.properties.filter((p) => {
                return p.name === "Name"
            })[0].value as string),
            docsUrl: "",
            tfDocsUrl: "",
            isName: true,
            defaultValue: defaultName,
            type: "string",
            mandatory: true,
        },
    ],
}

export const securityGroupServiceTranslation = (serviceData: ServiceData): ServiceTranslation => {
    const portsArray: string[] = []

    const args: Argument[] = [{
        name: "name",
        condition: true,
        value: addQuotes(getPropertyValue(serviceData, "Name")),
        dependsOnProperties: ["Name"]
    },
    {
        name: "description",
        condition: true,
        value: addQuotes("Allow inbound traffic")
    }]


    serviceData.parents.some((p) => {
        return p.serviceData.serviceName === "VPC"
    }) && args.push({
        name: "vpc_id",
        condition: true,
        value: "aws_vpc." + getPropertyValue(serviceData.parents.filter((p) => {
            return p.serviceData.serviceName === "VPC"
        })[0].serviceData, "Name"
        ) + ".id",
        dependsOnProperties: ["parent." + getParentShapeId(serviceData, "VPC") + ".Name"]
    })

    const pushPortsToArray = () => {

        (getObjectPropertyValue(serviceData, "Allow inbound traffic") as string[]).forEach((s) => {
            switch (s) {
                case "HTTP":
                    portsArray.push("80")
                    break;
                case "HTTPS":
                    portsArray.push("443")
                    break;
                case "SSH":
                    portsArray.push("22")
                    break;
            }
        })
    }
    const pushCustomPortsToArray = () => {
        if ((getObjectPropertyValue(serviceData, "Allow inbound traffic") as string[]).indexOf("Custom ports") !== -1) {
            portsArray.push(...getPropertyValue(serviceData, "Allowed ports").split(",")
                .map((p) => { return p.trim() })
                .filter((p) => { return parseInt(p) }))
        }
    }



    pushPortsToArray()
    pushCustomPortsToArray()

    portsArray.length > 0 ? args.push({
        name: "dynamic \"ingress\"",
        condition: true,
        value: [
            {
                name: "for_each",
                condition: true,
                dependsOnProperties: ["Allow inbound traffic"],
                value: "[" + portsArray.join(", ") + "]"
            },
            {
                name: "content",
                condition: true,
                value: [
                    {
                        name: "from_port",
                        condition: true,
                        value: "ingress.value"
                    },
                    {
                        name: "to_port",
                        condition: true,
                        value: "ingress.value"
                    },
                    {
                        name: "protocol",
                        condition: true,
                        value: addQuotes("tcp")
                    },
                    {
                        name: "cidr_blocks",
                        condition: true,
                        value: "[\"0.0.0.0/0\"]"
                    }
                ]
            },
        ]
    }) : args.push({
        name: "ingress",
        condition: true,
        value: "[]",
        dependsOnProperties: ["Allow inbound traffic"],
    })
    args.push({
        name: "egress",
        condition: true,
        value: [
            {
                name: "from_port",
                condition: true,
                value: "0"
            },
            {
                name: "to_port",
                condition: true,
                value: "0"
            },
            {
                name: "protocol",
                condition: true,
                value: addQuotes("-1")
            },
            {
                name: "cidr_blocks",
                condition: true,
                value: "[\"0.0.0.0/0\"]"
            }
        ],
    })

    getParentByServiceName(serviceData, "VPC") !== undefined && args.push({
        name: "vpc_id",
        condition: true,
        value: "aws_vpc.vpc.id",
        dependsOnProperties: ["parent." + getParentShapeId(serviceData, "VPC") + ".Address range"]
    })
    return {
        components: [
            {
                component: "resource",
                type: "aws_security_group",
                componentName: getPropertyValue(serviceData, "Name"),
                condition: true,
                dependsOnProperties: ["Name"],
                arguments: args
            }
        ]
    }
}