import { get } from "http";
import { Component, Service, ServiceData, ServiceTranslation, addQuotes, getParentShapeId, getPropertyFromArrowIn, getPropertyValue } from "../../Service";
import { validateService } from "../validators/generalValidator";
import { validateRDSInstanceIdentifier } from "../validators/rdsValidator";
import { validateS3DaysNumber, validateS3Name } from "../validators/s3Validator";
import { getSubnetCount, getSubnetsByType, hasPublicAndPrivateSubnets } from "./vpc";

const defaultName = "rds"

const engines = [
    "Aurora (MySQL compatible)",
    "Aurora (PostgreSQL compatible)",
    "MySQL",
    "MariaDB",
    "PostgreSQL",
    "Oracle",
    "Microsoft SQL Server"
]

const getEngine = (engineName: string) => {
    let engineType = '';

    switch (engineName) {
        case "Aurora (MySQL compatible)":
            engineType = "aurora";
            break;
        case "Aurora (PostgreSQL compatible)":
            engineType = "aurora-postgresql";
            break;
        case "MySQL":
            engineType = "mysql";
            break;
        case "MariaDB":
            engineType = "mariadb";
            break;
        case "PostgreSQL":
            engineType = "postgres";
            break;
        case "Oracle":
            engineType = "oracle-se2";
            break;
        case "Microsoft SQL Server":
            engineType = "sqlserver-se";
            break;
        default:
            engineType = "unknown";
    }

    return engineType;
}

const getEngineVersion = (engineName: string) => {
    let engineVersion = '';

    switch (engineName) {
        case "Aurora (MySQL compatible)":
            engineVersion = "8.0.mysql_aurora.3.02.0";
            break;
        case "Aurora (PostgreSQL compatible)":
            engineVersion = "13.6";
            break;
        case "MySQL":
            engineVersion = "8.0.33";
            break;
        case "MariaDB":
            engineVersion = "10.6.14";
            break;
        case "PostgreSQL":
            engineVersion = "15.3";
            break;
        case "Oracle":
            engineVersion = "19.0.0.0.ru-2023-07.rur-2023-07.r1"; // oracle-se2 version as an example
            break;
        case "Microsoft SQL Server":
            engineVersion = "15.00.4322.2.v1";
            break;
        default:
            engineVersion = "unknown";
    }

    return engineVersion;
}

const getDefaultPort = (engineName: string) => {
    switch (engineName) {
        case "Microsoft SQL Server":
            return "1433"
        case "PostgreSQL":
            return "5432"
        case "Aurora (PostgreSQL compatible)":
            return "5432"
        case "Oracle":
            return "1521"
        default:
            return "3306"
    }
}

const getInstanceType = (engineName: string, usageType: string) => {
    const mapping = {
        "Aurora (MySQL compatible)": {
            "Production": { instance: "db.r6g.2xlarge", vCPUs: 8, RAM: "64 GiB" },
            "Dev/test": { instance: "db.r6g.large", vCPUs: 2, RAM: "16 GiB" }
        },
        "Aurora (PostgreSQL compatible)": {
            "Production": { instance: "db.r6g.2xlarge", vCPUs: 8, RAM: "64 GiB" },
            "Dev/test": { instance: "db.t4g.large", vCPUs: 2, RAM: "8 GiB" }
        },
        "MySQL": {
            "Production": { instance: "db.r6g.xlarge", vCPUs: 4, RAM: "32 GiB" },
            "Dev/test": { instance: "db.r6g.large", vCPUs: 2, RAM: "16 GiB" },
            "Free tier": { instance: "db.t3.micro", vCPUs: 2, RAM: "1 GiB" }
        },
        "MariaDB": {
            "Production": { instance: "db.r6g.xlarge", vCPUs: 4, RAM: "32 GiB" },
            "Dev/test": { instance: "db.r6g.large", vCPUs: 2, RAM: "16 GiB" },
            "Free tier": { instance: "db.t3.micro", vCPUs: 2, RAM: "1 GiB" }
        },
        "PostgreSQL": {
            "Production": { instance: "db.r6g.xlarge", vCPUs: 4, RAM: "32 GiB" },
            "Dev/test": { instance: "db.r6g.large", vCPUs: 2, RAM: "16 GiB" },
            "Free tier": { instance: "db.t3.micro", vCPUs: 2, RAM: "1 GiB" }
        },
        "Oracle": {
            "Production": { instance: "db.r5.large", vCPUs: 2, RAM: "16 GiB" },
            "Dev/test": { instance: "db.m5.large", vCPUs: 2, RAM: "8 GiB" },
        },
        "Microsoft SQL Server": {
            "Production": { instance: "db.r5.xlarge", vCPUs: 4, RAM: "32 GiB" },
            "Dev/test": { instance: "db.m5.large", vCPUs: 2, RAM: "8 GiB" },
            "Free tier": { instance: "db.t2.micro", vCPUs: 1, RAM: "1 GiB" }
        }
    } as any;

    return mapping[engineName][usageType];
}

export const rds: Service = {
    serviceName: "RDS Instance",
    defaultName: defaultName,
    imgUrl: "img/RDS.png",
    category: "Database",
    filePath: "/root/modules/inkdrop/main.tf",
    validate: (serviceData: ServiceData) => validateService(serviceData),
    borderColor: "#4155D6",
    backgroundColor: "#d9ddf6",
    isWrapper: false,
    arrowInTypes: ["Interact"],
    arrowOutTypes: ["SNS Notification", "Policy Restriction"],
    parentsTypes: ["VPC", "Security Group"],
    properties: [
        {
            name: "Engine type",
            condition: () => { return true },
            docsUrl: "",
            tfDocsUrl: "",
            defaultValue: "PostgreSQL",
            choices: engines,
            type: "string",
            mandatory: true
        },
        {
            name: "DB instance size",
            condition: (serviceData: ServiceData) => {
                return ["Aurora (MySQL compatible)",
                    "Aurora (PostgreSQL compatible)",
                    "Oracle"].indexOf(getPropertyValue(serviceData, "Engine type")) === -1
            },
            docsUrl: "",
            tfDocsUrl: "",
            defaultValue: "Free tier",
            choices: ["Free tier", "Dev/test", "Production"],
            type: "string",
            mandatory: true
        },
        {
            name: "DB instance size (no free tier)",
            condition: (serviceData: ServiceData) => {
                return ["Aurora (MySQL compatible)",
                    "Aurora (PostgreSQL compatible)",
                    "Oracle"].indexOf(getPropertyValue(serviceData, "Engine type")) !== -1
            },
            docsUrl: "",
            tfDocsUrl: "",
            defaultValue: "Dev/test",
            choices: ["Dev/test", "Production"],
            type: "string",
            mandatory: true
        },
        {
            name: "Enable backups",
            condition: () => { return true },
            docsUrl: "",
            tfDocsUrl: "",
            defaultValue: "Yes",
            choices: ["Yes", "No"],
            type: "string",
            mandatory: true
        },
        {
            name: "Database subnet location",
            condition: (serviceData: ServiceData) => {
                return hasPublicAndPrivateSubnets(serviceData)
            },
            docsUrl: "",
            tfDocsUrl: "",
            defaultValue: "Public subnets",
            choices: ["Public subnets", "Private subnets"],
            type: "string",
            mandatory: true
        },
        {
            name: "Name",
            condition: () => { return true },
            validate: (serviceData: ServiceData) => validateRDSInstanceIdentifier(serviceData.properties.filter((p) => {
                return p.name === "Name"
            })[0].value as string),
            docsUrl: "",
            tfDocsUrl: "",
            isName: true,
            defaultValue: defaultName,
            type: "string",
            mandatory: true,
        },
    ],
}


export const rdsServiceTranslation = (serviceData: ServiceData): ServiceTranslation => {
    const dbArgs = [
        {
            name: "allocated_storage",
            condition: true,
            value: ["Aurora (MySQL compatible)",
                "Aurora (PostgreSQL compatible)",
                "Oracle"].indexOf(getPropertyValue(serviceData, "Engine type")) === -1 ? getPropertyValue(serviceData, "DB instance size") === "Free tier" ? "20" :
                getPropertyValue(serviceData, "DB instance size") === "Dev/test" ? "100" : "500" : getPropertyValue(serviceData, "DB instance size (no free tier)") === "Dev/test" ? "100" : "500",
            dependsOnProperties: ["DB instance size", "Engine type", "DB instance size (no free tier)"],
        },
        {
            name: "instance_class",
            condition: true,
            value: addQuotes(getInstanceType(getPropertyValue(serviceData, "Engine type"), ["Aurora (MySQL compatible)",
                "Aurora (PostgreSQL compatible)",
                "Oracle"].indexOf(getPropertyValue(serviceData, "Engine type")) === -1 ? getPropertyValue(serviceData, "DB instance size") :
                getPropertyValue(serviceData, "DB instance size (no free tier)")).instance),
            dependsOnProperties: ["DB instance size", "DB instance size (no free tier)", "Engine type"],
        },
        {
            name: "db_name",
            condition: true,
            value: addQuotes(getPropertyValue(serviceData, "Name")),
            dependsOnProperties: ["Name"],
        },
        {
            name: "engine",
            condition: true,
            value: addQuotes(getEngine(getPropertyValue(serviceData, "Engine type"))),
            dependsOnProperties: ["Engine type"],
        },
        {
            name: "engine_version",
            condition: true,
            value: addQuotes(getEngineVersion(getPropertyValue(serviceData, "Engine type"))),
            dependsOnProperties: ["Engine type"],
        },
        {
            name: "port",
            condition: true,
            value: getDefaultPort(getPropertyValue(serviceData, "Engine type")),
            dependsOnProperties: ["Engine type"],
        },
        {
            name: "username",
            condition: true,
            value: addQuotes("testuser"),
        },
        {
            name: "password",
            condition: true,
            value: addQuotes("testpassword"),
        },
        {
            name: "storage_encrypted",
            condition: true,
            value: "true",
        },
        {
            name: "backup_retention_period",
            condition: true,
            value: getPropertyValue(serviceData, "Enable backups") === "Yes" ? "7" : "0",
            dependsOnProperties: ["Enable backups"]
        },
        {
            name: "backup_window",
            condition: getPropertyValue(serviceData, "Enable backups") === "Yes",
            value: addQuotes("03:00-05:00"),
            dependsOnProperties: ["Enable backups"]
        },
        {
            name: "publicly_accessible",
            condition: true,
            value: "false",
        },
        {
            name: "performance_insights_enabled",
            condition: true,
            value: "true",
        },
        {
            name: "deletion_protection",
            condition: true,
            value: "false",
        },
        {
            name: "final_snapshot_identifier",
            condition: true,
            value: addQuotes(getPropertyValue(serviceData, "Name") + "-final-snapshot"),
            dependsOnProperties: ["Name"]
        },
    ]

    serviceData.parents.some((p) => {
        return p.serviceData.serviceName === "Security Group"
    }) && dbArgs.push({
        name: "vpc_security_group_ids",
        condition: true,
        value: "[" + serviceData.parents.filter((p) => {
            return p.serviceData.serviceName === "Security Group"
        }).map(p => {
            return "aws_security_group." + getPropertyValue(p.serviceData, "Name") + ".id"
        }).join(", ") + "]",
        dependsOnProperties: serviceData.parents.filter((p) => {
            return p.serviceData.serviceName === "Security Group"
        }).map(p => { return "parent." + p.shapeId + ".Name" })
    },)

    serviceData.parents.some((p) => {
        return p.serviceData.serviceName === "VPC"
    }) && dbArgs.push({
        name: "db_subnet_group_name",
        condition: serviceData.parents.some((p) => {
            return p.serviceData.serviceName === "VPC"
        }),
        value: addQuotes("db_subnet_group_" + getPropertyValue(serviceData, "Name")),
        dependsOnProperties: ["parent." + getParentShapeId(serviceData, "VPC") + ".Name", "Name"]
    },
        {
            name: "depends_on",
            condition: serviceData.parents.some((p) => {
                return p.serviceData.serviceName === "VPC"
            }),
            value: "[aws_db_subnet_group." + "db_subnet_group_" + getPropertyValue(serviceData, "Name") + "]",
            dependsOnProperties: ["parent." + getParentShapeId(serviceData, "VPC") + ".Name", "Name"]
        })

    return {
        components: [
            {
                component: "resource",
                type: "aws_db_instance",
                componentName: getPropertyValue(serviceData, "Name"),
                dependsOnProperties: ["Name"],
                condition: true,
                arguments: dbArgs
            },
            ...(
                serviceData.parents.some((p) => {
                    return p.serviceData.serviceName === "VPC"
                }) ? [{
                    component: "resource",
                    type: "aws_db_subnet_group",
                    componentName: "db_subnet_group_" + getPropertyValue(serviceData, "Name"),
                    dependsOnProperties: ["Name"],
                    condition: true,
                    arguments: [
                        {
                            name: "name",
                            condition: true,
                            value: addQuotes("db_subnet_group_" + getPropertyValue(serviceData, "Name")),
                            dependsOnProperties: ["Name"]
                        },
                        {
                            name: "subnet_ids",
                            condition: true,
                            value: "[" + getSubnetsByType(serviceData.parents.filter((p) => {
                                return p.serviceData.serviceName === "VPC"
                            })[0].serviceData, getPropertyValue(serviceData, "Database subnet location") as "Private subnets" | "Public subnets"
                            ).join(", ") + "]",
                            dependsOnProperties: ["parent." + getParentShapeId(serviceData, "VPC") + ".Number of public subnets",
                            "parent." + getParentShapeId(serviceData, "VPC") + ".Number of private subnets",
                            "parent." + getParentShapeId(serviceData, "VPC") + ".Number of availability zones",
                            "parent." + getParentShapeId(serviceData, "VPC") + ".Name",
                                "Database subnet location"]
                        },
                    ]
                } as Component] : []
            ),
            ...(serviceData.arrowOut.filter((a) => {
                return a.arrowData.type === "SNS Notification"
            }).map((arrow, index) => {
                return {
                    component: "resource",
                    type: "aws_db_event_subscription",
                    componentName: "event_sub_" + getPropertyValue(serviceData, "Name") + "_" + index,
                    condition: true,
                    dependsOnProperties: ["serviceOut." + arrow.arrowData.endId + ".Name", "Name"],
                    arguments: [
                        {
                            name: "name",
                            condition: true,
                            value: addQuotes("event_sub_" + getPropertyValue(serviceData, "Name") + "_" + index),
                            dependsOnProperties: ["Name"]
                        },
                        {
                            name: "sns_topic",
                            condition: true,
                            value: "aws_sns_topic." + getPropertyValue(arrow.serviceData, "Name") + ".arn",
                            dependsOnProperties: ["serviceOut." + arrow.arrowData.endId + ".Name"]
                        },
                        {
                            name: "source_type",
                            condition: true,
                            value: addQuotes("db-instance"),
                        },
                        {
                            name: "source_ids",
                            condition: true,
                            value: "[aws_db_instance." + getPropertyValue(serviceData, "Name") + ".identifier]",
                            dependsOnProperties: ["Name"]
                        },
                        {
                            name: "event_categories",
                            condition: true,
                            value: "[" + addQuotes((arrow.arrowData.properties.filter((p) => {
                                return p.name === "Send notification on"
                            })[0].value as string).toLowerCase()) + "]",
                            dependsOnProperties: ["arrowOut." + arrow.arrowData.shapeId + ".Send notification on"]
                        },
                    ]
                } as Component
            }))
        ]
    }
}
export { }