import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress } from "@mui/material";
import { initializeFirebase } from "./utils/firebase";
import {
    GoogleAuthProvider,
    getAuth,
    signInWithPopup, Auth,
    onAuthStateChanged
} from "firebase/auth";
import GoogleButton from 'react-google-button'
import Main from './main/Main';
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import CodeManager from './code/code-editor/CodeManager';
import Settings from './settings/Settings';

const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    let auth: Auth, googleProvider: GoogleAuthProvider
    const app = initializeFirebase();
    googleProvider = new GoogleAuthProvider();
    auth = getAuth(app);


    onAuthStateChanged(auth, (user) => {
        setIsLoading(false);
        setIsAuthenticated(user ? true : false);
    });

    const signInWithGoogle = async () => {
        const res = await signInWithPopup(auth, googleProvider);
        if (res.user) {
            setIsAuthenticated(true);
        }
    };
    return (
        <div className="absolute h-full w-full">
            {isLoading ? <CircularProgress style={{
                margin: 0,
                position: "relative",
                top: "50%",
                left: "50%",
                transform: "translate(0, -50%)"
            }} /> :
                <>
                    {
                        isAuthenticated ?
                            <Router>
                                <Routes>
                                    <Route path="/" element={<Main app={app} />} />
                                    <Route path="/settings" element={<Settings app={app} />} />
                                </Routes>
                            </Router>
                            :
                            <div style={{
                                margin: 0,
                                position: "relative",
                                left: "50%",
                                top: "50%",
                                transform: "translate(-50%, -50%)"
                            }}>
                                <Box
                                    component="img"
                                    sx={{
                                        position: "relative",
                                        left: "50%",
                                        top: "50%",
                                        transform: "translate(-50%, -50%)",
                                        height: "60px",
                                        marginBottom: "10px",
                                    }}
                                    alt="Inkdrop logo"
                                    src={"logo_long.png"}
                                />
                                <GoogleButton
                                    type={"light"}
                                    style={{
                                        margin: 0,
                                        position: "relative",
                                        left: "50%",
                                        top: "50%",
                                        transform: "translate(-50%, -50%)"
                                    }}
                                    onClick={() => {
                                        signInWithGoogle()
                                    }} />
                            </div>
                    }
                </>
            }
        </div>
    );
}

export default App;