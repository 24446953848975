import { Argument, Component, Service, ServiceData, ServiceTranslation, addQuotes, addSquareBrackets, getObjectFormat, getObjectPropertyValue, getParentByServiceName, getParentShapeId, getPropertyFromArrowIn, getPropertyValue, hasArrowTypeIn, removeQuotes, services, stringToObject, updatePropertyValue } from "../../Service";
import { validateDynamoDBAttributes, validateDynamoDBKey, validateDynamoDBTableName } from "../validators/dynamoDBValidator";
import { validateService } from "../validators/generalValidator";

const defaultName = "dynamodb-table"

export const dynamoDBTable: Service = {
    serviceName: "DynamoDB Table",
    defaultName: defaultName,
    borderColor: "#4053D5",
    backgroundColor: "#d8dcf6",
    category: "Database",
    parentsTypes: [],
    validate: (serviceData: ServiceData) => {
        const { isValid, messages } = validateService(serviceData)
        const finalMessages: string[] = []
        messages.forEach((m) => {
            const split = m.split(": ")
            const finalMessage = split.length > 1 ? split.slice(1).join(": ") : split[0]
            if (finalMessages.indexOf(finalMessage) === -1) finalMessages.push(finalMessage)
        })
        return { isValid, messages: finalMessages }
    },
    isWrapper: false,
    filePath: "/root/modules/inkdrop/main.tf",
    imgUrl: "img/DynamoDB.png",
    arrowInTypes: ["Interact", "API Integration"],
    arrowOutTypes: ["Invoke Function", "Policy Restriction"],
    properties: [
        {
            name: "Partition key",
            condition: () => { return true },
            validate: (serviceData: ServiceData) => validateDynamoDBKey(serviceData.properties.filter(p => {
                return p.name === "Partition key"
            })[0].value as any, serviceData.properties.filter(p => {
                return p.name === "Attributes"
            })[0].value as any),
            docsUrl: "",
            tfDocsUrl: "https://registry.terraform.io/providers/hashicorp/aws/latest/docs/resources/dynamodb_table#attribute",
            databaseKey: true,
            defaultValue: {
                name: "",
                type: "string",
            },
            type: "Object",
            mandatory: true,
        },
        {
            name: "Attributes",
            condition: () => { return true },
            validate: (serviceData: ServiceData) => validateDynamoDBAttributes(serviceData.properties.filter(p => {
                return p.name === "Attributes"
            })[0].value as any, serviceData.properties.filter(p => {
                return p.name === "Partition key"
            })[0].value as any),
            docsUrl: "",
            tfDocsUrl: "https://registry.terraform.io/providers/hashicorp/aws/latest/docs/resources/dynamodb_table#attribute",
            databaseEntry: true,
            defaultValue: [{
                name: "",
                type: "string",
            }],
            type: "Object[]",
            mandatory: true,
        },
        {
            name: "Name",
            condition: () => { return true },
            validate: (serviceData: ServiceData) => validateDynamoDBTableName(serviceData.properties.filter(p => {
                return p.name === "Name"
            })[0].value as string),
            docsUrl: "",
            tfDocsUrl: "",
            isName: true,
            defaultValue: defaultName,
            type: "string",
            mandatory: true,
        },
    ],
}

export const dynamoDBTableServiceTranslation = (serviceData: ServiceData): ServiceTranslation => {

    const args = [
        {
            name: "name",
            condition: true,
            dependsOnProperties: ["Name"],
            value: addQuotes(getPropertyValue(serviceData, "Name")),
        },
        {
            name: "billing_mode",
            condition: true,
            value: addQuotes("PROVISIONED"),
        },
        {
            name: "table_class",
            condition: true,
            value: addQuotes("STANDARD"),
        },
        {
            name: "read_capacity",
            condition: true,
            value: "10",
        },
        {
            name: "write_capacity",
            condition: true,
            value: "10",
        },
        {
            name: "hash_key",
            condition: true,
            value: addQuotes((getObjectPropertyValue(serviceData, "Partition key") as any).name),
            dependsOnProperties: ["Partition key"],
        },
        {
            name: "dynamic \"attribute\"",
            condition: true,
            dependsOnProperties: ["Attributes", "Partition key"],
            value: [
                {
                    name: "for_each",
                    condition: true,
                    dependsOnProperties: ["Attributes", "Partition key"],
                    value: "[\n" +
                        "{\n" +
                        "name = " + addQuotes((getObjectPropertyValue(serviceData, "Partition key") as any).name) + "\n" +
                        "type = " + addQuotes(((getObjectPropertyValue(serviceData, "Partition key") as any).type as string).charAt(0).toUpperCase()) + "\n},\n" +
                        (getObjectPropertyValue(serviceData, "Attributes") as any[]).filter((a) => {
                            return a.name !== ""
                        }).map((a) => {
                            return "{\n" +
                                "name = " + addQuotes(a.name) + "\n" +
                                "type = " + addQuotes((a.type as string).charAt(0).toUpperCase()) + "\n},"
                        }).join("\n") + "\n]"
                },
                {
                    name: "content",
                    condition: true,
                    value: [
                        {
                            name: "name",
                            condition: true,
                            value: "attribute.value[\"name\"]"
                        },
                        {
                            name: "type",
                            condition: true,
                            value: "attribute.value[\"type\"]"
                        },
                    ]
                },
            ]
        }
    ]

    if (serviceData.arrowOut.some((arrow) => {
        return arrow.arrowData.type === "Invoke Function"
    })) {
        args.push({
            name: "stream_enabled",
            condition: true,
            value: "true",
            dependsOnProperties: serviceData.arrowOut.filter((a) => {
                return a.arrowData.type === "Invoke Function"
            }).map((a) => {
                return "serviceOut." + a.arrowData.endId + ".Name"
            })
        })
        args.push({
            name: "stream_view_type",
            condition: true,
            value: addQuotes("NEW_AND_OLD_IMAGES"),
            dependsOnProperties: serviceData.arrowOut.filter((a) => {
                return a.arrowData.type === "Invoke Function"
            }).map((a) => {
                return "serviceOut." + a.arrowData.endId + ".Name"
            })
        })
    }

    return {
        components: [
            {
                component: "resource",
                type: "aws_dynamodb_table",
                componentName: getPropertyValue(serviceData, "Name"),
                dependsOnProperties: ["Name"],
                condition: true,
                arguments: args
            },
        ]
    }
}