import { s3Invoke } from "./aws/connectors/s3Trigger"
import { policy } from "./aws/connectors/policy"
import { ec2, ec2ServiceTranslation } from "./aws/services/ec2"
import { iamPolicy, iamPolicyServiceTranslation } from "./aws/services/iamPolicy"
import { iamRole, iamRoleServiceTranslation } from "./aws/services/iamRole"
import { lambda, lambdaServiceTranslation } from "./aws/services/lambda"
import { s3, s3ServiceTranslation } from "./aws/services/s3"
import { securityGroup, securityGroupServiceTranslation } from "./aws/services/securityGroup"
import { subnet, subnetServiceTranslation } from "./aws/services/subnet"
import { vpc, vpcServiceTranslation } from "./aws/services/vpc"
import { sqs, sqsServiceTranslation } from "./aws/services/sqs"
import { dynamoDBTable, dynamoDBTableServiceTranslation } from "./aws/services/dynamoDBTable"
import { s3Message } from "./aws/connectors/s3Message"
import { lambdaToDynamoDBInteract, lambdaToLambdaInteract, lambdaToRDSInteract, lambdaToS3Interact, lambdaToSNSInteract, lambdaToSQSInteract } from "./aws/connectors/interact/lambdaInteract"
import { sns, snsServiceTranslation } from "./aws/services/sns"
import { s3Notification } from "./aws/connectors/s3Notification"
import { rds, rdsServiceTranslation } from "./aws/services/rds"
import { rdsNotification } from "./aws/connectors/rdsNotification"
import { apiGateway, apiGatewayServiceTranslation } from "./aws/services/apiGateway"
import { apiToDynamoDBIntegration, apiToLambdaIntegration, apiToS3Integration, apiToSNSIntegration, apiToSQSIntegration } from "./aws/connectors/apiIntegration"
import { cognito, cognitoServiceTranslation } from "./aws/services/cognito"
import { cognitoInvoke } from "./aws/connectors/cognitoInvoke"
import { ecsCluster, ecsClusterServiceTranslation } from "./aws/services/ecsCluster"
import { ecsService, ecsServiceServiceTranslation } from "./aws/services/ecsService"

export type Service = {
    serviceName: string
    defaultName: string
    imgUrl: string
    isWrapper: boolean,
    category: string,
    filePath: string,
    borderColor: string
    backgroundColor: string
    arrowInTypes: string[],
    arrowOutTypes: string[]
    parentsTypes: string[]
    properties: ServiceProperty[]
    validate: (serviceData: ServiceData) => { isValid: boolean, messages: string[] },
}

type ServiceProperty = {
    name: string,
    docsUrl: string,
    tfDocsUrl: string,
    isName?: boolean,
    condition: (serviceData: ServiceData) => boolean,
    validate?: (serviceData: ServiceData) => { isValid: boolean, messages: string[] },
    isDockerImage?: boolean,
    defaultValue: Object | Object[],
    advanced?: boolean
    choices?: string[] | number[]
    type: "string" | "number" | "Object" | "Object[]" | "array"
    multipleChoice?: boolean,
    databaseEntry?: boolean
    containerDefinition?: boolean,
    databaseKey?: boolean
    mandatory: boolean
    hide?: boolean
}

export type ServicePropertyData = {
    name: string,
    type: "string" | "number" | "Object" | "Object[]" | "array"
    condition: (serviceData: ServiceData) => boolean,
    validate?: (serviceData: ServiceData) => { isValid: boolean, messages: string[] },
    isDockerImage?: boolean,
    isName?: boolean,
    value: Object | Object[],
    databaseEntry?: boolean
    databaseKey?: boolean
    containerDefinition?: boolean,
    multipleChoice?: boolean,
    advanced?: boolean
    defaultValue: Object,
    choices?: string[] | number[]
    mandatory: boolean
    hide?: boolean
}

export type ServiceData = {
    serviceName: string
    name: string
    isWrapper: boolean
    filePath: string,
    validate: (serviceData: ServiceData) => { isValid: boolean, messages: string[] },
    properties: ServicePropertyData[],
    arrowInTypes: string[],
    arrowOutTypes: string[]
    arrowIn: {
        serviceData: ServiceData
        arrowData: ArrowData
    }[],
    arrowOut: {
        serviceData: ServiceData
        arrowData: ArrowData
    }[],
    parents: {
        serviceData: ServiceData,
        shapeId: string
    }[],
    parentsTypes: string[]
}

export type ArrowPropertyData = {
    name: string,
    condition: (arrowData: ArrowData) => boolean,
    value: Object
    defaultValue: Object,
    multipleChoice?: boolean,
    choices?: string[] | number[]
    type: "string" | "number" | "Object" | "array"
    mandatory: boolean
    hide?: boolean
}

export type ArrowPropertyDefinition = {
    name: string,
    condition: (arrowData: ArrowData) => boolean,
    defaultValue: Object,
    choices?: string[] | number[]
    multipleChoice?: boolean,
    type: "string" | "number" | "Object" | "array"
    mandatory: boolean
    hide?: boolean
}

export type ArrowData = {
    type: string
    shapeId: string
    startId: string
    endId: string
    properties: ArrowPropertyData[]
}

export type ArrowDefinition = {
    type: string
    fromType: string
    toType: string
    properties: ArrowPropertyDefinition[]
}

export type Argument = {
    name: string,
    value: string | Argument[],
    dependsOnProperties?: string[]
    condition: boolean
}

export type Component = {
    component: "data" | "resource"
    type: string
    dependsOnProperties?: string[]
    componentName: string
    condition: boolean
    arguments: Argument[]
}

export type ServiceTranslation = {
    components: Component[]
}

export const getPropertyValue = (serviceData: ServiceData, propertyName: string) => {
    return serviceData.properties.filter((p) => { return p.name === propertyName })[0]!.value.toString()
}

export const getObjectPropertyValue = (serviceData: ServiceData, propertyName: string) => {
    return serviceData.properties.filter((p) => { return p.name === propertyName })[0]!.value
}

export const addQuotes = (old: string) => {
    return `"${old}"`
}
export const addSquareBrackets = (old: string) => {
    return `[${old}]`
}
export const getPropertyDefault = (serviceData: ServiceData, propertyName: string) => {
    return serviceData.properties.filter((p) => { return p.name === propertyName })[0]!.defaultValue
}

export const updatePropertyValue = (serviceData: ServiceData, propertyName: string, newValue: string) => {
    return serviceData.properties.filter((p) => { return p.name === propertyName })[0]!.value = unescapeCharacters(newValue)
}

export const stringToObject = (input: string) => {// If the input is a null value, return an empty object
    // If the input is a null or undefined value, return an empty object
    if (input === null || input === undefined) {
        return {};
    }

    if (input.replace(/{ ([^\$ ]+ \$SIGN_EQUALS_TO\$ [^\$ ]+ )+}/g, "")) return {}

    let object = {} as any

    const values = input.split(RegExp(/ \$SIGN_EQUALS_TO\$ | /g)).slice(1, -1)
    values.forEach((v, index) => {
        if (index % 2 === 0) {
            object[v] = removeQuotes(values[index + 1])
        }
    })

    return object
}

export const escapeCharacters = (str: any) => {
    return typeof str === "string" ? str.replace(/([\\\"'\[\]{}<>=,.])/g, "\\$1") : str
}

export const unescapeCharacters = (str: any) => {
    return typeof str === "string" ? str.replace(/\\([\\\"'\[\]{}<>=,.])/g, "$1") : str
}

export const removeQuotes = (old: string) => {
    return old.replace(/^['"](.*)["']$/g, "$1");
}

export const getObjectFormat = (object: Object) => {
    return `{\n${Object.entries(object).map((line) => {
        return line[0] + " = " + addQuotes(line[1])
    }).join("\n")}\n}`
}

export const hasArrowTypeIn = (serviceData: ServiceData, arrowType: string) => {
    return serviceData.arrowIn.some((a) => { return a.arrowData.type === arrowType })
}

export const getPropertyFromArrowIn = (serviceData: ServiceData, arrowType: string, property: string) => {
    const arrow = serviceData.arrowIn.filter((a) => { return a.arrowData.type === arrowType })
    return arrow.length > 0 ? escapeCharacters(arrow[0].arrowData.properties.filter((p) => { return p.name === property })[0].value) : ""
}

export const hasArrowTypeOut = (serviceData: ServiceData, arrowType: string) => {
    return serviceData.arrowOut.some((a) => { return a.arrowData.type === arrowType })
}

export const getParentByServiceName = (serviceData: ServiceData, serviceName: string) => {
    const filtered = serviceData.parents.filter((p) => {
        return p.serviceData.serviceName === serviceName
    })
    return filtered.length > 0 ? filtered[0].serviceData : undefined
}

export const getParentShapeId = (serviceData: ServiceData, serviceName: string) => {
    const filtered = serviceData.parents.filter((p) => {
        return p.serviceData.serviceName === serviceName
    })
    return filtered.length > 0 ? filtered[0].shapeId : undefined
}
export const getParentsWithType = (serviceData: ServiceData, serviceName: string) => {
    const filtered = serviceData.parents.filter((p) => {
        return p.serviceData.serviceName === serviceName
    })
    return filtered
}

export const arrowsProperties: ArrowDefinition[] = [s3Invoke, s3Message, s3Notification,
    lambdaToRDSInteract, rdsNotification, policy, lambdaToSNSInteract, lambdaToDynamoDBInteract,
    lambdaToLambdaInteract, lambdaToS3Interact, lambdaToSQSInteract, apiToDynamoDBIntegration,
    apiToLambdaIntegration, apiToS3Integration, apiToSNSIntegration, apiToSQSIntegration, cognitoInvoke]
export const services = [lambda, s3, sqs, dynamoDBTable, sns, iamRole, iamPolicy, rds, securityGroup, apiGateway, cognito, vpc, ecsCluster, ecsService]
export const getServiceTranslation = (serviceData: ServiceData) => {
    switch (serviceData.serviceName) {
        case "S3 Bucket":
            return s3ServiceTranslation(serviceData)
        case "Lambda":
            return lambdaServiceTranslation(serviceData)
        case "IAM Policy":
            return iamPolicyServiceTranslation(serviceData)
        case "IAM Role":
            return iamRoleServiceTranslation(serviceData)
        case "SQS Queue":
            return sqsServiceTranslation(serviceData)
        case "SNS Topic":
            return snsServiceTranslation(serviceData)
        case "RDS Instance":
            return rdsServiceTranslation(serviceData)
        case "DynamoDB Table":
            return dynamoDBTableServiceTranslation(serviceData)
        case "API Gateway":
            return apiGatewayServiceTranslation(serviceData)
        case "VPC":
            return vpcServiceTranslation(serviceData)
        case "Subnet":
            return subnetServiceTranslation(serviceData)
        case "Security Group":
            return securityGroupServiceTranslation(serviceData)
        case "ECS Cluster":
            return ecsClusterServiceTranslation(serviceData)
        case "ECS Service":
            return ecsServiceServiceTranslation(serviceData)
        case "EC2":
            return ec2ServiceTranslation(serviceData)
        case "Cognito User Pool":
            return cognitoServiceTranslation(serviceData)
    }
    return lambdaServiceTranslation(serviceData)
}