import { Component, Service, ServiceData, ServiceTranslation, addQuotes, getPropertyValue } from "../../Service";
import { validateService } from "../validators/generalValidator";
import { validateS3DaysNumber, validateS3Name } from "../validators/s3Validator";

const defaultName = "api-gateway"

function generateApiGatewayServiceUri(region: string, service: string, resourceName: string, extraOptions?: any) {
    let serviceLower = service.toLowerCase().replace(/\s/g, '_');
    let uri;

    switch (serviceLower) {
        case "lambda":
            uri = `arn:aws:apigateway:${region}:lambda:path/2015-03-31/functions/arn:aws:lambda:${region}:${resourceName}/invocations`;
            break;
        case "s3_bucket":
            uri = `arn:aws:apigateway:${region}:s3:path/${resourceName}/{proxy+}`;
            break;
        case "dynamodb_table":
            const action = extraOptions && extraOptions.action ? extraOptions.action : 'PutItem';
            uri = `arn:aws:apigateway:${region}:dynamodb:action/${action}`;
            break;
        case "sqs_queue":
            uri = `arn:aws:apigateway:${region}:sqs:path/${resourceName}`;
            break;
        case "sns_topic":
            uri = `arn:aws:apigateway:${region}:sns:path/${resourceName}`;
            break;
        default:
            throw new Error(`Unsupported service: ${service}`);
    }

    return uri;
}

const getPolicyResource = (serviceData: ServiceData) => {
    switch (serviceData.serviceName) {
        case "S3 Bucket":
            return "aws_s3_bucket." + getPropertyValue(serviceData, "Name") + ".arn"
        case "SQS Queue":
            return "aws_sqs_queue." + getPropertyValue(serviceData, "Name") + ".arn"
        case "DynamoDB Table":
            return "aws_dynamodb_table." + getPropertyValue(serviceData, "Name") + ".arn"
        case "Lambda":
            return "aws_lambda_function." + getPropertyValue(serviceData, "Name") + ".arn"
        case "SNS Topic":
            return "aws_sns_topic." + getPropertyValue(serviceData, "Name") + ".arn"
    }
}

const getDynamoAction = (method: string) => {
    switch (method) {
        case "GET":
            return "GetItem";
        case "POST":
            return "PutItem";
        case "PUT":
            return "UpdateItem";
        case "DELETE":
            return "DeleteItem";
        default:
            return "PutItem";
    }
}

const getPolicyActions = (service: string, methods: string[]) => {
    const actions: string[] = []
    switch (service) {
        case "Lambda":
            actions.push("lambda:InvokeFunction")
            break
        case "S3 Bucket":
            methods.forEach((m) => {
                switch (m) {
                    case "GET":
                        actions.push("s3:GetObject")
                        break
                    case "PUT":
                        actions.push("s3:PutObject")
                        break
                    case "DELETE":
                        actions.push("s3:DeleteObject")
                        break
                    case "HEAD":
                        actions.push("s3:HeadObject")
                        break
                }
            })
            break
        case "DynamoDB Table":
            methods.forEach((m) => {
                switch (m) {
                    case "GET":
                        actions.push("dynamodb:GetItem")
                        break
                    case "POST":
                        actions.push("dynamodb:PutItem")
                        break
                    case "PUT":
                        actions.push("dynamodb:UpdateItem")
                        break
                    case "DELETE":
                        actions.push("dynamodb:DeleteItem")
                        break
                }
            })
            break
        case "SQS Queue":
            actions.push("sqs:SendMessage")
            break
        case "SNS Topic":
            actions.push("sns:Publish")
            break
        default:
            break
    }
    return "[" + actions.map((a) => {
        return addQuotes(a)
    }).join(", ") + "]"
}

export const apiGateway: Service = {
    serviceName: "API Gateway",
    defaultName: defaultName,
    imgUrl: "img/APIGateway.png",
    category: "Networking & Content Delivery",
    filePath: "/root/modules/inkdrop/main.tf",
    validate: (serviceData: ServiceData) => validateService(serviceData),
    borderColor: "#D82F6C",
    backgroundColor: "#f7d5e1",
    isWrapper: false,
    arrowInTypes: ["API Authentication"],
    arrowOutTypes: ["API Integration"],
    parentsTypes: [],
    properties: [
        {
            name: "Name",
            condition: () => { return true },
            docsUrl: "",
            tfDocsUrl: "",
            isName: true,
            defaultValue: defaultName,
            type: "string",
            mandatory: true,
        },
    ],
}
export const apiGatewayServiceTranslation = (serviceData: ServiceData): ServiceTranslation => {
    const apiGatewayComponents: Component[] = [{
        component: "resource",
        type: "aws_api_gateway_rest_api",
        componentName: getPropertyValue(serviceData, "Name"),
        dependsOnProperties: ["Name"],
        condition: true,
        arguments: [
            {
                name: "name",
                condition: true,
                value: addQuotes(getPropertyValue(serviceData, "Name")),
                dependsOnProperties: ["Name"],
            },
        ]
    },
    ...(serviceData.arrowOut.filter((arrow) => {
        return arrow.arrowData.type === "API Integration"
    }).map((arrow, index) => {
        return {
            component: "resource",
            type: "aws_api_gateway_resource",
            componentName: "api_resource_" + getPropertyValue(serviceData, "Name") + "_" + index,
            condition: true,
            dependsOnProperties: ["serviceOut." + arrow.arrowData.endId + ".Name", "Name"],
            arguments: [
                {
                    name: "rest_api_id",
                    condition: true,
                    value: "aws_api_gateway_rest_api." + getPropertyValue(serviceData, "Name") + ".id",
                    dependsOnProperties: ["Name"]
                },
                {
                    name: "parent_id",
                    condition: true,
                    value: "aws_api_gateway_rest_api." + getPropertyValue(serviceData, "Name") + ".root_resource_id",
                    dependsOnProperties: ["Name"]
                },
                {
                    name: "path_part",
                    condition: true,
                    value: addQuotes(getPropertyValue(arrow.serviceData, "Name")),
                    dependsOnProperties: ["serviceOut." + arrow.arrowData.endId + ".Name"]
                },

            ]
        }
    }) as Component[])
    ];

    if (serviceData.arrowIn.some((arrow) => {
        return arrow.arrowData.type === "API Authentication"
    })) {
        const arrows = serviceData.arrowIn.filter((arrow) => { return arrow.arrowData.type === "API Authentication" })
        apiGatewayComponents.push(
            {
                component: "resource",
                type: "aws_api_gateway_authorizer",
                componentName: "api_authorizer_" + getPropertyValue(serviceData, "Name"),
                condition: true,
                dependsOnProperties: ["Name"],
                arguments: [
                    {
                        name: "name",
                        condition: true,
                        value: addQuotes("api_authorizer_" + getPropertyValue(serviceData, "Name")),
                        dependsOnProperties: ["Name"]
                    },
                    {
                        name: "rest_api_id",
                        condition: true,
                        value: "aws_api_gateway_rest_api." + getPropertyValue(serviceData, "Name") + ".id",
                        dependsOnProperties: ["Name"]
                    },
                    {
                        name: "type",
                        condition: true,
                        value: addQuotes("COGNITO_USER_POOLS"),
                    },
                    {
                        name: "provider_arns",
                        condition: true,
                        value: "[" + arrows.map((arrow) => {
                            return "aws_cognito_user_pool." + getPropertyValue(arrow.serviceData, "Name") + ".arn"
                        }).join(", ") + "]",
                        dependsOnProperties: arrows.map((arrow) => {
                            return "serviceIn." + arrow.arrowData.startId + ".Name"
                        })
                    },
                ]
            }
        )
    }

    serviceData.arrowOut.filter((arrow) => {
        return arrow.arrowData.type === "API Integration"
    }).forEach((arrow, index) => {
        const methods: string[] = arrow.arrowData.properties.some((p) => {
            return p.name === "HTTP Methods"
        }) ? arrow.arrowData.properties.filter((p) => {
            return p.name === "HTTP Methods"
        })[0].value as string[] : ["POST"]

        if (!apiGatewayComponents.some((c) => {
            return c.type === "aws_iam_role"
        })) {
            apiGatewayComponents.push(
                {
                    component: "data",
                    type: "aws_iam_policy_document",
                    componentName: "assume_role_policy_doc_" + getPropertyValue(serviceData, "Name"),
                    dependsOnProperties: ["Name"],
                    condition: true,
                    arguments: [
                        {
                            name: "statement",
                            condition: true,
                            value: [
                                {
                                    name: "actions",
                                    condition: true,
                                    value: "[\"sts:AssumeRole\"]"
                                },
                                {
                                    name: "principals",
                                    condition: true,
                                    value: [
                                        {
                                            name: "type",
                                            condition: true,
                                            value: addQuotes("Service")
                                        },
                                        {
                                            name: "identifiers",
                                            condition: true,
                                            value: "[\"" + "apigateway.amazonaws.com" + "\"]",
                                            dependsOnProperties: ["Service type"]
                                        },
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    component: "resource",
                    type: "aws_iam_role",
                    componentName: "aws_iam_role_" + getPropertyValue(serviceData, "Name"),
                    dependsOnProperties: ["Name"],
                    condition: true,
                    arguments: [
                        {
                            name: "name",
                            condition: true,
                            value: addQuotes("aws_iam_role_" + getPropertyValue(serviceData, "Name")),
                            dependsOnProperties: ["Name"]
                        },
                        {
                            name: "assume_role_policy",
                            condition: true,
                            value: "data.aws_iam_policy_document.assume_role_policy_doc_" + getPropertyValue(serviceData, "Name") + ".json",
                            dependsOnProperties: ["Name"]
                        },
                    ]
                },
            )
        }

        apiGatewayComponents.push(
            {
                component: "resource",
                type: "aws_iam_role_policy",
                componentName: "policy_" + getPropertyValue(serviceData, "Name") + "_" + index,
                condition: true,
                dependsOnProperties: ["Name"],
                arguments: [
                    {
                        name: "name",
                        condition: true,
                        value: addQuotes("policy_" + getPropertyValue(serviceData, "Name") + "_" + index),
                        dependsOnProperties: ["Name"]
                    },
                    {
                        name: "role",
                        condition: true,
                        value: "aws_iam_role." + "aws_iam_role_" + getPropertyValue(serviceData, "Name") + ".id",
                        dependsOnProperties: ["Name"]
                    },
                    {
                        name: "policy",
                        condition: true,
                        value: "data.aws_iam_policy_document.policy_document_" + getPropertyValue(serviceData, "Name") + "_" + index + ".json",
                        dependsOnProperties: ["Name"]
                    },
                ]
            },
            {
                component: "data",
                type: "aws_iam_policy_document",
                componentName: "policy_document_" + getPropertyValue(serviceData, "Name") + "_" + index,
                dependsOnProperties: ["Name"],
                condition: true,
                arguments: [
                    {
                        name: "statement",
                        condition: true,
                        value: [
                            {
                                name: "actions",
                                condition: true,
                                value: getPolicyActions(arrow.serviceData.serviceName, methods),
                                dependsOnProperties: ["arrowOut." + arrow.arrowData.shapeId + ".HTTP Methods"]
                            },
                            {
                                name: "resources",
                                condition: true,
                                value: "[" + getPolicyResource(arrow.serviceData) + "]",
                                dependsOnProperties: ["serviceOut." + arrow.arrowData.endId + ".Name"]
                            }
                        ]
                    }
                ]
            }
        )


        methods.forEach((method) => {
            apiGatewayComponents.push(
                {
                    component: "resource",
                    type: "aws_api_gateway_method",
                    componentName: "api_method_" + getPropertyValue(serviceData, "Name") + "_" + method + "_" + index,
                    condition: true,
                    dependsOnProperties: ["serviceOut." + arrow.arrowData.endId + ".Name", "arrowOut." + arrow.arrowData.shapeId + ".HTTP Methods", "Name"],
                    arguments: [
                        {
                            name: "rest_api_id",
                            condition: true,
                            value: "aws_api_gateway_rest_api." + getPropertyValue(serviceData, "Name") + ".id",
                            dependsOnProperties: ["Name"]
                        },
                        {
                            name: "resource_id",
                            condition: true,
                            value: "aws_api_gateway_resource.api_resource_" + getPropertyValue(serviceData, "Name") + "_" + index + ".id",
                            dependsOnProperties: ["Name"]
                        },
                        {
                            name: "http_method",
                            condition: true,
                            value: addQuotes(method),
                            dependsOnProperties: ["arrowOut." + arrow.arrowData.shapeId + ".HTTP Methods"]
                        },
                        {
                            name: "authorization",
                            condition: true,
                            value: addQuotes((arrow.arrowData.properties.filter((p) => {
                                return p.name === "Authorization method"
                            })[0].value as string).toUpperCase().replaceAll(" ", "_")),
                            dependsOnProperties: ["arrowOut." + arrow.arrowData.shapeId + ".Authorization method"]
                        },
                        ...(arrow.arrowData.properties.filter((p) => {
                            return p.name === "Authorization method"
                        })[0].value === "Cognito user pools" ? [{
                            name: "authorizer_id",
                            condition: true,
                            value: "aws_api_gateway_authorizer.api_authorizer_" + getPropertyValue(serviceData, "Name") + ".id",
                            dependsOnProperties: ["arrowOut." + arrow.arrowData.shapeId + ".Authorization method"]
                        }] : []),
                    ]
                }
            )
            apiGatewayComponents.push(
                {
                    component: "resource",
                    type: "aws_api_gateway_integration",
                    componentName: "api_integration_" + getPropertyValue(serviceData, "Name") + "_" + method + "_" + index,
                    condition: true,
                    dependsOnProperties: ["serviceOut." + arrow.arrowData.endId + ".Name", "Name", "arrowOut." + arrow.arrowData.shapeId + ".HTTP Methods"],
                    arguments: [
                        {
                            name: "rest_api_id",
                            condition: true,
                            value: "aws_api_gateway_rest_api." + getPropertyValue(serviceData, "Name") + ".id",
                            dependsOnProperties: ["Name"]
                        },
                        {
                            name: "resource_id",
                            condition: true,
                            value: "aws_api_gateway_resource.api_resource_" + getPropertyValue(serviceData, "Name") + "_" + index + ".id",
                            dependsOnProperties: ["Name"]
                        },
                        {
                            name: "http_method",
                            condition: true,
                            value: "aws_api_gateway_method.api_method_" + getPropertyValue(serviceData, "Name") + "_" + method + "_" + index + ".http_method",
                            dependsOnProperties: ["Name"]
                        },
                        {
                            name: "integration_http_method",
                            condition: true,
                            value: addQuotes("POST"),
                        },
                        {
                            name: "type",
                            condition: true,
                            value: addQuotes(arrow.serviceData.serviceName === "Lambda" ? "AWS_PROXY" : "AWS"),
                        },
                        {
                            name: "uri",
                            condition: true,
                            value: addQuotes(generateApiGatewayServiceUri("us-west-2", arrow.serviceData.serviceName, arrow.serviceData.name,
                                arrow.serviceData.serviceName === "DynamoDB Table" ? {
                                    action: getDynamoAction(method)
                                } : undefined
                            )),
                            dependsOnProperties: ["serviceOut." + arrow.arrowData.endId + ".Name"]
                        },
                        {
                            name: "credentials",
                            condition: true,
                            value: "aws_iam_role.aws_iam_role_" + getPropertyValue(serviceData, "Name") + ".arn",
                            dependsOnProperties: ["Name"]
                        }
                    ]
                }
            )
        })
    })

    if (serviceData.arrowOut.some((arrow) => {
        return arrow.arrowData.type === "API Integration"
    })) {
        const dependencies: string[] = []
        serviceData.arrowOut.filter((arrow) => {
            return arrow.arrowData.type === "API Integration"
        }).forEach((arrow, index) => {
            const methods: string[] = arrow.arrowData.properties.some((p) => {
                return p.name === "HTTP Methods"
            }) ? arrow.arrowData.properties.filter((p) => {
                return p.name === "HTTP Methods"
            })[0].value as string[] : ["POST"]
            methods.forEach((method) => {
                dependencies.push("aws_api_gateway_integration.api_integration_" + getPropertyValue(serviceData, "Name") + "_" + method + "_" + index)
                dependencies.push("aws_api_gateway_method.api_method_" + getPropertyValue(serviceData, "Name") + "_" + method + "_" + index)
            })
        })
        apiGatewayComponents.push(
            {
                component: "resource",
                type: "aws_api_gateway_deployment",
                componentName: "deployment_" + getPropertyValue(serviceData, "Name"),
                dependsOnProperties: ["Name", ...serviceData.arrowOut.filter((arrow) => {
                    return arrow.arrowData.type === "API Integration"
                }).map((arrow) => {
                    return "serviceOut." + arrow.arrowData.endId + ".Name"
                })],
                condition: true,
                arguments: [
                    {
                        name: "rest_api_id",
                        condition: true,
                        value: "aws_api_gateway_rest_api." + getPropertyValue(serviceData, "Name") + ".id",
                        dependsOnProperties: ["Name"],
                    },
                    {
                        name: "stage_name",
                        condition: true,
                        value: addQuotes("test"),
                    },
                    {
                        name: "depends_on",
                        condition: true,
                        value: "[" + dependencies.join(", ") + "]",
                        dependsOnProperties: [...serviceData.arrowOut.filter((arrow) => {
                            return arrow.arrowData.type === "API Integration"
                        }).map((arrow) => {
                            return "serviceOut." + arrow.arrowData.endId + ".Name"
                        }),
                        ...serviceData.arrowOut.filter((arrow) => {
                            return arrow.arrowData.type === "API Integration"
                        }).map((arrow) => {
                            return "arrowOut." + arrow.arrowData.shapeId + ".HTTP Methods"
                        }),
                        ]
                    }
                ]
            })
    }



    return {
        components: apiGatewayComponents
    }
}
export { }