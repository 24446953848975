export const validateDynamoDBTableName = (tableName: string) => {
    let messages = [];
    let isValid = true;

    // Checking length of table name
    if (tableName.length < 3 || tableName.length > 255) {
        messages.push("Table names must be between 3 and 255 characters long.");
        isValid = false;
    }

    // Checking if the table name consists of letters, numbers, or underscores (_)
    if (!/^[a-zA-Z0-9_.-]+$/.test(tableName)) {
        messages.push("Table names can only consist of alphanumeric characters, underscore (_), hyphen (-), and dot (.).");
        isValid = false;
    }


    return {
        isValid,
        messages
    };
}

export const validateDynamoDBKey = (key: { name: string, type: string }, otherAttributes: { name: string, type: string }[]) => {
    let messages = [];
    let isValid = true;

    // Checking length of key name
    if (key.name.length < 3 || key.name.length > 255) {
        messages.push("Key attribute names must be between 3 and 255 characters long.");
        isValid = false;
    }

    // Checking if the key name consists of letters, numbers, or underscores (_)
    if (!/^[a-zA-Z0-9_.-]+$/.test(key.name)) {
        messages.push("Key attribute names can only consist of alphanumeric characters, underscore (_), hyphen (-), and dot (.).");
        isValid = false;
    }

    // Checking if the key name is unique
    if (otherAttributes.filter(attr => attr.name === key.name).length > 0) {
        messages.push("Key attribute names must be unique.");
        isValid = false;
    }

    return {
        isValid,
        messages
    };
}

export const validateDynamoDBAttributes = (attributes: { name: string, type: string }[], keyAttribute: { name: string, type: string }) => {
    attributes = attributes.slice(0, -1)
    let messages: string[] = [];
    let isValid = true;

    // Validate each attribute
    attributes.forEach((attr, index) => {
        // Check attribute name
        if (!/^[a-zA-Z0-9_.-]+$/.test(attr.name)) {
            messages.push(`${index}: Attribute name ${attr.name} can only consist of alphanumeric characters, underscore (_), hyphen (-), and dot (.).`);
            isValid = false;
        }
        // Checking length of name
        if (attr.name.length < 3 || attr.name.length > 255) {
            messages.push(`${index}: Attribute names must be between 3 and 255 characters long.`);
            isValid = false;
        }
        // Check if the attribute name is unique
        if (attributes.filter(a => a.name === attr.name).length > 1 || attr.name === keyAttribute.name) {
            messages.push(`${index}: Attribute names must be unique.`);
            isValid = false;
        }

    });

    return {
        isValid,
        messages
    };
}