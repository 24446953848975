import { Component, Service, ServiceData, ServiceTranslation, addQuotes, addSquareBrackets, getObjectFormat, getParentByServiceName, getParentShapeId, getPropertyFromArrowIn, getPropertyValue, hasArrowTypeIn, removeQuotes, stringToObject, updatePropertyValue } from "../../Service";

export const subnet: Service = {
    serviceName: "Subnet",
    defaultName: "subnet",
    borderColor: "#7444D1",
    backgroundColor: "#f1ecfa",
    category: "Networking & Content Delivery",
    filePath: "",
    parentsTypes: ["VPC"],
    validate: () => { return { isValid: true, messages: [] } },
    isWrapper: true,
    imgUrl: "img/VPC.png",
    arrowInTypes: [],
    arrowOutTypes: [],
    properties: [
        {
            name: "Address range",
            condition: () => { return true },
            docsUrl: "https://docs.aws.amazon.com/vpc/latest/userguide/vpc-cidr-blocks.html",
            tfDocsUrl: "https://registry.terraform.io/providers/hashicorp/aws/latest/docs/resources/vpc",
            defaultValue: "10.0.0.0/24",
            type: "string",
            mandatory: true
        },
    ],
}

export const subnetServiceTranslation = (serviceData: ServiceData): ServiceTranslation => {
    const args = [{
        name: "cidr_block",
        condition: true,
        value: addQuotes(getPropertyValue(serviceData, "Address range")),
        dependsOnProperties: ["Address range"]
    }]
    getParentByServiceName(serviceData, "VPC") !== undefined && args.push({
        name: "vpc_id",
        condition: true,
        value: "aws_vpc.vpc.id",
        dependsOnProperties: ["parent." + getParentShapeId(serviceData, "VPC") + ".Address range"]
    })
    return {
        components: [
            {
                component: "resource",
                type: "aws_subnet",
                componentName: "subnet",
                condition: true,
                arguments: args
            }
        ]
    }
}