import { CodeArgument, CodeComponent } from "./codeWriteUtils";

export const checkArgumentsChange = (argsCode: any, upperOldArgument: CodeArgument[], completeArguments: CodeArgument[], isEditorFocused: boolean) => {
    const codeArguments: CodeArgument[] = argsCode.arguments !== undefined ? argsCode.arguments : argsCode.value
    const alreadyUsedArgs: CodeArgument[] = []

    codeArguments.forEach((parsedArg) => {
        const oldArray = upperOldArgument.filter((a) => {
            return a.name === parsedArg.name
        })
        const completeArg = completeArguments.filter((cs) => {
            return cs.name === parsedArg.name
        })

        let o: CodeArgument | undefined = undefined

        let found = false

        const isNew = oldArray.length === 0

        oldArray.forEach((old) => {
            if (!found)
                if (!alreadyUsedArgs.some(arg => {
                    return arg.name === old.name
                })) {
                    alreadyUsedArgs.push(old)
                    o = old
                    found = true
                }
        })
        const oldArgs = o || parsedArg

        if (!oldArgs.state) {
            oldArgs.state = completeArg.length > 0 && (o || isNew) ? "UNEDITED" : "EDITED"
        }
        parsedArg.state = oldArgs.state === "HIDDEN" ? "UNEDITED" : oldArgs.state
        if (Array.isArray(parsedArg.value)) {
            parsedArg.state = checkArgumentsChange(parsedArg, oldArgs.value as CodeArgument[], completeArg.length > 0 && (o || isNew) ? Array.isArray(completeArg[0].value) ? completeArg[0].value : [] : [], isEditorFocused)
        } else {
            if ((parsedArg.value !== oldArgs.value || oldArgs.state === "DELETED") && isEditorFocused) {
                parsedArg.state = "EDITED"
            } else {
                parsedArg.state = oldArgs.state === "HIDDEN" ? "UNEDITED" : oldArgs.state
            }
        }
    }
    )
    upperOldArgument.forEach((o) => {
        if (!codeArguments.some((a) => { return a.name === o.name && o.state !== "DELETED" })) {
            o.state = o.state === "HIDDEN" || (!isEditorFocused && o.state !== "DELETED") ? "HIDDEN" : "DELETED"
            codeArguments.push(o)
        }
    })
    return codeArguments.some((c) => { return c.state !== "UNEDITED" && c.state !== "HIDDEN" }) ? "EDITED" : "UNEDITED"
}

export const parseHCL = (hcl: string): CodeComponent[] => {
    let currentIndex = 0;

    const tokens = hcl.match(/[a-zA-Z0-9]+\((?:\\.|[^\(\)\\])*\)|"(?:\\.|[^"\\])*"|\[.*\]|'(?:\\.|[^'\\])*'|\{|\}|[^\s\{\}]+/g) || [];
    let error = false
    function consume(expected: string) {
        if (tokens[currentIndex] === expected) {
            currentIndex++;
        } else {
            currentIndex++;
            console.warn(`Expected ${expected} but got ${tokens[currentIndex]}, skipping.`);
            error = true;
        }
    }

    function parseValue(noAssignment = false) {
        if (noAssignment && tokens[currentIndex] === "{") {
            consume("{");
            const args: CodeArgument[] = [];
            while (tokens[currentIndex] !== '}' && currentIndex < tokens.length) {
                if (tokens[currentIndex]) {
                    args.push(parseArgument());
                } else {
                    currentIndex++;
                }
            }
            consume("}");
            return args;
        }
        if (!noAssignment && tokens[currentIndex] === "{") {
            let string = "{\n"
            currentIndex++
            while (tokens[currentIndex] !== '}' && currentIndex < tokens.length) {
                if (tokens[currentIndex] === "=") {
                    string += "= "
                    currentIndex++
                } else {
                    string += tokens[currentIndex++] + " "
                }
            }
            currentIndex++
            return string + "\n}"
        }
        if (!noAssignment && tokens[currentIndex] === "[") {
            let string = "[\n"
            currentIndex++
            while (tokens[currentIndex] !== ']' && currentIndex < tokens.length) {

                switch (tokens[currentIndex]) {
                    case "=":
                        string += "= "
                        currentIndex++
                        break;
                    case "{":
                        string += "{\n"
                        currentIndex++
                        break;
                    case ",":
                        string += ",\n"
                        currentIndex++
                        break;
                    case "}":
                        string += "}"
                        currentIndex++
                        break;
                    default:
                        if (tokens[currentIndex - 1] === "=")
                            string += tokens[currentIndex++] + "\n"
                        else
                            string += tokens[currentIndex++] + " "
                }
            }
            currentIndex++
            return string + "]"
        }
        return tokens[currentIndex++];
    }

    function parseArgument() {
        let name = tokens[currentIndex++];
        if (tokens[currentIndex] !== "{" && tokens[currentIndex] !== "=") {
            name = name + " " + tokens[currentIndex]
            currentIndex++
        }
        const noAssignment = tokens[currentIndex] === "{";
        if (!noAssignment) {
            consume("=");
        }
        const value = parseValue(noAssignment);
        return { name, value };
    }

    function parseComponent() {
        const component = (tokens[currentIndex++] as "data") || "resource";
        const type = tokens[currentIndex++]?.replace(/['"]/g, "");
        const componentName = tokens[currentIndex++]?.replace(/['"]/g, "");
        consume("{");
        const args: CodeArgument[] = [];
        while (tokens[currentIndex] !== "}" && currentIndex < tokens.length) {
            if (tokens[currentIndex]) {
                args.push(parseArgument());
            } else {
                currentIndex++;
            }
        }
        consume("}");
        return { component, type, componentName, arguments: args };
    }

    const components: CodeComponent[] = [];
    while (currentIndex < tokens.length) {
        if (tokens[currentIndex]) {
            components.push(parseComponent());
        } else {
            currentIndex++;
        }
    }
    if (error) return []
    return components;
}