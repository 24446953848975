import { ArrowDefinition } from "../../Service";

export const cognitoInvoke: ArrowDefinition = {
    type: "Invoke Function",
    fromType: "Cognito User Pool",
    toType: "Lambda",
    properties: [
        {
            name: "Trigger on",
            condition: () => { return true },
            defaultValue: "Post-authentication",
            choices: ["Post-authentication", "Pre-authentication", "Post-confirmation", "Pre-signup"],
            type: "string",
            mandatory: true,
        }
    ]
}