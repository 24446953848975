import { Argument, Component, Service, ServiceData, ServiceTranslation, addQuotes, addSquareBrackets, getObjectFormat, getParentByServiceName, getParentShapeId, getPropertyFromArrowIn, getPropertyValue, hasArrowTypeIn, removeQuotes, stringToObject, updatePropertyValue } from "../../Service";
import { validateService } from "../validators/generalValidator";
import { validateIAMPolicyName } from "../validators/iamPolicyValidator";

type Policy = {
    actions: string[],
    resources: string[]
}

function getPolicyDetails(policyName: string): Policy {
    switch (policyName) {
        case 'S3 Read-Only Access Policy':
            return {
                actions: ['s3:GetObject'],
                resources: ['*'],
            };
        case 'S3 Full Access Policy':
            return {
                actions: ['s3:*'],
                resources: ['*'],
            };
        case 'Lambda Execution Policy':
            return {
                actions: ['lambda:InvokeFunction'],
                resources: ['*'],
            };
        case 'Lambda Full Access Policy':
            return {
                actions: ['lambda:*'],
                resources: ['*'],
            };
        case 'SQS Full Access Policy':
            return {
                actions: ['sqs:*'],
                resources: ['*'],
            };
        case 'SQS Read-Only Access Policy':
            return {
                actions: ['sqs:ReceiveMessage', 'sqs:GetQueueAttributes'],
                resources: ['*'],
            };
        case 'DynamoDB Read-Only Access Policy':
            return {
                actions: ['dynamodb:GetItem', 'dynamodb:Scan', 'dynamodb:Query'],
                resources: ['*'],
            };
        case 'DynamoDB Full Access Policy':
            return {
                actions: ['dynamodb:*'],
                resources: ['*'],
            };
        case 'SNS Topic Read-Only Policy':
            return {
                actions: ['sns:GetTopicAttributes', 'sns:ListSubscriptionsByTopic'],
                resources: ['*'],
            };
        case 'SNS Topic Full Access Policy':
            return {
                actions: ['sns:*'],
                resources: ['*'],
            };
        case 'RDS Instance Read-Only Policy':
            return {
                actions: ['rds:Describe*', 'rds:List*'],
                resources: ['*'],
            };
        case 'RDS Instance Full Access Policy':
            return {
                actions: ['rds:*'],
                resources: ['*'],
            };
        default:
            return {
                actions: ['s3:PutObject', 's3:DeleteObject'],
                resources: ['*'],
            };
    }
}

const defaultName = "iam-policy"

export const iamPolicy: Service = {
    serviceName: "IAM Policy",
    defaultName: defaultName,
    borderColor: "#DD3033",
    backgroundColor: "#f8d5d6",
    category: "Security, Identity, & Compliance",
    validate: (serviceData: ServiceData) => validateService(serviceData),
    filePath: "/root/modules/inkdrop/main.tf",
    parentsTypes: [],
    isWrapper: false,
    imgUrl: "img/IAM.png",
    arrowInTypes: ["Policy Restriction"],
    arrowOutTypes: ["Policy"],
    properties: [
        {
            name: "Policy type",
            condition: () => { return true },
            docsUrl: "",
            tfDocsUrl: "",
            defaultValue: "S3 Read-Only Access Policy",
            type: "string",
            mandatory: true,
            choices: [
                "S3 Read-Only Access Policy",
                "S3 Full Access Policy",
                "Lambda Execution Policy",
                "Lambda Full Access Policy",
                "SQS Full Access Policy",
                "SQS Read-Only Access Policy",
                "DynamoDB Read-Only Access Policy",
                "DynamoDB Full Access Policy",
                "SNS Topic Read-Only Policy",
                "SNS Topic Full Access Policy",
                "RDS Instance Read-Only Policy",
                "RDS Instance Full Access Policy"
            ]
        },
        {
            name: "Name",
            condition: () => { return true },
            validate: (serviceData: ServiceData) => validateIAMPolicyName(serviceData.properties.filter((p) => {
                return p.name === "Name"
            })[0].value as string),
            docsUrl: "",
            tfDocsUrl: "",
            isName: true,
            defaultValue: defaultName,
            type: "string",
            mandatory: true,
        },
    ],
}

export const iamPolicyServiceTranslation = (serviceData: ServiceData): ServiceTranslation => {

    const statementValue = [
        {
            name: "actions",
            condition: true,
            value: JSON.stringify(getPolicyDetails(getPropertyValue(serviceData, "Policy type")).actions),
            dependsOnProperties: ["Policy type"]
        },
    ]

    if (serviceData.arrowIn.some(a => {
        return a.arrowData.type === "Policy Restriction"
    })) {
        statementValue.push({
            name: "resources",
            condition: true,
            value: "[" + serviceData.arrowIn.filter(a => {
                return a.arrowData.type === "Policy Restriction"
            }).map((a) => {
                switch (a.serviceData.serviceName) {
                    case "S3 Bucket":
                        return "aws_s3_bucket." + getPropertyValue(a.serviceData, "Name") + ".arn"
                    case "SQS Queue":
                        return "aws_sqs_queue." + getPropertyValue(a.serviceData, "Name") + ".arn"
                    case "DynamoDB Table":
                        return "aws_dynamodb_table." + getPropertyValue(a.serviceData, "Name") + ".arn"
                    case "Lambda":
                        return "aws_lambda_function." + getPropertyValue(a.serviceData, "Name") + ".arn"
                    case "SNS Topic":
                        return "aws_sns_topic." + getPropertyValue(a.serviceData, "Name") + ".arn"
                    case "RDS Instance":
                        return "aws_db_instance." + getPropertyValue(a.serviceData, "Name") + ".arn"
                }
            }).join(",") + "]",
            dependsOnProperties: serviceData.arrowIn.filter(a => {
                return a.arrowData.type === "Policy Restriction"
            }).map((a) => { return "serviceIn." + a.arrowData.startId + ".Name" })
        })
    } else {
        statementValue.push({
            name: "resources",
            condition: true,
            value: JSON.stringify(getPolicyDetails(getPropertyValue(serviceData, "Policy type")).resources),
            dependsOnProperties: ["Policy type"]
        })
    }

    return {
        components: [
            {
                component: "data",
                type: "aws_iam_policy_document",
                componentName: getPropertyValue(serviceData, "Name"),
                dependsOnProperties: ["Name"],
                condition: true,
                arguments: [
                    {
                        name: "statement",
                        condition: true,
                        value: statementValue
                    }
                ]
            }
        ]
    }
}