import { terraformTemplate } from "../code/code-editor/FileTree";
import { CodeComponent, getFileContent } from "../code/codeWriteUtils";
import CustomButton from "../custom-components/CustomButton";
import { MappedService } from "../editor-handler/EditorHandler";
import JSZip from 'jszip';
import { saveAs } from 'file-saver'
import DownloadIcon from '@mui/icons-material/Download';

interface CodeDownloaderProps {
    fileContents: { [filePath: string]: string }
    serviceDataMapRef: React.MutableRefObject<Map<string, MappedService>>
    setCodeState: (key: string, newCodeState: CodeComponent[]) => void
    generalCodeState: { [filePath: string]: CodeComponent[] }
}

const CodeDownloader = ({
    fileContents, serviceDataMapRef, setCodeState, generalCodeState
}: CodeDownloaderProps) => {

    const addFile = async (zip: JSZip, path: string, content: string) => {
        path = path.replace("/root", "inkdrop-tf");
        zip.file(path, content);
    };

    async function traverseAndGenerateFiles(zip: JSZip, obj: any, currentPath: string = '') {
        for (let key in obj) {
            let newPath = `${currentPath}/${key}`;
            if (obj[key] === null) {
                let textToAdd: string[];
                if (fileContents[newPath]) {
                    textToAdd = [fileContents[newPath]];
                } else {
                    const { newCodeText } = getFileContent(serviceDataMapRef, newPath, setCodeState, generalCodeState[newPath]);
                    textToAdd = newCodeText;
                }
                await addFile(zip, newPath, textToAdd.join("\n\n"));
            } else {
                await traverseAndGenerateFiles(zip, obj[key], newPath);
            }
        }
    }

    const handleDownloadClick = async () => {
        const zip = new JSZip(); // zip should be defined inside the click handler.
        await traverseAndGenerateFiles(zip, terraformTemplate);

        zip.generateAsync({ type: "blob" }).then(function (content) {
            saveAs(content, "inkdrop-tf.zip");
        }).catch(function (err) {
            console.error('Error generating zip:', err);
        });
    };

    return (
        <CustomButton name="Download code" darkMode
            icon={<DownloadIcon />}
            onClick={handleDownloadClick} />
    )
}

export default CodeDownloader;