import { ArrowData, ArrowDefinition } from "../../Service";

export const s3Notification: ArrowDefinition = {
    type: "SNS Notification",
    fromType: "S3 Bucket",
    toType: "SNS Topic",
    properties: [
        {
            name: "Send notification on",
            condition: () => { return true },
            defaultValue: "Create",
            choices: ["Create", "Delete"],
            type: "string",
            mandatory: true,
        }
    ]
}