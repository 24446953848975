import { Component, Service, ServiceData, ServiceTranslation, addQuotes, addSquareBrackets, getObjectFormat, getPropertyFromArrowIn, getPropertyValue, hasArrowTypeIn, removeQuotes, stringToObject, updatePropertyValue } from "../../Service";
import { validateService } from "../validators/generalValidator";
import { validateVPCOutboundTraffic } from "../validators/vpcValidator";

const defaultName = "vpc"

export const vpc: Service = {
    serviceName: "VPC",
    defaultName: defaultName,
    borderColor: "#7444D1",
    backgroundColor: "#f1ecfa",
    category: "Networking & Content Delivery",
    parentsTypes: [],
    validate: (serviceData: ServiceData) => validateService(serviceData),
    filePath: "/root/modules/inkdrop/main.tf",
    isWrapper: true,
    imgUrl: "img/VPC.png",
    arrowInTypes: [],
    arrowOutTypes: [],
    properties: [
        {
            name: "Number of availability zones",
            condition: () => { return true },
            docsUrl: "",
            tfDocsUrl: "",
            defaultValue: "2",
            choices: ["1", "2", "3"],
            type: "string",
            mandatory: true,
        },
        {
            name: "Number of public subnets",
            condition: () => { return true },
            docsUrl: "",
            tfDocsUrl: "",
            defaultValue: "1 per AZ",
            choices: ["None", "1 per AZ", "2 per AZ"],
            type: "string",
            mandatory: true,
        },
        {
            name: "Number of private subnets",
            condition: () => { return true },
            docsUrl: "",
            tfDocsUrl: "",
            defaultValue: "None",
            choices: ["None", "1 per AZ", "2 per AZ"],
            type: "string",
            mandatory: true,
        },
        {
            name: "Private subnets outbound traffic",
            condition: (serviceData: ServiceData) => { return getPropertyValue(serviceData, "Number of private subnets") !== "None" },
            validate: (serviceData: ServiceData) => {
                const publicSubnetsNumber = getPropertyValue(serviceData, "Number of public subnets")
                const outboundTraffic = getPropertyValue(serviceData, "Private subnets outbound traffic")
                return validateVPCOutboundTraffic(publicSubnetsNumber, outboundTraffic)
            },
            docsUrl: "",
            tfDocsUrl: "",
            defaultValue: "Enabled",
            choices: ["Enabled with high availability", "Enabled", "Disabled"],
            type: "string",
            mandatory: true,
        },
        {
            name: "Name",
            condition: () => { return true },
            docsUrl: "",
            tfDocsUrl: "",
            isName: true,
            defaultValue: defaultName,
            type: "string",
            mandatory: true,
        },
    ],
}

export const getSubnetCount = (numberOfSubnets: string, numberOfAvailabilityZones: string) => {
    if (numberOfSubnets === "None") return "0"
    if (numberOfSubnets === "1 per AZ") return numberOfAvailabilityZones
    if (numberOfSubnets === "2 per AZ") return 2 * parseInt(numberOfAvailabilityZones) + ""
    return "0"
}

export const getSubnetsByType = (parentVPC: ServiceData, type: "Public subnets" | "Private subnets") => {
    const numberOfPublicSubnets = getPropertyValue(parentVPC, "Number of public subnets")
    const numberOfPrivateSubnets = getPropertyValue(parentVPC, "Number of private subnets")
    const numberOfSubnetsToConsider = numberOfPublicSubnets === "None" && numberOfPrivateSubnets !== "None" ? numberOfPrivateSubnets :
        numberOfPublicSubnets !== "None" && numberOfPrivateSubnets === "None" ? numberOfPublicSubnets :
            type === "Public subnets" ? numberOfPublicSubnets : numberOfPrivateSubnets
    const placeHolderToConsider = numberOfPublicSubnets === "None" && numberOfPrivateSubnets !== "None" ? "private" :
        numberOfPublicSubnets !== "None" && numberOfPrivateSubnets === "None" ? "public" :
            type === "Public subnets" ? "public" : "private"
    const numberOfSubnets = parseInt(getSubnetCount(numberOfSubnetsToConsider, getPropertyValue(parentVPC, "Number of availability zones")))
    return Array.from(Array(numberOfSubnets).keys()).map((i) => {
        return "aws_subnet." + getPropertyValue(parentVPC, "Name") + "_" + placeHolderToConsider + "_subnet[" + i + "].id"
    })
}


export const hasPublicAndPrivateSubnets = (childServiceData: ServiceData) => {
    const parentVPC = childServiceData.parents.filter((p) => {
        return p.serviceData.serviceName === "VPC"
    })
    if (parentVPC.length === 0) return false
    return !(getPropertyValue(parentVPC[0].serviceData, "Number of private subnets") === "None" ?
        getPropertyValue(parentVPC[0].serviceData, "Number of public subnets") !== "None" :
        getPropertyValue(parentVPC[0].serviceData, "Number of public subnets") === "None")
}


export const vpcServiceTranslation = (serviceData: ServiceData): ServiceTranslation => {
    return {
        components: [
            {
                component: "resource",
                type: "aws_vpc",
                componentName: getPropertyValue(serviceData, "Name"),
                condition: true,
                dependsOnProperties: ["Name"],
                arguments: [
                    {
                        name: "cidr_block",
                        condition: true,
                        value: addQuotes("10.0.0.0/16"),
                    },
                    {
                        name: "enable_dns_hostnames",
                        condition: true,
                        value: "true",
                    },
                    {
                        name: "enable_dns_support",
                        condition: true,
                        value: "true",
                    },
                    {
                        name: "instance_tenancy",
                        condition: true,
                        value: addQuotes("default"),
                    },
                    {
                        name: "tags",
                        condition: true,
                        value: "{\n        Name = " + addQuotes(getPropertyValue(serviceData, "Name")) + "\n    }",
                        dependsOnProperties: ["Name"],

                    }
                ]
            },
            {
                component: "resource",
                type: "aws_internet_gateway",
                componentName: getPropertyValue(serviceData, "Name") + "_igw",
                condition: true,
                dependsOnProperties: ["Name"],
                arguments: [
                    {
                        name: "vpc_id",
                        condition: true,
                        value: "aws_vpc." + getPropertyValue(serviceData, "Name") + ".id",
                        dependsOnProperties: ["Name"],
                    },
                    {
                        name: "tags",
                        condition: true,
                        value: "{\n        Name = " + addQuotes(getPropertyValue(serviceData, "Name") + "_igw") + "\n    }",
                        dependsOnProperties: ["Name"],
                    }
                ]
            },
            {
                component: "data",
                type: "aws_availability_zones",
                componentName: "available_zones_" + getPropertyValue(serviceData, "Name"),
                condition: true,
                dependsOnProperties: ["Name"],
                arguments: [
                    {
                        name: "state",
                        condition: true,
                        value: addQuotes("available"),
                    }
                ]
            },
            {
                component: "resource",
                type: "aws_subnet",
                componentName: getPropertyValue(serviceData, "Name") + "_public_subnet",
                condition: getPropertyValue(serviceData, "Number of public subnets") !== "None",
                dependsOnProperties: ["Name", "Number of public subnets"],
                arguments: [
                    {
                        name: "count",
                        condition: true,
                        value: getSubnetCount(getPropertyValue(serviceData, "Number of public subnets"), getPropertyValue(serviceData, "Number of availability zones")),
                        dependsOnProperties: ["Number of public subnets", "Number of availability zones"],
                    },
                    {
                        name: "vpc_id",
                        condition: true,
                        value: "aws_vpc." + getPropertyValue(serviceData, "Name") + ".id",
                        dependsOnProperties: ["Name"],
                    },
                    {
                        name: "cidr_block",
                        condition: true,
                        value: "cidrsubnet(aws_vpc." + getPropertyValue(serviceData, "Name") + ".cidr_block,4,count.index)",
                        dependsOnProperties: ["Name"],
                    },
                    {
                        name: "availability_zone",
                        condition: true,
                        value: "element(data.aws_availability_zones.available_zones_" + getPropertyValue(serviceData, "Name") + ".names,count.index%" + getPropertyValue(serviceData, "Number of availability zones") + ")",
                        dependsOnProperties: ["Name", "Number of availability zones"],
                    },
                    {
                        name: "tags",
                        condition: true,
                        value: "{\n        Name = " + addQuotes(getPropertyValue(serviceData, "Name") + "_public_subnet_${count.index}") + "\n    }",
                        dependsOnProperties: ["Name"],
                    }
                ]
            },
            {
                component: "resource",
                type: "aws_subnet",
                componentName: getPropertyValue(serviceData, "Name") + "_private_subnet",
                condition: getPropertyValue(serviceData, "Number of private subnets") !== "None",
                dependsOnProperties: ["Name", "Number of private subnets"],
                arguments: [
                    {
                        name: "count",
                        condition: true,
                        value: getSubnetCount(getPropertyValue(serviceData, "Number of private subnets"), getPropertyValue(serviceData, "Number of availability zones")),
                        dependsOnProperties: ["Number of private subnets", "Number of availability zones"],
                    },
                    {
                        name: "vpc_id",
                        condition: true,
                        value: "aws_vpc." + getPropertyValue(serviceData, "Name") + ".id",
                        dependsOnProperties: ["Name"],
                    },
                    {
                        name: "cidr_block",
                        condition: true,
                        value: "cidrsubnet(aws_vpc." + getPropertyValue(serviceData, "Name") + ".cidr_block,4,count.index+8)",
                        dependsOnProperties: ["Name"],
                    },
                    {
                        name: "availability_zone",
                        condition: true,
                        value: "element(data.aws_availability_zones.available_zones_" + getPropertyValue(serviceData, "Name") + ".names,count.index%" + getPropertyValue(serviceData, "Number of availability zones") + ")",
                        dependsOnProperties: ["Name", "Number of availability zones"],
                    },
                    {
                        name: "tags",
                        condition: true,
                        value: "{\n        Name = " + addQuotes(getPropertyValue(serviceData, "Name") + "_private_subnet_${count.index}") + "\n    }",
                        dependsOnProperties: ["Name"],
                    }
                ]
            },
            {
                component: "resource",
                type: "aws_route_table",
                componentName: getPropertyValue(serviceData, "Name") + "_public_route_table",
                condition: true,
                dependsOnProperties: ["Name"],
                arguments: [
                    {
                        name: "vpc_id",
                        condition: true,
                        value: "aws_vpc." + getPropertyValue(serviceData, "Name") + ".id",
                        dependsOnProperties: ["Name"],
                    },
                    {
                        name: "route",
                        condition: true,
                        value: [
                            {
                                name: "cidr_block",
                                condition: true,
                                value: addQuotes("0.0.0.0/0")
                            },
                            {
                                name: "gateway_id",
                                condition: true,
                                value: "aws_internet_gateway." + getPropertyValue(serviceData, "Name") + "_igw.id",
                                dependsOnProperties: ["Name"],
                            }
                        ]
                    },
                    {
                        name: "tags",
                        condition: true,
                        value: "{\n        Name = " + addQuotes(getPropertyValue(serviceData, "Name") + "_public_route_table") + "\n    }",
                        dependsOnProperties: ["Name"],
                    }
                ]
            },
            {
                component: "resource",
                type: "aws_route_table_association",
                componentName: getPropertyValue(serviceData, "Name") + "_public_subnet_association",
                condition: true,
                dependsOnProperties: ["Name"],
                arguments: [
                    {
                        name: "count",
                        condition: true,
                        value: getSubnetCount(getPropertyValue(serviceData, "Number of public subnets"), getPropertyValue(serviceData, "Number of availability zones")),
                        dependsOnProperties: ["Number of public subnets", "Number of availability zones"],
                    },
                    {
                        name: "subnet_id",
                        condition: true,
                        value: "aws_subnet." + getPropertyValue(serviceData, "Name") + "_public_subnet[count.index].id",
                        dependsOnProperties: ["Name"],
                    },
                    {
                        name: "route_table_id",
                        condition: true,
                        value: "aws_route_table." + getPropertyValue(serviceData, "Name") + "_public_route_table.id",
                        dependsOnProperties: ["Name"],
                    }
                ]
            },
            {
                component: "resource",
                type: "aws_eip",
                componentName: getPropertyValue(serviceData, "Name") + "_nat_eip",
                condition: getPropertyValue(serviceData, "Private subnets outbound traffic") !== "Disabled" && getPropertyValue(serviceData, "Number of public subnets") !== "None" && getPropertyValue(serviceData, "Number of private subnets") !== "None",
                dependsOnProperties: ["Name", "Private subnets outbound traffic", "Number of public subnets", "Number of private subnets"],
                arguments: [
                    {
                        name: "count",
                        condition: getPropertyValue(serviceData, "Private subnets outbound traffic") === "Enabled with high availability",
                        value: getSubnetCount("1 per AZ", getPropertyValue(serviceData, "Number of availability zones")),
                        dependsOnProperties: ["Number of availability zones", "Private subnets outbound traffic"],
                    },
                    {
                        name: "vpc",
                        condition: true,
                        value: "true",
                    },
                    {
                        name: "tags",
                        condition: true,
                        value: "{\n        Name = " + addQuotes(getPropertyValue(serviceData, "Name") + "_nat_eip") + "\n    }",
                    }
                ]
            },
            {
                component: "resource",
                type: "aws_route_table",
                componentName: getPropertyValue(serviceData, "Name") + "_private_route_table",
                condition: getPropertyValue(serviceData, "Private subnets outbound traffic") !== "Disabled" && getPropertyValue(serviceData, "Number of public subnets") !== "None" && getPropertyValue(serviceData, "Number of private subnets") !== "None",
                dependsOnProperties: ["Name", "Private subnets outbound traffic", "Number of public subnets", "Number of private subnets"],
                arguments: [
                    {
                        name: "count",
                        condition: getPropertyValue(serviceData, "Private subnets outbound traffic") === "Enabled with high availability",
                        value: getSubnetCount("1 per AZ", getPropertyValue(serviceData, "Number of availability zones")),
                        dependsOnProperties: ["Number of availability zones", "Private subnets outbound traffic"],
                    },
                    {
                        name: "vpc_id",
                        condition: true,
                        value: "aws_vpc." + getPropertyValue(serviceData, "Name") + ".id",
                        dependsOnProperties: ["Name"],
                    },
                    {
                        name: "route",
                        condition: true,
                        value: [
                            {
                                name: "cidr_block",
                                condition: true,
                                value: addQuotes("0.0.0.0/0")
                            },
                            {
                                name: "nat_gateway_id",
                                condition: true,
                                value: "aws_nat_gateway." + getPropertyValue(serviceData, "Name") + "_nat_gateway" + (getPropertyValue(serviceData, "Private subnets outbound traffic") === "Enabled with high availability" ? "[count.index]" : "") + ".id",
                                dependsOnProperties: ["Name", "Private subnets outbound traffic"],
                            }
                        ]
                    },
                    {
                        name: "tags",
                        condition: true,
                        value: "{\n        Name = " + addQuotes(getPropertyValue(serviceData, "Name") + "_private_route_table") + "\n    }",
                        dependsOnProperties: ["Name"],
                    }
                ]
            },
            {
                component: "resource",
                type: "aws_route_table_association",
                componentName: getPropertyValue(serviceData, "Name") + "_private_subnet_association",
                condition: getPropertyValue(serviceData, "Private subnets outbound traffic") !== "Disabled" && getPropertyValue(serviceData, "Number of public subnets") !== "None" && getPropertyValue(serviceData, "Number of private subnets") !== "None",
                dependsOnProperties: ["Name", "Private subnets outbound traffic", "Number of public subnets", "Number of private subnets"],
                arguments: [
                    {
                        name: "count",
                        condition: true,
                        value: getSubnetCount(getPropertyValue(serviceData, "Number of private subnets"), getPropertyValue(serviceData, "Number of availability zones")),
                        dependsOnProperties: ["Number of private subnets", "Number of availability zones"],
                    },
                    {
                        name: "subnet_id",
                        condition: true,
                        value: "aws_subnet." + getPropertyValue(serviceData, "Name") + "_private_subnet[count.index].id",
                        dependsOnProperties: ["Name"],
                    },
                    {
                        name: "route_table_id",
                        condition: true,
                        value: "aws_route_table." + getPropertyValue(serviceData, "Name") + "_private_route_table.id",
                        dependsOnProperties: ["Name"],
                    }
                ]
            },
            {
                component: "resource",
                type: "aws_nat_gateway",
                componentName: getPropertyValue(serviceData, "Name") + "_nat_gateway",
                condition: getPropertyValue(serviceData, "Private subnets outbound traffic") !== "Disabled" && getPropertyValue(serviceData, "Number of public subnets") !== "None" && getPropertyValue(serviceData, "Number of private subnets") !== "None",
                dependsOnProperties: ["Name", "Private subnets outbound traffic", "Number of public subnets", "Number of private subnets"],
                arguments: [
                    {
                        name: "count",
                        condition: getPropertyValue(serviceData, "Private subnets outbound traffic") === "Enabled with high availability",
                        value: getSubnetCount("1 per AZ", getPropertyValue(serviceData, "Number of availability zones")),
                        dependsOnProperties: ["Number of availability zones", "Private subnets outbound traffic"],
                    },
                    {
                        name: "allocation_id",
                        condition: true,
                        value: "aws_eip." + getPropertyValue(serviceData, "Name") + "_nat_eip" + (getPropertyValue(serviceData, "Private subnets outbound traffic") === "Enabled with high availability" ? "[count.index]" : "") + ".id",
                        dependsOnProperties: ["Name", "Private subnets outbound traffic"],
                    },
                    {
                        name: "subnet_id",
                        condition: true,
                        value: "aws_subnet." + getPropertyValue(serviceData, "Name") + "_public_subnet" + (getPropertyValue(serviceData, "Private subnets outbound traffic") === "Enabled with high availability" ? "[count.index]" : "[0]") + ".id",
                        dependsOnProperties: ["Name", "Private subnets outbound traffic"],
                    },
                    {
                        name: "tags",
                        condition: true,
                        value: "{\n        Name = " + addQuotes(getPropertyValue(serviceData, "Name") + "_nat_gateway") + "\n    }",
                        dependsOnProperties: ["Name"],
                    }
                ],
            }
        ]
    }
}