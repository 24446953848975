import { ServiceData } from "../../Service";
import { validateService } from "./generalValidator";

export const validateEcsService = (service: ServiceData) => {
    let { isValid, messages } = validateService(service)
    const finalMessages: string[] = []
    if (!service.parents.some(p => p.serviceData.serviceName === "VPC")) {
        finalMessages.push("The ECS service must be created inside a VPC.")
        isValid = false
    }
    if (!service.parents.some(p => p.serviceData.serviceName === "ECS Cluster")) {
        finalMessages.push("The ECS service must be created inside an ECS cluster.")
        isValid = false
    }
    messages.forEach((m) => {
        const split = m.split(": ")
        const finalMessage = split.length > 1 ?
            split.slice(1).join(": ").startsWith("Invalid image URI") ? split.slice(1).join(": ") :
                split.slice(2).join(": ") : split[0]
        if (finalMessages.indexOf(finalMessage) === -1) finalMessages.push(finalMessage)
    })

    return { isValid, messages: finalMessages }
}

export const validateEcsContainerDefinition = (containerDefinition: {
    imageUri: string,
    ports: {
        containerPort: string,
        hostPort: string
    }[],
}[]) => {
    let messages: string[] = [];
    let isValid = true;

    // Check if the image URI is valid (for every image, ECR or not)
    containerDefinition.forEach((c, index) => {
        if (c.imageUri === "") {
            messages.push(`${index}: Invalid image URI. Image URI can't be empty.`);
            isValid = false;
        } else if (!/^[a-z][a-z0-9-_.\/]+(:[a-z0-9-_.\/]+)?$/g.test(c.imageUri)) {
            messages.push(`${index}: Invalid image URI. The URI should consist of letters, numbers, hyphens (-), and underscores (_).`);
            isValid = false;
        }
    });

    // Check if the containerPorts are valid
    containerDefinition.forEach((c, index) => {
        c.ports.forEach((p, i) => {
            if (i !== c.ports.length - 1) {
                if (p.containerPort === "") {
                    messages.push(`${index}: ${i}: Invalid container port. Container port can't be empty.`);
                    isValid = false;
                } else {
                    if (!/^[0-9]+$/g.test(p.containerPort)) {
                        messages.push(`${index}: ${i}: Invalid container port. Container port should be a number.`);
                        isValid = false;
                    }
                    else if (!/^[0-9]{1,5}$/g.test(p.containerPort)) {
                        messages.push(`${index}: ${i}: Invalid container port. Container port should be a number between 0 and 65535.`);
                        isValid = false;
                    }
                }
            }
        });
    });

    // Check if the hostPorts are valid
    containerDefinition.forEach((c, index) => {
        c.ports.forEach((p, i) => {
            if (i !== c.ports.length - 1) {
                if (p.hostPort === "") {
                    messages.push(`${index}: ${i}: Invalid host port. Host port can't be empty.`);
                    isValid = false;
                } else {
                    if (!/^[0-9]+$/g.test(p.hostPort)) {
                        messages.push(`${index}: ${i}: Invalid host port. Host port should be a number.`);
                        isValid = false;
                    } else if (!/^[0-9]{1,5}$/g.test(p.hostPort)) {
                        messages.push(`${index}: ${i}: Invalid host port. Host port should be a number between 0 and 65535.`);
                        isValid = false;
                    }
                }
            }
        });
    });

    return {
        isValid,
        messages
    };
}