import { Component, Service, ServiceData, ServiceTranslation, addQuotes, getObjectFormat, getPropertyFromArrowIn, getPropertyValue } from "../../Service";
import { validateService } from "../validators/generalValidator";
import { validateSQSQueueName } from "../validators/sqsValidator";

const defaultName = "sqs-queue"

export const sqs: Service = {
    serviceName: "SQS Queue",
    defaultName: defaultName,
    imgUrl: "img/SQS.png",
    borderColor: "#D82F6C",
    category: "Application Integration",
    filePath: "/root/modules/inkdrop/main.tf",
    validate: (serviceData: ServiceData) => validateService(serviceData),
    backgroundColor: "#f7d5e1",
    isWrapper: false,
    arrowInTypes: ["SQS Message", "Interact", "API Integration"],
    arrowOutTypes: ["Invoke Function", "Policy Restriction"],
    parentsTypes: [],
    properties: [
        {
            name: "Strictly preserve message order",
            condition: () => { return true },
            docsUrl: "https://docs.aws.amazon.com/AWSSimpleQueueService/latest/SQSDeveloperGuide/sqs-queue-types.html",
            tfDocsUrl: "",
            defaultValue: "No",
            choices: ["Yes", "No"],
            type: "string",
            mandatory: true
        },
        /*
        {
            name: "High throughput",
            condition: (serviceData: ServiceData) => { return getPropertyValue(serviceData, "Queue type") === "FIFO" },
            docsUrl: "https://docs.aws.amazon.com/AWSSimpleQueueService/latest/SQSDeveloperGuide/sqs-queue-types.html",
            tfDocsUrl: "",
            defaultValue: "Yes",
            choices: ["Yes", "No"],
            type: "string",
            mandatory: true
        },
        */
        {
            name: "Name",
            condition: () => { return true },
            validate: (serviceData: ServiceData) => validateSQSQueueName(serviceData.properties.filter((p) => {
                return p.name === "Name"
            })[0].value as string),
            docsUrl: "",
            tfDocsUrl: "",
            isName: true,
            defaultValue: defaultName,
            type: "string",
            mandatory: true,
        },
    ],
}
export const sqsServiceTranslation = (serviceData: ServiceData): ServiceTranslation => {

    const queueArgs = [
        {
            name: "name",
            condition: true,
            value: getPropertyValue(serviceData, "Strictly preserve message order") === "Yes" ? addQuotes(getPropertyValue(serviceData, "Name") + ".fifo") : addQuotes(getPropertyValue(serviceData, "Name")),
            dependsOnProperties: ["Name", "Strictly preserve message order"],
        },
        {
            name: "delay_seconds",
            condition: true,
            value: "0"
        },
        {
            name: "max_message_size",
            condition: true,
            value: "262144"
        },
        {
            name: "message_retention_seconds",
            condition: true,
            value: "345600"
        },
        {
            name: "receive_wait_time_seconds",
            condition: true,
            value: "0"
        },
        {
            name: "fifo_queue",
            condition: getPropertyValue(serviceData, "Strictly preserve message order") === "Yes",
            value: "true",
            dependsOnProperties: ["Strictly preserve message order"],
        },
        {
            name: "content_based_deduplication",
            condition: getPropertyValue(serviceData, "Strictly preserve message order") === "Yes",
            value: "false",
            dependsOnProperties: ["Strictly preserve message order"],
        },
        {
            name: "deduplication_scope",
            condition: getPropertyValue(serviceData, "Strictly preserve message order") === "Yes",
            value: addQuotes("queue"),
            dependsOnProperties: ["Strictly preserve message order"],
        },
        {
            name: "fifo_throughput_limit",
            condition: getPropertyValue(serviceData, "Strictly preserve message order") === "Yes",
            value: addQuotes("perQueue"),
            dependsOnProperties: ["Strictly preserve message order"],
        },
    ]

    const components: Component[] = [{
        component: "resource",
        type: "aws_sqs_queue",
        componentName: getPropertyValue(serviceData, "Name"),
        dependsOnProperties: ["Name"],
        condition: true,
        arguments: queueArgs
    },]

    if (serviceData.arrowIn.some((a) => {
        return a.arrowData.type === "SQS Message"
    })) {
        queueArgs.push(
            {
                name: "policy",
                condition: true,
                value: "data.aws_iam_policy_document.send_message_policy_" + getPropertyValue(serviceData, "Name") + ".json",
                dependsOnProperties: [...serviceData.arrowIn.filter((a) => {
                    return a.arrowData.type === "SQS Message"
                }).map((a) => {
                    return "serviceIn." + a.arrowData.startId + ".Name"
                }), "Name"],
            },
        )
    }

    if (serviceData.arrowIn.some((a) => {
        return a.arrowData.type === "SQS Message"
    })) {
        components.push({
            component: "data",
            type: "aws_iam_policy_document",
            componentName: "send_message_policy_" + getPropertyValue(serviceData, "Name"),
            dependsOnProperties: [...serviceData.arrowIn.filter((a) => {
                return a.arrowData.type === "SQS Message"
            }).map((a) => {
                return "serviceIn." + a.arrowData.startId + ".Name"
            }), "Name"],
            condition: true,
            arguments: [
                {
                    name: "statement",
                    condition: true,
                    value: [
                        {
                            name: "effect",
                            condition: true,
                            value: addQuotes("Allow")
                        },
                        {
                            name: "principals",
                            condition: true,
                            value: [
                                {
                                    name: "type",
                                    condition: true,
                                    value: addQuotes("*")
                                },
                                {
                                    name: "identifiers",
                                    condition: true,
                                    value: "[\"*\"]"
                                },
                            ]
                        },
                        {
                            name: "actions",
                            condition: true,
                            value: "[\"sqs:SendMessage\"]"
                        },
                        {
                            name: "resources",
                            condition: true,
                            value: "[\"arn:aws:sqs:*:*:" + getPropertyValue(serviceData, "Name") + "\"]",
                            dependsOnProperties: ["Name"]
                        },
                        {
                            name: "condition",
                            condition: true,
                            value: [
                                {
                                    name: "test",
                                    condition: true,
                                    value: addQuotes("ArnEquals")
                                },
                                {
                                    name: "variable",
                                    condition: true,
                                    value: addQuotes("aws:SourceArn")
                                },
                                {
                                    name: "values",
                                    condition: true,
                                    value: "[" +
                                        serviceData.arrowIn.filter((a) => {
                                            return a.arrowData.type === "SQS Message"
                                        }).map((a) => {
                                            return (a.serviceData.serviceName === "S3 Bucket" ?
                                                "aws_s3_bucket." : "aws_sns_topic.") + getPropertyValue(a.serviceData, "Name") + ".arn"
                                        }).join(",") +
                                        "]",
                                    dependsOnProperties: serviceData.arrowIn.filter((a) => {
                                        return a.arrowData.type === "SQS Message"
                                    }).map((a) => {
                                        return "serviceIn." + a.arrowData.startId + ".Name"
                                    })
                                },
                            ]
                        },
                    ]
                }
            ]
        })
    }

    return {
        components: components
    }
}
export { }