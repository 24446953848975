import { ArrowData, ArrowDefinition } from "../../Service";

export const s3Invoke: ArrowDefinition = {
    type: "Invoke Function",
    fromType: "S3 Bucket",
    toType: "Lambda",
    properties: [
        {
            name: "Trigger on",
            condition: () => { return true },
            defaultValue: "Create",
            choices: ["Create", "Delete"],
            type: "string",
            mandatory: true,
        }
    ]
}