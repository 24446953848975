import { ArrowDefinition, ArrowPropertyData, ArrowPropertyDefinition } from "../../Service"

const baseApiIntegrationProperties: ArrowPropertyDefinition[] = [
    {
        name: "HTTP Methods",
        condition: () => { return true },
        defaultValue: ["GET"],
        choices: ["GET", "POST", "PUT", "DELETE", "HEAD"],
        type: "string",
        multipleChoice: true,
        mandatory: true,
    },
    {
        name: "Authorization method",
        condition: () => { return true },
        defaultValue: "None",
        choices: ["None", "AWS IAM", "Cognito user pools"],
        type: "string",
        mandatory: true,
    }
]

export const apiToS3Integration: ArrowDefinition = {
    type: "API Integration",
    fromType: "API Gateway",
    toType: "S3 Bucket",
    properties: [
        {
            ...baseApiIntegrationProperties[0],
            choices: ["GET", "PUT", "DELETE", "HEAD"],
        },
        baseApiIntegrationProperties[1],
    ]
}

export const apiToDynamoDBIntegration: ArrowDefinition = {
    type: "API Integration",
    fromType: "API Gateway",
    toType: "DynamoDB Table",
    properties: [
        {
            ...baseApiIntegrationProperties[0],
            choices: ["GET", "POST", "PUT", "DELETE"],
        },
        baseApiIntegrationProperties[1],
    ]
}
export const apiToLambdaIntegration: ArrowDefinition = {
    type: "API Integration",
    fromType: "API Gateway",
    toType: "Lambda",
    properties: [
        ...baseApiIntegrationProperties,
    ]
}
export const apiToSQSIntegration: ArrowDefinition = {
    type: "API Integration",
    fromType: "API Gateway",
    toType: "SQS Queue",
    properties: [
        baseApiIntegrationProperties[1],
    ]
}

export const apiToSNSIntegration: ArrowDefinition = {
    type: "API Integration",
    fromType: "API Gateway",
    toType: "SNS Topic",
    properties: [
        baseApiIntegrationProperties[1],
    ]
}